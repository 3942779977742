import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

const TemplateItemSection: React.FC<{
  raTemplate: any;
  rateAnalysisId: string;
}> = ({ raTemplate, rateAnalysisId }) => {
  const navigate = useNavigate();

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="Template Items"
        collapsible
        action={
          <EditNoteIcon
            onClick={() => navigate(`/rateanalysis/manage-ra?type=edit&raId=${rateAnalysisId}`)}
            sx={{ cursor: 'pointer' }}
          />
        }
      >
        <DataGridTable
          sx={{
            maxWidth: '80vw',
            mx: 'auto',
            mt: 0.5,
          }}
          columns={[
            {
              field: 'referenceId',
              headerName: 'Id',
              minWidth: 100,
              editable: true,
              disableColumnMenu: true,
              disableReorder: true,
              renderCell: params => params?.row?.item?.referenceId || 'N/A',
            },
            {
              field: 'name',
              headerName: 'Item Name',
              minWidth: 250,
            },
            {
              field: 'qty',
              headerName: 'Quantity',
              minWidth: 200,
              editable: true,
              disableColumnMenu: true,
              disableReorder: true,
            },
            {
              field: 'uom',
              headerName: 'UOM',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'cost',
              headerName: 'Cost',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
          ]}
          getRowId={row => row?.item._id}
          rows={raTemplate ?? []}
          hideFooterPagination
        />
      </Section>
    </Grid>
  );
};

export default TemplateItemSection;
