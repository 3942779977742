import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { GET_ME_QUERY, GetMeQueryResponse } from 'graphql/query/get-me';
import { FC, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { User } from 'types';

import FocusLayout from 'layouts/FocusLayout';

import { storeLoginCredentials } from 'utils/auth';
import { getHomeRoute } from 'utils/common';
import { emailRegex } from 'utils/regexes';

import { AuthSelector, PasscodeScreen, PasswordScreen } from './screens';

type LoginMutationResponse = {
  login: {
    sessionToken: string;
  };
};

type LoginMutationVariables = {
  email: string;
  password: string;
};

const LOGIN_USER = gql`
  mutation Mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      sessionToken
      message
    }
  }
`;

const LOGIN_WITH_PASSCODE_MUTATION = gql`
  mutation LoginWithPasscode($email: String!, $passcode: String!) {
    loginWithPasscode(email: $email, passcode: $passcode) {
      sessionToken
      message
      error
    }
  }
`;

type LoginWithPasscodeMutationResponse = {
  loginWithPasscode: {
    user: User;
    sessionToken: string;
    message: string;
    error: string;
  };
};

type LoginWithPasscodeMutationVariables = {
  email: string;
  passcode: string;
};

const GENERATE_PASSCODE = gql`
  mutation GeneratePasscode($email: String!) {
    generatePasscode(email: $email) {
      passcodeGeneratedAt
    }
  }
`;

type GeneratePasscodeMutationResponse = {
  generatePasscode: {
    passcodeGeneratedAt;
  };
};

type GeneratePasscodeMutationVariables = {
  email: string;
};

const LoginPage: FC = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [formState, setFormState] = useState({
    email: localStorage.getItem('loginEmail') ?? '',
    password: '',
    passcode: '',
  });

  const [errorMsg, setErrorMsg] = useState<Record<string, string>>({
    email: '',
    password: '',
    passcode: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passcodeTimer, setPasscodeTimer] = useState(0);

  const [loginUser, { loading: loggingIn }] = useMutation<
    LoginMutationResponse,
    LoginMutationVariables
  >(LOGIN_USER);

  const [loginWithPasscode, { loading: logginWithPasscode }] = useMutation<
    LoginWithPasscodeMutationResponse,
    LoginWithPasscodeMutationVariables
  >(LOGIN_WITH_PASSCODE_MUTATION);

  const [generatePasscode, { loading: generatingPasscode, error: errorGeneratingPasscode }] =
    useMutation<GeneratePasscodeMutationResponse, GeneratePasscodeMutationVariables>(
      GENERATE_PASSCODE
    );

  const [getMe, { loading: loadingUser }] = useLazyQuery<GetMeQueryResponse>(GET_ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  const startTimer = () => {
    setPasscodeTimer(30);
    timerRef.current = setInterval(() => {
      setPasscodeTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current as NodeJS.Timeout);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleChange = (fieldName: string, value: any) => {
    setErrorMsg({});
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToken = (email: string, token: string) => {
    localStorage.setItem('loginEmail', email);
    storeLoginCredentials(token);
    getMe({
      onCompleted: usr => {
        const redirectTo = usr.getMe.passwordResetRequired
          ? '/settings'
          : getHomeRoute(usr.getMe.role.name);
        navigate(redirectTo);
      },
    });
  };
  const [currentStep, setCurrentStep] = useState(1);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { email, passcode, password } = formState;

    setErrorMsg({});

    if (currentStep === 3) {
      loginUser({ variables: { email, password } })
        .then(data => {
          const token = data.data?.login?.sessionToken;
          if (token) {
            handleToken(email, token);
          } else setErrorMsg({ password: 'Enter a valid email or password' });
        })
        .catch(error => console.log(error));
    }

    if (currentStep === 2) {
      loginWithPasscode({
        variables: {
          email,
          passcode,
        },
        onCompleted: res => {
          const token = res.loginWithPasscode.sessionToken;
          if (token) handleToken(email, token);
          else setErrorMsg({ passcode: 'Invalid Passcode' });
        },
      });
    }
  };

  const isValidEmail = emailRegex.test(formState.email);
  const isLoading = loggingIn || loadingUser || logginWithPasscode || generatingPasscode;

  return (
    <FocusLayout>
      <form onSubmit={onSubmit}>
        {currentStep === 1 && (
          <AuthSelector
            onNext={setCurrentStep}
            formState={formState}
            handleChange={handleChange}
            isValidEmail={isValidEmail}
            timerRef={timerRef}
            startTimer={startTimer}
            generatePasscode={generatePasscode}
          />
        )}
        {currentStep === 2 && (
          <PasscodeScreen
            passcodeTimer={passcodeTimer}
            onNext={setCurrentStep}
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            setErrorMsg={setErrorMsg}
            errorMsg={errorMsg}
            isLoading={isLoading}
            timerRef={timerRef}
            startTimer={startTimer}
            generatePasscode={generatePasscode}
          />
        )}
        {currentStep === 3 && (
          <PasswordScreen
            onNext={setCurrentStep}
            formState={formState}
            handleChange={handleChange}
            handleTogglePassword={handleTogglePassword}
            showPassword={showPassword}
            errorMsg={errorMsg}
            isLoading={isLoading}
            timerRef={timerRef}
            startTimer={startTimer}
            generatePasscode={generatePasscode}
            setFormState={setFormState}
          />
        )}
      </form>
    </FocusLayout>
  );
};
export default LoginPage;
