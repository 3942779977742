import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import classNames from 'classnames';
import React, {
  Children,
  Dispatch,
  FC,
  isValidElement,
  useEffect,
  useReducer,
  useState,
} from 'react';

import ErrorMessage from 'components/ErrorMessage';

import { deepEqual } from 'utils/common';
import validate from 'utils/textValidators';
import { isEmpty } from 'utils/transformFn';

import { getInitialDataAndValidatorsFromChildren } from './formFields';
import FormPanelContext from './formPanelContext';
import reducer, {
  ActionType,
  ClearFormActionType,
  ResetFormActionType,
  UpdateErrorsActionType,
  UpdateStateActionType,
} from './formPanelReducer';
import theme from './theme.module.scss';

const FormPanel: FC<{
  onSubmit: (data: any) => void;
  loading: boolean;
  error: any;
  onCancel?: () => void;
  cancelButtonLabel?: string;
  onReset?: () => void;
  submitButtonLabel?: string;
  needConfirmation?: boolean;
  disableSubmit?: boolean;
  fixActionsAtBottom?: boolean;
  className?: string;
  children: React.ReactNode;
  enableClear?: boolean;
  manualFormUpdate?: () => { fieldName: string; value: any };
}> = ({ disableSubmit = false, className = '', ...props }) => {
  const [initialData, validators] = getInitialDataAndValidatorsFromChildren(props.children);
  const [formState, formDispatch] = useReducer(reducer, { data: initialData });
  const [showConfirmation, toggleConfirmation] = useState(false);

  const formContext = { formState, formDispatch };

  const updateErrorsDispatch: Dispatch<ActionType & UpdateErrorsActionType> = formDispatch;
  const resetFormDispatch: Dispatch<ActionType & ResetFormActionType> = formDispatch;
  const clearFormDispatch: Dispatch<ActionType & ClearFormActionType> = formDispatch;
  const updateStateDispatch: Dispatch<ActionType & UpdateStateActionType> = formDispatch;

  useEffect(() => {
    if (props.manualFormUpdate) {
      const manualData = props.manualFormUpdate();
      updateFormData(manualData.fieldName, manualData.value);
    }
    // eslint-disable-next-line
  }, [props.manualFormUpdate]);

  function validateForm() {
    let errors = {};
    Object.keys(formState.data).forEach(f => {
      if (validators[f]) {
        const error = validate(validators[f], formState, formState.data[f]);
        if (error) {
          errors = { ...errors, [f]: error };
        }
      }
    });

    Children.forEach(props.children, c => {
      if (!isValidElement(c)) return;

      if (
        c.props.type === 'date-range' &&
        formState.data[c.props.fieldName][1] &&
        formState.data[c.props.fieldName][0] > formState.data[c.props.fieldName][1]
      ) {
        errors = { ...errors, [c.props.fieldName]: "'From Date' must be before 'To Date'" };
      } else if (c.props.type === 'range-slider') {
        const bounds = formState.data[c.props.fieldName];
        if (bounds && bounds[0] !== '' && bounds[1] !== '' && bounds[0] > bounds[1]) {
          errors = {
            ...errors,
            [c.props.fieldName]: "'Min Amount' must be less than 'Max Amount'",
          };
        }
      }
    });

    updateErrorsDispatch({
      type: 'UPDATE_ERRORS',
      payload: { errors },
    });

    return errors;
  }

  const updateFormData = (fieldName: string, value: any) => {
    updateStateDispatch({
      type: 'UPDATE_STATE',
      payload: {
        fieldName,
        value,
      },
    });
  };

  const handleSubmit = () => {
    props.onSubmit({ ...formState.data });
  };

  // Check if any key has a non-empty value
  const isEmptyObj = Object.values(initialData).every(value => isEmpty(value));

  return (
    <FormPanelContext.Provider value={formContext}>
      <div
        className={classNames(theme.formPanel, className)}
        style={{
          paddingBottom: props.fixActionsAtBottom ? '4rem' : undefined,
        }}
      >
        {Children.map(props.children, element => {
          if (!isValidElement(element)) return;
          // @ts-ignore
          return React.cloneElement(element, { loading: props.loading });
        })}
        {props.error ? <ErrorMessage type="alert" error={props.error} /> : null}

        {showConfirmation ? (
          <div className={theme.confirmationContainer}>
            <p>Are you sure you want to update?</p>
            <div className={theme.buttonWrapper}>
              <Button onClick={() => toggleConfirmation(false)} variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleSubmit();
                  toggleConfirmation(false);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </div>
          </div>
        ) : (
          <div
            className={classNames(theme.buttonContainer, {
              [theme.fixAtBottom]: props.fixActionsAtBottom,
            })}
          >
            <LoadingButton
              className={theme.submitButton}
              variant="contained"
              size="medium"
              color="secondary"
              fullWidth={props.fixActionsAtBottom}
              disabled={disableSubmit || deepEqual(formState.data, initialData)}
              loading={props.loading}
              onClick={() => {
                const errors = validateForm();
                if (Object.keys(errors).length < 1) {
                  props.needConfirmation ? toggleConfirmation(true) : handleSubmit();
                }
              }}
            >
              {props.submitButtonLabel ? props.submitButtonLabel : 'Submit'}
            </LoadingButton>

            {props?.enableClear && (
              <Button
                disabled={isEmptyObj}
                variant="outlined"
                color="secondary"
                size="medium"
                fullWidth={props.fixActionsAtBottom}
                sx={{ backgroundColor: 'white', marginRight: '10px' }}
                onClick={() => {
                  clearFormDispatch({
                    type: 'CLEAR_FORM',
                    payload: { initialData },
                  });
                }}
              >
                {props.cancelButtonLabel ? props.cancelButtonLabel : 'Clear'}
              </Button>
            )}

            {props.onReset ? (
              <Button
                disabled={deepEqual(formState.data, initialData)}
                variant="outlined"
                size="medium"
                color="secondary"
                fullWidth={props.fixActionsAtBottom}
                sx={{ backgroundColor: 'white' }}
                onClick={() => {
                  resetFormDispatch({
                    type: 'RESET_FORM',
                    payload: { initialData },
                  });
                  if (props.onReset) props.onReset();
                }}
              >
                Reset
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </FormPanelContext.Provider>
  );
};

export default FormPanel;
