import { NetworkStatus, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UOMEnum } from 'routes/RFQs/ManageBOQ';
import { GET_RA_TEMPLATE_QUERY } from 'routes/RateAnalysis';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { GET_BOQ_BYID_QUERY } from 'components/RFQs/Sections/BOQSection';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const GET_RATE_ANALYSIS_BY_ID_QUERY = gql`
  query GetRateAnalysisById($id: ID!) {
    getRateAnalysisById(_id: $id) {
      _id
      referenceId
      totalCost
      totalPrice
      items {
        item
        name
        qty
        uom
        actualCost
        modifiedCost
        totalCost
      }
      boq {
        _id
        referenceId
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
      }
      name
      description
      baseQty
      baseUom
    }
  }
`;
const GET_ITEM_MASTER_QUERY = gql`
  query GetItemMasters($filter: ItemMasterFilter) {
    getItemMasters(filter: $filter) {
      _id
      referenceId
      # code
      # codeDescription
      # subGroup
      # subGroupDescription
      # resourceCode
      # type
      description
      uom
      cost
    }
  }
`;

type GetLeadPeopleQueryVariables = {
  filter?: {
    lead?: string;
    people?: string;
    type?: any;
    searchTerm?: string;
  };
};

const CREATE_RATE_ANALYSIS_MUTATION = gql`
  mutation CreateRateAnalysis($input: CreateRateAnalysisInput!) {
    createRateAnalysis(input: $input) {
      _id
      # boq
      # referenceId
      # totalAmount
      # items {
      #   # item
      #   name
      #   qty
      #   uom
      #   actualPrice
      #   modifiedPrice
      #   amount
      # }
    }
  }
`;

const UPDATE_RATE_ANALYSIS_MUTATION = gql`
  mutation UpdateRateAnalysis($input: UpdateRateAnalysisInput!) {
    updateRateAnalysis(input: $input) {
      totalCost
      totalPrice
      boq {
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
        _id
        referenceId
      }
      referenceId
      _id
      items {
        item
        name
        qty
        uom
        actualCost
        modifiedCost
        totalCost
      }
    }
  }
`;

type CreateFollowupMutationResponse = {
  createFollowup: any;
};

type CreateFollowupMutationVariables = {
  input: {
    name: string;
    description: string;
    baseQty: number;
    baseUom: string;
    items: [
      {
        name: string;
        item: string;
        qty: number;
        price: string;
      }
    ];
  };
};

const MapBOQRAPage = () => {
  const navigate = useNavigate();
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const screenType = params.get('type');
  const rateAnalysisId = params.get('rateAnalysisId');

  const screenTitle =
    screenType === 'create' ? 'Create Rate Analysis for' : 'Update Rate Analysis for';

  const { boqId = '', rfqId } = useParams<{ boqId: string; rfqId: string }>();

  const [formState, setFormState] = useState<Record<string, any>>({});
  const { data, networkStatus: boqNetworkStatus } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: boqId,
    },
  });

  const loadingBOQDetails =
    boqNetworkStatus === NetworkStatus.loading ||
    boqNetworkStatus === NetworkStatus.setVariables ||
    !!!data;

  const boqDetails = data?.getBOQById;

  const [
    getRateAnalysisTemplates,
    { loading: loadingRaTemplates, data: raTemplatesData, networkStatus },
  ] = useLazyQuery(GET_RA_TEMPLATE_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const loadingRAtemplate =
    NetworkStatus.loading === networkStatus ||
    NetworkStatus.setVariables === networkStatus ||
    !!!raTemplatesData;

  const { data: rateAnalysisData, loading: rateAnalysisLoading } = useQuery(
    GET_RATE_ANALYSIS_BY_ID_QUERY,
    {
      variables: {
        id: rateAnalysisId,
      },
      skip: !rateAnalysisId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  const { data: ItemMasters } = useQuery(GET_ITEM_MASTER_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getItemMaster, { loading: loadingItemMaster, data: ItemMaster }] =
    useLazyQuery(GET_ITEM_MASTER_QUERY);

  const [itemMasterOptions, setItemMasterOptions] = useState([]);

  // Trigger the query on component mount
  useEffect(() => {
    getItemMaster();
  }, [getItemMaster]);

  // Update itemMasterOptions when ItemMaster data is available
  useEffect(() => {
    if (ItemMaster?.getItemMasters) {
      const options = ItemMaster.getItemMasters.map(item => ({
        ...item,
        name: item?.description, // Map description to name
      }));
      setItemMasterOptions(options);
    }
  }, [ItemMaster]);

  const [createRateAnalysis, { loading: creatingRA }] = useMutation(CREATE_RATE_ANALYSIS_MUTATION);

  const [updateRateAnalysis, { loading: updatingRA }] = useMutation(UPDATE_RATE_ANALYSIS_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const [rows, setRows] = useState([
    {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', description: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      item: '',
      amount: 0,
      modifiedCost: 0,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState<Record<string, boolean>>({});

  const [formTableState, setTableFormState] = useState(() =>
    rows.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {})
  );

  // load from RA Template functionality
  useEffect(() => {
    if (formState?.template) {
      const itemMasterOptionsV2 = ItemMasters?.getItemMasters?.map(item => ({
        ...item,
        name: item?.description,
      }));

      const { name, description, baseQty, baseUom, items } = formState?.template ?? {};

      // Set form state for the other fields
      setFormState({
        description,
        baseQty,
        baseUom,
      });

      // Map API items to the rows structure
      const updatedRows = items?.map(item => {
        const itemFromMaster = itemMasterOptionsV2?.find(opt => opt.description === item.name);
        return {
          _id: Math.random() * dayjs().unix(),
          referenceId: item.referenceId,
          resp: {
            referenceId: itemFromMaster.referenceId,
            _id: item.item,
            name: itemFromMaster?.description || item.name || '', //item.name,
            cost: itemFromMaster.cost,
            uom: itemFromMaster.uom || '',
          },
          qty: item.qty,
          uom: itemFromMaster.uom || '',
          name: itemFromMaster?.description || item.name || '',
          item: item.item,
          amount: item.qty * item.price,
        };
      });

      // Update rows state
      setRows(updatedRows);

      // Optionally update formTableState
      const updatedFormTableState = updatedRows?.reduce((prev, curr) => {
        prev[curr._id] = curr;
        return prev;
      }, {});
      setTableFormState(updatedFormTableState);
    }
    // }
  }, [ItemMasters, formState]);

  // Edit functionality
  useEffect(() => {
    if (rateAnalysisData?.getRateAnalysisById && screenType === 'update') {
      const itemMasterOptionsV2 = ItemMasters?.getItemMasters?.map(item => ({
        ...item,
        name: item?.description,
      }));

      const { name, description, baseQty, baseUom, items } =
        rateAnalysisData?.getRateAnalysisById ?? {};

      // Set form state for the other fields

      setFormState({
        name,
        description,
        baseQty,
        baseUom,
      });

      // Map API items to the rows structure
      const updatedRows = items?.map(item => {
        const itemFromMaster = itemMasterOptionsV2?.find(opt => opt.description === item.name);

        return {
          _id: Math.random() * dayjs().unix(),
          referenceId: item?.referenceId,
          resp: {
            referenceId: itemFromMaster?.referenceId,
            _id: item?.item,
            name: itemFromMaster?.description || item.name || '', //item.name,
            cost: itemFromMaster?.cost,
            uom: itemFromMaster?.uom || '',
          },
          modifiedCost: item?.modifiedCost,
          qty: item?.qty,
          uom: itemFromMaster?.uom || '',
          name: itemFromMaster?.description || item.name || '',
          item: item?.item,
          amount: item.qty * item.price,
        };
      });

      // Update rows state
      setRows(updatedRows);

      // Optionally update formTableState
      const updatedFormTableState = updatedRows?.reduce((prev, curr) => {
        prev[curr._id] = curr;
        return prev;
      }, {});
      setTableFormState(updatedFormTableState);
    }
  }, [ItemMasters, rateAnalysisData?.getRateAnalysisById]);

  const handleTableChange = (rowId: string, fieldName: string, value: any) => {
    setTableFormState(prev => {
      const updatedRow = {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      };

      // Dynamically update `uom` if `name` is being changed
      if (fieldName === 'resp') {
        updatedRow.uom = value.uom;
        updatedRow.referenceId = value.referenceId;
        updatedRow.name = value.name;
        updatedRow.item = value._id;
        updatedRow.modifiedCost = value.cost;
      }

      const updatedState = {
        ...prev,
        [rowId]: updatedRow,
      };

      // Synchronize rows with formTableState
      setRows(Object.values(updatedState));
      return updatedState;
    });
  };

  const handleAddRow = () => {
    const newRow = {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', description: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      item: '',
      amount: 0,
      modifiedCost: 0,
    };

    setRows(prevRows => [...prevRows, newRow]);
    setTableFormState(prev => ({
      ...prev,
      [newRow._id]: newRow,
    }));
  };

  const handleDeleteRow = (id: number) => {
    // Update formTableState by removing the specific row
    setTableFormState(prev => {
      const updatedState = { ...prev };
      delete updatedState[id];
      return updatedState;
    });

    // Update rows by filtering out the deleted row
    setRows(prevRows => prevRows.filter(row => row._id !== id));

    // Optionally update selectedRows and selectedRowsMap if they depend on the deleted row
    setSelectedRows(prevSelected => prevSelected.filter(rowId => rowId !== id.toString()));
    setSelectedRowsMap(prevMap => {
      const updatedMap = { ...prevMap };
      delete updatedMap[id.toString()];
      return updatedMap;
    });

    console.log('Row deleted:', id);
  };

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setTableFormState(prevState => ({
      ...prevState,
      [newRow._id]: {
        ...(prevState[newRow._id] ?? {}), // Preserve existing fields
        ...newRow, // Merge updated row fields
      },
    }));

    // Optionally, update rows (derived from formTableState if necessary)
    setRows(
      Object.values({
        ...formTableState,
        [newRow._id]: {
          ...(formTableState[newRow._id] ?? {}),
          ...newRow,
        },
      })
    );

    return newRow; // Return the updated row back to DataGrid
  };

  const tableData =
    formTableState &&
    Object?.values(formTableState)?.map((item: any) => {
      const modifiedCost =
        item?.modifiedCost === undefined ? Number(item?.resp?.cost) : Number(item?.modifiedCost);
      return {
        // referenceId: item?.resp?.referenceId,
        uom: item?.uom,
        item: item?.resp?.referenceId,
        name: item?.name,
        qty: Number(item?.qty),
        actualCost: Number(item?.resp?.cost),
        modifiedCost,
        // amount: Number(item?.qty * modifiedCost),
      };
    });

  const tableDataAggregate =
    formTableState &&
    Object?.values(formTableState)?.map((item: any) => {
      const modifiedCost =
        item?.modifiedCost === undefined ? Number(item?.resp?.cost) : Number(item?.modifiedCost);
      return {
        // Doubt
        // ...item,
        // modifiedAmount: Number(item?.qty * item?.modifiedCost),
        // item: item?.item,

        // Good values,only
        // modifiedCost,
        // actualCost: Number(item?.resp?.cost),
        // amount: Number(item?.qty * item?.resp?.cost),

        // Down aggregate calculations,
        actualAmount: Number(item?.qty * item?.resp?.cost),
        modifiedAmount: Number(item?.qty * modifiedCost),
      };
    });

  const totalPriceFromTemplate = tableDataAggregate?.reduce(
    (acc, curr) => acc + (curr?.actualAmount || 0),
    0
  );
  const totalPriceAfterModification = tableDataAggregate?.reduce(
    (acc, curr) => acc + (curr?.modifiedAmount || 0),
    0
  );

  const handleSubmit = () => {
    if (screenType === 'update') {
      updateRateAnalysis({
        variables: {
          input: {
            _id: rateAnalysisId,
            ...formState,
            baseQty: Number(formState?.baseQty),
            items: tableData,
            boq: boqId,
          },
        },
        onCompleted: res => navigate(`/rfqs/${rfqId}/${boqId}`, { replace: true }),
      });
    } else {
      createRateAnalysis({
        variables: {
          input: {
            ...formState,
            boq: boqId,
            baseQty: Number(formState?.baseQty),
            items: tableData,
            // totalAmount: totalPriceAfterModification,
          },
        },
        onCompleted: res => navigate(`/rfqs/${rfqId}/${boqId}`, { replace: true }),
      });
    }
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: `${screenTitle} ${boqDetails?.referenceId}`,
      }}
    >
      {rateAnalysisLoading ? (
        <Grid item container direction="column" xs={10} mt={0.5} p={2}>
          <LoadingIndicator />
        </Grid>
      ) : (
        <Grid item container direction="column" xs={10} mt={0.5} p={2}>
          <form
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container item xs={10} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={2}>
                <TextField
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={boqDetails?.boqId ?? ''}
                  onChange={e => handleChange('boqID', e.target.value)}
                  label="BOQ ID"
                />
              </Grid>
              <Grid item xs={3.5}>
                <TextField
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={boqDetails?.name ?? ''}
                  onChange={e => handleChange('boqName', e.target.value)}
                  label="BOQ Name"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getEnumKeyFromValue(UOMEnum, boqDetails?.uom) ?? ''}
                  onChange={e => handleChange('boqName', e.target.value)}
                  label="UOM"
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={boqDetails?.margin ?? 0}
                  // onChange={e => handleChange('margin', e.target.value)}
                  label="Margin %"
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={boqDetails?.discount ?? 0}
                  // onChange={e => handleChange('discount', e.target.value)}
                  label="Discount %"
                />
              </Grid>
              <Grid item xs={1.5}>
                <TextField
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  value={boqDetails?.wastage ?? 0}
                  // onChange={e => handleChange('wastage', e.target.value)}
                  label="Wastage %"
                />
              </Grid>
              {screenType === 'create' && (
                <Grid item xs={12}>
                  <AutocompleteWithFetch
                    fetch={getRateAnalysisTemplates}
                    handleChange={val => handleChange('template', val)}
                    label="Choose rate analysis templates"
                    loading={loadingRaTemplates}
                    value={formState?.template}
                    options={raTemplatesData?.getRateAnalysisTemplates?.rateAnalysisTemplates}
                    variant="standard"
                    size="small"
                    // required
                  />
                </Grid>
              )}
            </Grid>

            {/* here */}
            {Object.keys(formState).length === 0 || formState?.name === '' ? (
              // <LoadingIndicator size="1.6rem" />
              <>Choose a template</>
            ) : (
              <Grid container item xs={10} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} mt={3} rowSpacing={2}>
                  <TextField
                    required={screenType === 'create'}
                    disabled={screenType === 'update'}
                    value={formState.name ?? ''}
                    onChange={e => handleChange('name', e.target.value)}
                    label="Rate analysis name"
                  />
                </Grid>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      value={formState.description ?? ''}
                      onChange={e => handleChange('description', e.target.value)}
                      label="Rate Analysis Description"
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      required={screenType === 'create'}
                      type="number"
                      value={formState.baseQty ?? ''}
                      onChange={e => handleChange('baseQty', e.target.value)}
                      label="Base Quantity"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      options={Object.keys(UOMEnum).map(o => ({ label: o, value: UOMEnum[o] }))}
                      value={formState.baseUom ?? ''}
                      onChange={val => handleChange('baseUom', val)}
                      label="Base UOM"
                      required={screenType === 'create'}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      value={totalPriceFromTemplate ?? ''}
                      label="Base Rate"
                      helperText="Calculated based on sum of all line items amount."
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      value={totalPriceAfterModification ?? ''}
                      label="Modified Rate"
                      helperText="Calculated based on sum of all line items amount."
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={12}>
                    <DataGridTable
                      sx={{
                        maxWidth: '90vw',
                        height: '450px',
                        minHeight: '400px',
                        mx: 'auto',
                        mt: 0.5,
                      }}
                      columns={[
                        {
                          field: 'referenceId',
                          headerName: 'Item Id',
                          minWidth: 150,
                          editable: true,
                          disableColumnMenu: true,
                          disableReorder: true,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.referenceId ?? ''}
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'resp',
                          headerName: 'Item',
                          minWidth: 250,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <AutocompleteWithFetch
                                fetch={getItemMaster}
                                handleChange={val =>
                                  handleTableChange(params?.row?._id, 'resp', val)
                                }
                                label=""
                                loading={loadingItemMaster}
                                value={formTableState[params.row._id]?.resp}
                                options={itemMasterOptions}
                                required
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'qty',
                          headerName: 'Quantity',
                          minWidth: 150,
                          editable: true,
                          disableColumnMenu: true,
                          disableReorder: true,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                type="number"
                                value={formTableState[params?.row?._id]?.qty ?? 0}
                                onChange={e =>
                                  handleTableChange(params?.row?._id, 'qty', e.target.value)
                                }
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'uom',
                          headerName: 'UOM',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.uom ?? ''}
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'cost',
                          headerName: 'ERP Cost',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={formTableState[params?.row?._id]?.resp.cost ?? ''}
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'modifiedCost',
                          headerName: 'Cost',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                type="number"
                                defaultValue={
                                  Number(formTableState[params?.row?._id]?.resp.cost) ?? 0
                                }
                                value={formTableState[params?.row?._id]?.modifiedCost}
                                onChange={e =>
                                  handleTableChange(
                                    params?.row?._id,
                                    'modifiedCost',
                                    e.target.value
                                  )
                                }
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'amount',
                          headerName: 'Amount',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <Box
                              sx={{
                                height: 50,
                                my: 'auto',
                                pt: 2,
                              }}
                            >
                              <TextField
                                disabled
                                value={
                                  formTableState &&
                                  params?.row?._id &&
                                  (() => {
                                    const rowState = formTableState[params.row._id];
                                    const modifiedCost = rowState?.modifiedCost ?? 0;
                                    const qty = rowState?.qty ?? 0;
                                    const respCost = rowState?.resp?.cost ?? 0;

                                    // Use modifiedCost if defined and non-zero, otherwise use resp.cost
                                    return Number(
                                      (modifiedCost !== 0 ? modifiedCost : respCost) * qty
                                    );
                                  })()
                                }
                                label=""
                                variant="standard"
                                size="small"
                              />
                            </Box>
                          ),
                        },
                        {
                          field: 'action',
                          headerName: 'Action',
                          minWidth: 150,
                          disableColumnMenu: true,
                          disableReorder: true,
                          sortable: false,
                          renderCell: params => (
                            <>
                              {rows?.length > 1 && (
                                <DeleteIcon
                                  onClick={() => {
                                    handleDeleteRow(params?.row?._id);
                                  }}
                                  fontSize="small"
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <AddCircleIcon
                                onClick={() => {
                                  handleAddRow();
                                }}
                                fontSize="small"
                                sx={{ ml: 2, cursor: 'pointer' }}
                              />
                            </>
                          ),
                        },
                      ]}
                      getRowId={row => row._id}
                      rows={rows}
                      onRowSelectionModelChange={newSelection => {
                        setSelectedRowsMap(
                          newSelection.reduce((prev, curr) => {
                            prev[curr] = true;
                            return prev;
                          }, {})
                        );
                        setSelectedRows(newSelection as string[]);
                      }}
                      rowSelectionModel={selectedRows}
                      processRowUpdate={handleProcessRowUpdate}
                      hideFooterPagination
                    />
                  </Grid>
                </Grid>
                <Grid container position="sticky" bottom={0} right={10} pt={2} bgcolor={'white'}>
                  <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
                    {/* <Button onClick={handleAddRow} variant="outlined" color="secondary">
                    add new item
                  </Button> */}
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      color="secondary"
                      loading={creatingRA || updatingRA}
                    >
                      {screenType} BOM
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </form>
        </Grid>
      )}
    </Navbar>
  );
};

export default MapBOQRAPage;
