import { NetworkStatus, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UOMEnum } from 'routes/RFQs/ManageBOQ';
import { CompanyType, Followup } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';
import Note from 'components/Note';
import DataGridTable from 'components/Table';

import { removeEmptyFields } from 'utils/common';

import { GET_RA_BY_ID_QUERY } from '../RADetails';

const GET_ITEM_MASTER_QUERY = gql`
  query GetItemMasters($filter: ItemMasterFilter) {
    getItemMasters(filter: $filter) {
      _id
      referenceId
      # code
      # codeDescription
      # subGroup
      # subGroupDescription
      # resourceCode
      # type
      description
      uom
      cost
    }
  }
`;

const CREATE_RATE_ANALYSIS_TEMPLATE_MUTATION = gql`
  mutation CreateRateAnalysisTemplate($input: CreateRateAnalysisTemplateInput!) {
    createRateAnalysisTemplate(input: $input) {
      _id
      referenceId
      name
      description
      baseQty
      baseUom
      items {
        name
        qty
        cost
        uom
        item {
          _id
          referenceId
          code
          codeDescription
          subGroup
          subGroupDescription
          resourceCode
          type
          description
          uom
          cost
        }
      }
    }
  }
`;

const UPDATE_RATE_ANALYSIS_TEMPLATE_MUTATION = gql`
  mutation UpdateRateAnalysisTemplate($input: UpdateRateAnalysisTemplateInput!) {
    updateRateAnalysisTemplate(input: $input) {
      _id
      referenceId
      name
      description
      baseQty
      baseUom
      items {
        name
        qty
        cost
        uom
        item {
          _id
          # referenceId
          code
          codeDescription
          subGroup
          subGroupDescription
          resourceCode
          type
          description
          uom
          cost
        }
      }
    }
  }
`;

type CreateFollowupMutationResponse = {
  createFollowup: Followup;
};

type CreateFollowupMutationVariables = {
  input: {
    name: string;
    description: string;
    baseQty: number;
    baseUom: string;
    items: [
      {
        name: string;
        item: string;
        qty: number;
        price: string;
      }
    ];
  };
};

const ManageRateAnalysisPage = () => {
  const navigate = useNavigate();
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);
  const type = params.get('type');
  const raId = params.get('raId');
  const screenTitle = type === 'create' ? 'Create RA Template' : 'Update RA Template';

  const [formState, setFormState] = useState<Record<string, any>>({});

  const { data: ItemMasters } = useQuery(GET_ITEM_MASTER_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [getItemMaster, { loading: loadingItemMaster, data: ItemMaster }] =
    useLazyQuery(GET_ITEM_MASTER_QUERY);

  const [itemMasterOptions, setItemMasterOptions] = useState([]);

  useEffect(() => {
    getItemMaster();
  }, [getItemMaster]);

  // Update itemMasterOptions when ItemMaster data is available
  useEffect(() => {
    if (ItemMaster?.getItemMasters) {
      const options = ItemMaster.getItemMasters.map(item => ({
        ...item,
        name: item?.description, // Map description to name
      }));
      setItemMasterOptions(options);
    }
  }, [ItemMaster]);

  const [createRateAnalysisTemplate, { loading: creatingRATemplate }] = useMutation(
    CREATE_RATE_ANALYSIS_TEMPLATE_MUTATION
  );

  const [updateRateAnalysisTemplate, { loading: updatingRATemplate }] = useMutation(
    UPDATE_RATE_ANALYSIS_TEMPLATE_MUTATION
  );

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  //  Get RaById

  const { data: raTemplate, networkStatus } = useQuery(GET_RA_BY_ID_QUERY, {
    variables: {
      id: raId,
    },
    skip: type === 'create',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadingRAById =
    NetworkStatus.loading === networkStatus ||
    NetworkStatus.setVariables === networkStatus ||
    !!!raTemplate;

  const [rows, setRows] = useState([
    {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', description: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      // item: '',
      amount: 0,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState<Record<string, boolean>>({});

  const [formTableState, setTableFormState] = useState(() =>
    rows.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {})
  );

  // Update functionality

  useEffect(() => {
    if (type === 'edit') {
      const itemMasterOptionsV2 = ItemMasters?.getItemMasters?.map(item => ({
        ...item,
        name: item?.description,
      }));

      const { name, description, baseQty, baseUom, items } =
        raTemplate?.getRateAnalysisTemplateById ?? {};

      if (raTemplate?.getRateAnalysisTemplateById && ItemMasters) {
        setFormState({
          name,
          description,
          baseQty,
          baseUom,
        });

        // Map API items to the rows structure

        const updatedRows = items?.map(item => {
          const itemFromMaster = itemMasterOptionsV2?.find(opt => opt.description === item.name);

          return {
            _id: Math.random() * dayjs().unix(),
            referenceId: itemFromMaster?.referenceId || item.item?.referenceId || '', // Use fallback
            resp: {
              referenceId: itemFromMaster?.referenceId || item.item?.referenceId || '', // Use fallback
              _id: item.item._id,
              name: itemFromMaster?.description || item.name || '',
              cost: itemFromMaster?.cost || item.cost, // Fallback to cost if cost is not available
              uom: itemFromMaster?.uom || '', // Use fallback for `uom`
            },
            uom: itemFromMaster?.uom || '', // Use fallback for `uom`
            qty: item.qty,
            name: itemFromMaster?.description || item.name || '',
            amount: item.qty * (itemFromMaster?.cost || item.cost), // Calculate amount
          };
        });

        // Update rows state
        setRows(updatedRows);

        // Optionally update formTableState
        const updatedFormTableState = updatedRows?.reduce((prev, curr) => {
          prev[curr._id] = curr;
          return prev;
        }, {});
        setTableFormState(updatedFormTableState);
      }
    }
  }, [raTemplate, ItemMasters, type]);

  const handleTableChange = (rowId: string, fieldName: string, value: any) => {
    setTableFormState(prev => {
      const updatedRow = {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      };

      if (fieldName === 'resp') {
        updatedRow.uom = value.uom;
        updatedRow.referenceId = value.referenceId;
        updatedRow.name = value.name;
      }

      const updatedState = {
        ...prev,
        [rowId]: updatedRow,
      };

      // Synchronize rows with formTableState
      setRows(Object.values(updatedState));
      return updatedState;
    });
  };

  const handleAddRow = () => {
    const newRow = {
      _id: Math.random() * dayjs().unix(),
      referenceId: '',
      resp: { _id: '', description: '', cost: 0 },
      qty: 0,
      uom: '',
      name: '',
      // item: '',
      amount: 0,
    };

    setRows(prevRows => [...prevRows, newRow]);
    setTableFormState(prev => ({
      ...prev,
      [newRow._id]: newRow,
    }));
  };

  const handleDeleteRow = (id: number) => {
    // Update formTableState by removing the specific row
    setTableFormState(prev => {
      const updatedState = { ...prev };
      delete updatedState[id];
      return updatedState;
    });

    // Update rows by filtering out the deleted row
    setRows(prevRows => prevRows.filter(row => row._id !== id));

    // Optionally update selectedRows and selectedRowsMap if they depend on the deleted row
    setSelectedRows(prevSelected => prevSelected.filter(rowId => rowId !== id.toString()));
    setSelectedRowsMap(prevMap => {
      const updatedMap = { ...prevMap };
      delete updatedMap[id.toString()];
      return updatedMap;
    });

    console.log('Row deleted:', id);
  };

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setTableFormState(prevState => ({
      ...prevState,
      [newRow._id]: {
        ...(prevState[newRow._id] ?? {}), // Preserve existing fields
        ...newRow, // Merge updated row fields
      },
    }));

    // Optionally, update rows (derived from formTableState if necessary)
    setRows(
      Object.values({
        ...formTableState,
        [newRow._id]: {
          ...(formTableState[newRow._id] ?? {}),
          ...newRow,
        },
      })
    );

    return newRow; // Return the updated row back to DataGrid
  };

  const tableData = Object.values(formTableState)?.map((item: any) => ({
    // referenceId: item?.referenceId,
    uom: item?.uom,
    qty: Number(item?.qty),
    name: item?.name,
    item: item?.resp?._id,
    cost: Number(item?.qty * item?.resp?.cost),
  }));

  const totalPrice = tableData.reduce((acc, curr) => acc + (curr.cost || 0), 0);

  const handleSubmit = () => {
    console.log('res', {
      ...formState,
      items: tableData,
    });

    if (type === 'edit') {
      updateRateAnalysisTemplate({
        variables: {
          input: {
            _id: raId,
            ...formState,
            baseQty: Number(formState?.baseQty),
            items: tableData,
          },
        },
        onCompleted: res =>
          navigate(`/rateanalysis/${res.updateRateAnalysisTemplate._id}`, { replace: true }),
      });
    } else {
      createRateAnalysisTemplate({
        variables: {
          input: {
            ...formState,
            baseQty: Number(formState?.baseQty),
            items: tableData,
          },
        },
        onCompleted: res =>
          navigate(`/rateanalysis/${res.createRateAnalysisTemplate._id}`, { replace: true }),
      });
    }
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: screenTitle,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={0.5} rowGap={2.5}>
        <Note title="Create reusable Rate Analysis Template for better productivity" />
        <form
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                value={formState.name ?? ''}
                onChange={e => handleChange('name', e.target.value)}
                label="RA Template Name"
              />
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  minRows={3}
                  value={formState.description ?? ''}
                  onChange={e => handleChange('description', e.target.value)}
                  label="RA Template Description"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={4}>
                <TextField
                  required
                  type="number"
                  value={formState.baseQty ?? ''}
                  onChange={e => handleChange('baseQty', e.target.value)}
                  label="Base Quantity"
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  options={Object.keys(UOMEnum).map(o => ({ label: o, value: UOMEnum[o] }))}
                  value={formState.baseUom ?? ''}
                  onChange={val => handleChange('baseUom', val)}
                  label="Base UOM"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  disabled
                  value={totalPrice ?? ''}
                  label="Base Rate"
                  helperText="Calculated based on sum of all line items amount."
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <DataGridTable
                  sx={{
                    maxWidth: '80vw',
                    height: '450px',
                    minHeight: '400px',
                    mx: 'auto',
                    mt: 0.5,
                  }}
                  columns={[
                    {
                      field: 'referenceId',
                      headerName: 'Item Id',
                      minWidth: 200,
                      editable: true,
                      disableColumnMenu: true,
                      disableReorder: true,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled
                            value={formTableState[params?.row?._id]?.resp.referenceId ?? ''}
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'resp',
                      headerName: 'Item',
                      minWidth: 250,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <AutocompleteWithFetch
                            fetch={getItemMaster}
                            handleChange={val => handleTableChange(params?.row?._id, 'resp', val)}
                            label=""
                            loading={loadingItemMaster}
                            value={formTableState[params.row._id]?.resp}
                            options={itemMasterOptions}
                            required
                            variant="standard"
                            size="small"
                            // disabled={!selectedRowsMap[params.row._id]}
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'qty',
                      headerName: 'Quantity',
                      minWidth: 200,
                      editable: true,
                      disableColumnMenu: true,
                      disableReorder: true,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          {/* <TextField
                            type="number"
                            value={formTableState[params?.row?._id]?.qty ?? 0}
                            // onChange={e =>
                            //   handleTableChange(params?.row?._id, 'qty', e.target.value)
                            // }
                            onChange={e => {
                              const value = Number(e.target.value);
                              if (!isNaN(value) && value >= 1) {
                                handleTableChange(params?.row?._id, 'qty', value);
                              }
                            }}
                            label=""
                            variant="standard"
                            size="small"
                          /> */}

                          <TextField
                            type="number"
                            value={formTableState[params?.row?._id]?.qty ?? ''}
                            onChange={e => {
                              const value = e.target.value;

                              // Allow only positive numbers (>= 1)
                              if (value === '' || /^[1-9]\d*$/.test(value)) {
                                handleTableChange(
                                  params?.row?._id,
                                  'qty',
                                  value === '' ? '' : Number(value)
                                );
                              }
                            }}
                            inputProps={{
                              min: 1, // Prevents typing numbers below 1
                            }}
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'uom',
                      headerName: 'UOM',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      sortable: false,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled
                            value={formTableState[params?.row?._id]?.resp.uom ?? ''}
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'cost',
                      headerName: 'Cost',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      sortable: false,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled
                            value={formTableState[params?.row?._id]?.resp.cost ?? ''}
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'amount',
                      headerName: 'Amount',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      sortable: false,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled
                            value={
                              Number(
                                formTableState[params?.row?._id]?.resp.cost *
                                  formTableState[params?.row?._id]?.qty
                              ) ?? 0
                            }
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'action',
                      headerName: 'Action',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      sortable: false,
                      renderCell: params => (
                        <>
                          {rows?.length > 1 && (
                            <DeleteIcon
                              onClick={() => {
                                handleDeleteRow(params?.row?._id);
                              }}
                              fontSize="small"
                              sx={{ cursor: 'pointer' }}
                            />
                          )}
                          <AddCircleIcon
                            onClick={() => {
                              handleAddRow();
                            }}
                            fontSize="small"
                            sx={{ ml: 2, cursor: 'pointer' }}
                          />
                        </>
                      ),
                    },
                  ]}
                  getRowId={row => row._id}
                  rows={rows}
                  onRowSelectionModelChange={newSelection => {
                    setSelectedRowsMap(
                      newSelection.reduce((prev, curr) => {
                        prev[curr] = true;
                        return prev;
                      }, {})
                    );
                    setSelectedRows(newSelection as string[]);
                  }}
                  rowSelectionModel={selectedRows}
                  processRowUpdate={handleProcessRowUpdate}
                  hideFooterPagination
                />
              </Grid>
            </Grid>

            <Grid container position="sticky" bottom={0} right={10} pt={2} bgcolor={'white'}>
              <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
                {/* <Button onClick={handleAddRow} variant="outlined" color="secondary">
                  add new item
                </Button> */}
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  color="secondary"
                  loading={creatingRATemplate || updatingRATemplate}
                >
                  {screenTitle}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default ManageRateAnalysisPage;
