import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Lead, PQSubmitTypeEnum } from 'types';

import DateInput from 'components/Inputs/Date';
import { Select } from 'components/Inputs/Select';

import { formatDate } from 'utils/transformFn';

const SUBMIT_PQ_MUTATION = gql`
  mutation SubmitPQ($input: SubmitPQInput!) {
    submitPQ(input: $input) {
      _id
      name
      pqReceivedDate
      pqSubmittedDate
      leadStatus
    }
  }
`;

type SubmitPQMutationResponse = {
  submitPQ: Lead;
};

type SubmitPQMutationVariables = {
  input: {
    lead: string;
    pqSubmitType: PQSubmitTypeEnum;
    pqSubmittedDate: string;
  };
};

const SubmitPQForm: React.FC<{ lead: Lead; cb: (lead: Lead) => void }> = ({ cb, lead }) => {
  const [formState, setFormState] = useState({
    pqSubmitType: PQSubmitTypeEnum.ONLINE,
    pqSubmittedDate: dayjs(),
  });

  const [submitPQ, { loading: submittingPQ }] = useMutation<
    SubmitPQMutationResponse,
    SubmitPQMutationVariables
  >(SUBMIT_PQ_MUTATION);

  const handleSubmit = () => {
    const pqSubmittedDate = formatDate(formState.pqSubmittedDate) as string;

    submitPQ({
      variables: {
        input: {
          lead: lead._id,
          pqSubmitType: formState.pqSubmitType,
          pqSubmittedDate,
        },
      },
      onCompleted: updatedLead => {
        cb(updatedLead.submitPQ);
      },
      onError: error => {
        console.error('GraphQL error:', error);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2} px={2} py={2}>
        <Grid item xs={6}>
          <Select
            options={Object.keys(PQSubmitTypeEnum).map(o => ({
              label: o,
              value: PQSubmitTypeEnum[o],
            }))}
            required
            label="PQ Submit Type"
            value={formState.pqSubmitType}
            onChange={val => {
              if (val === PQSubmitTypeEnum.ONLINE) {
                setFormState({
                  pqSubmitType: val,
                  pqSubmittedDate: dayjs(),
                });
              } else {
                setFormState(prev => ({
                  ...prev,
                  pqSubmitType: val,
                }));
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DateInput
            required
            value={formState.pqSubmittedDate}
            onChange={val => setFormState(prev => ({ ...prev, pqSubmittedDate: val }))}
            label="PQ Submit Date"
            disabled={formState.pqSubmitType === PQSubmitTypeEnum.ONLINE}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <LoadingButton loading={submittingPQ} type="submit" variant="contained">
            Submit PQ
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SubmitPQForm;
