import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Followup, FollowupTypeEnum } from 'types';

import Filters from 'components/Filters';
import FollowupTemplate from 'components/Followups';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { transformFilters } from 'utils/transformFn';

const GET_FOLLOWUPS_QUERY = gql`
  query GetFollowups($filter: FollowupFilter) {
    getFollowups(filter: $filter) {
      _id
      referenceId
      comments
      followupDate
      followupType
      leadStatus
      lead {
        name
        probability
        segment
      }
    }
  }
`;

type GetFollowupQueryResponse = {
  getFollowups: Followup[];
};

type GetFollowupQueryVariables = {
  filter: {
    searchTerm?: string;
    followupType?: FollowupTypeEnum[];
    followupDateRange?: string[];
  };
};

const FollowupsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const navigate = useNavigate();

  const {
    data: followups,
    networkStatus,
    refetch: refetchFollowups,
  } = useQuery<GetFollowupQueryResponse, GetFollowupQueryVariables>(GET_FOLLOWUPS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const loadingFollowups =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!followups;

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFollowups({
      filter: newFilters,
    });
  };
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  return (
    <Navbar
      title="Events"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search Events',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate('create-followup')}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="events"
            refetch={data => {
              return applyFilters({
                ...transformFilters(data),
              });
            }}
          />
        </Grid>
      </Grid>
      {loadingFollowups ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <FollowupTemplate followups={followups.getFollowups} />
      )}
    </Navbar>
  );
};

export default FollowupsPage;
