import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import {
  LeadAssociateMuationResponse,
  LeadAssociateMuationVariables,
  UPDATE_LEAD_ASSOCIATE_MUTATION,
} from 'graphql/mutation/associate';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Branch, CompanyType, Lead } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import TextField from 'components/Inputs/TextField';

import { getUpdatedFields, removeEmptyFields } from 'utils/common';

import { getCompanyAndBranchFieldName } from '../AssociateButton';

const BRANCHES_QUERY = gql`
  query GetBranches($filter: BranchFilter) {
    getBranches(filter: $filter) {
      _id
      referenceId
      name
    }
  }
`;

const UpdateAssociateForm: React.FC<{
  initialData: Lead;
  cb: () => void;
}> = ({ initialData, cb }) => {
  const { leadId = '', companyType = CompanyType['COST CONSULTANT'] } = useParams<{
    leadId: string;
    companyType: CompanyType;
  }>();

  const { branchFieldName, companyLabel, companyFieldName } =
    getCompanyAndBranchFieldName(companyType);

  const [formState, setFormState] = useState<Record<string, any>>({
    branch: {
      _id: initialData[branchFieldName]._id,
      name: initialData[branchFieldName].name,
      referenceId: initialData[branchFieldName].referenceId,
    },
  });

  const [getBranches, { loading: loadingBranches, data: branches }] = useLazyQuery<
    { getBranches: Branch[] },
    { filter: Record<string, any> }
  >(BRANCHES_QUERY);

  const [updateLead, { loading: updatingLead }] = useMutation<
    LeadAssociateMuationResponse,
    LeadAssociateMuationVariables
  >(UPDATE_LEAD_ASSOCIATE_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = () => {
    const refinedData = removeEmptyFields(formState);

    updateLead({
      variables: {
        input: {
          _id: leadId,
          [branchFieldName]: refinedData.branch,
        },
      },
      onCompleted: _ => cb(),
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2} px={2} py={1.5}>
        <Grid item xs={6}>
          <TextField
            label={companyLabel}
            value={initialData[companyFieldName].name}
            disabled
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getBranches}
            handleChange={val => handleChange('branch', val)}
            label="Branch"
            loading={loadingBranches}
            options={branches?.getBranches ?? []}
            value={formState.branch}
            required
            variables={{
              filter: {
                company: initialData[companyFieldName]._id,
              },
            }}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item ml={'auto'}>
            <LoadingButton
              loading={updatingLead || loadingBranches}
              variant="contained"
              disabled={
                !!!Object.keys(getUpdatedFields(initialData[branchFieldName], formState.branch))
                  .length
              }
              type="submit"
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateAssociateForm;
