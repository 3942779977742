import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import {
  UPLOAD_COMPLIANCE_DOCUMENT_MUTATION,
  UploadComplianceDocumentResponse,
  UploadComplianceDocumentVariables,
} from 'graphql/mutation/compliance';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import UploadFile from 'components/Inputs/UploadFile';

const UploadBOQForm: React.FC<{
  onCancel: () => void;
}> = ({ onCancel }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const { rfqId = '' } = useParams<{ rfqId: string }>();

  const [uploadBOQ, { loading: uploadingBOQ }] = useMutation<
    UploadComplianceDocumentResponse,
    UploadComplianceDocumentVariables
  >(UPLOAD_COMPLIANCE_DOCUMENT_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    uploadBOQ({
      variables: {
        input: {
          _id: '',
          file: formState.file,
        },
      },
      onCompleted: res => {
        if (!res.uploadComplianceDocument.success) {
          toast.error(res.uploadComplianceDocument.message);
        } else {
          console.log('');
        }
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container columnSpacing={1.5} rowGap={2} p={2}>
        <Grid item xs={12}>
          <UploadFile
            buttonProps={{
              size: 'small',
            }}
            values={formState.file ?? ''}
            onChange={val => handleChange('file', val)}
            label="Select File *"
            required
          />
        </Grid>

        <Grid item container xs={12} columnGap={1} justifyContent={'flex-end'}>
          <Button variant="text" size="medium" onClick={onCancel}>
            Cancel
          </Button>

          <LoadingButton variant="contained" loading={uploadingBOQ} type="submit" size="medium">
            Upload
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UploadBOQForm;
