import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { updateCachedList } from 'graphql/cacheUpdate';
import { COMPLIANCES_CHECKLIST_QUERY } from 'graphql/query/complianceChecklist';
import { useParams } from 'react-router-dom';
import { Folder } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import Section from 'components/Section';

import StandardComplianceDocumentsListTable from './StandardComplianceChecklistTable';

const GET_STANDARD_COMPLIANCE_DOCUMENTS = gql`
  query GetStandardComplianceDocuments($project: ID!) {
    getStandardComplianceDocuments(project: $project) {
      _id
      docName
      docType {
        _id
        referenceId
        name
      }
    }
  }
`;

type GetStandardComplianceDocumentsResponse = {
  getStandardComplianceDocuments: {
    _id: string;
    docName: string;
    docType: Folder;
  }[];
};

type GetStandardComplianceDocumentsVariables = {
  project: string;
};

const StandardComplianceDocumentsList = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  const { data: standardComplianceDocuments, networkStatus } = useQuery<
    GetStandardComplianceDocumentsResponse,
    GetStandardComplianceDocumentsVariables
  >(GET_STANDARD_COMPLIANCE_DOCUMENTS, {
    variables: {
      project: projectId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingStandardComplianceDocuments =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!standardComplianceDocuments;

  return (
    <Section title="Add Compliance Items" collapsible>
      {loadingStandardComplianceDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Box
          sx={{
            position: 'relative',
            height: '80vh',
            overflowY: 'auto',
          }}
        >
          <StandardComplianceDocumentsListTable
            initialRows={standardComplianceDocuments.getStandardComplianceDocuments}
            cb={newChecklist =>
              updateCachedList(
                COMPLIANCES_CHECKLIST_QUERY,
                'getComplianceChecklist',
                newChecklist,
                {
                  project: projectId,
                }
              )
            }
          />
        </Box>
      )}
    </Section>
  );
};

export default StandardComplianceDocumentsList;
