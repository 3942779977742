import {
  FormControl,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

import { phoneNumberRegex } from 'utils/regexes';

type TextFieldProps = {
  loading?: boolean;
  setError?: (arg: any) => void;
} & MuiTextFieldProps;

const TextField: React.FC<TextFieldProps> = ({ loading = false, ...props }) => {
  return (
    <FormControl fullWidth>
      <MuiTextField
        FormHelperTextProps={{
          sx: {
            ml: 0.5,
          },
        }}
        {...props}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : props.InputProps
        }
      />
    </FormControl>
  );
};

export const ContactTextField: React.FC<TextFieldProps> = ({
  loading = false,
  onChange: handleChange,
  setError,
  ...props
}) => {
  const [isInvalid, toggleInvalid] = useState(false);

  const checkVal = (val: string) => {
    if (!!val && !phoneNumberRegex.test(String(val))) {
      toggleInvalid(true);
      setError && setError(true);
    } else {
      toggleInvalid(false);
      setError && setError(false);
    }
  };

  return (
    <FormControl fullWidth>
      <MuiTextField
        {...props}
        onChange={e => {
          if (handleChange) {
            if (/^[0-9]+$/.test(e.target.value)) {
              handleChange(e);
            } else {
              e.target.value = '';
              handleChange(e);
            }
            checkVal(e.target.value);
          }
        }}
        type="tel"
        error={isInvalid}
        InputProps={
          loading
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <LoadingIndicator size="0.9rem" />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {isInvalid && (
        <Typography color="crimson" fontSize={11} ml={0.5}>
          Please enter valid number
        </Typography>
      )}
    </FormControl>
  );
};

export default TextField;
