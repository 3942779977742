import dayjs from 'dayjs';
import { User } from 'types';
import { AutoCompleteOption, Customer, Lead } from 'types/common';

import { isAutoCompleteOption } from './common';

export const transformFilters = (filters: any, autoCompleteFields: string[] = []) => {
  const transformedFilters = JSON.parse(JSON.stringify(filters));

  autoCompleteFields.forEach(fieldName => {
    if (filters[fieldName] && filters[fieldName].length > 0) {
      const values = filters[fieldName];
      const ids: string[] = [];
      for (let i = 0; i < values.length; i++) {
        const data = values[i];
        ids.push(data._id);
      }
      transformedFilters[fieldName] = ids;
    } else if (isAutoCompleteOption(filters[fieldName])) {
      if (!!filters[fieldName]._id) transformedFilters[fieldName] = filters[fieldName]._id;
      else transformedFilters[fieldName] = [];
    } else {
      transformedFilters[fieldName] = [];
    }
  });

  for (const filter of Object.keys(filters)) {
    if (
      transformedFilters[filter].length === 0 ||
      transformedFilters[filter][0] === null ||
      transformedFilters[filter][0] === undefined ||
      transformedFilters[filter][0] === ''
    )
      transformedFilters[filter] = undefined;
    else if (dayjs.isDayjs(filters[filter][0]) || dayjs.isDayjs(filters[filter][1])) {
      transformedFilters[filter][0] = dayjs(filters[filter][0]).format('YYYY-MM-DD');

      if (transformedFilters[filter][1]) {
        transformedFilters[filter][1] = dayjs(filters[filter][1]).format('YYYY-MM-DD');
      }
    }
  }

  return transformedFilters;
};

export const transformCustomerNames = (
  customers: Partial<Customer | User | string | null | undefined>[] | undefined
) => {
  const transformedArr: AutoCompleteOption[] = [];
  if (
    (Array.isArray(customers) && !customers.length) ||
    customers === null ||
    customers === undefined
  )
    return transformedArr;

  customers.forEach(customer => {
    if (customer !== null && customer !== undefined && typeof customer !== 'string')
      transformedArr.push({
        name: customer.firstName + ' ' + (customer.lastName ?? ''),
        _id: customer._id ?? '',
        // @ts-ignore
        referenceId: customer.referenceId ?? customer.empId ?? '',
      });
  });

  return transformedArr;
};

export const convertLeadsToAutoCompleteOptions = (arr: any): AutoCompleteOption[] => {
  if (Array.isArray(arr)) {
    return arr.map((value: Lead) => {
      return {
        _id: value._id,
        name: value.customer.firstName + ' ' + (value.customer.lastName ?? ''),
        referenceId: value.referenceId,
      };
    });
  }
  return [];
};

export const transformEnumsToAutoCompleteOptions = (
  enums: Record<string, string> | string[]
): AutoCompleteOption[] => {
  if (enums === null || typeof enums !== 'object' || Object.keys(enums).length === 0) {
    return [];
  }

  if (Array.isArray(enums)) {
    return enums.map(option => ({
      name: option,
      _id: option,
    }));
  }

  return Object.values(enums).map(option => ({
    name: option,
    _id: option.toLowerCase().replace(/\s+/g, '_'),
  }));
};

export const transformAutoCompleteToString = (data: any) => {
  const ans: string[] = [];

  if (Array.isArray(data) && data.length) {
    if (isAutoCompleteOption(data[0])) {
      data.forEach(option => {
        if (!!option._id) ans.push(option._id);
      });
    }
  } else {
    if (isAutoCompleteOption(data)) {
      return data._id;
    }
  }
  return ans;
};

export const getEnumKeyFromValue = (obj: Object, value: string | number) => {
  return Object.keys(obj).find(k => obj[k] === value);
};

export const formatDate = (d: any, format = 'YYYY-MM-DD') => {
  if (!!d) {
    return dayjs(d).format(format);
  }
  return dayjs().format(format);
};

// Define a function to check if a value is "empty"
export function isEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0 || value.every(item => isEmpty(item));
  }
  if (typeof value === 'object' && Object.keys(value).length > 0) {
    return Object.values(value).every(isEmpty);
  }
  if (typeof value === 'string') {
    return value.trim() === '';
  }
  return false;
}
