import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { GET_LEADS_QUERY, GetLeadsQueryResponse } from 'graphql/query/lead';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from 'types';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      _id
      referenceId
      name
      lead {
        _id
        name
      }
      status
    }
  }
`;

type CreateProjectMutationResponse = {
  createProject: Project;
};

type CreateProjectMutationVariables = {
  input: {
    name: string;
    lead: string;
  };
};

const CreateProject = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const navigate = useNavigate();

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [createProject, { loading: creatingProject }] = useMutation<
    CreateProjectMutationResponse,
    CreateProjectMutationVariables
  >(CREATE_PROJECT_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState({ ...formState, [fieldName]: val });
  };

  const handleSubmit = () => {
    createProject({
      variables: {
        input: {
          lead: formState.lead._id,
          name: formState.name,
        },
      },
      onCompleted: res => navigate(`${res.createProject._id}`),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Project',
      }}
    >
      <Grid container mt={2}>
        <Grid item sm={12} md={8} lg={6}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  type="string"
                  required
                  value={formState.name ?? ''}
                  onChange={e => handleChange('name', e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWithFetch
                  fetch={getLeads}
                  options={leads?.getLeads ?? []}
                  loading={loadingLeads}
                  required
                  value={formState.lead ?? ''}
                  handleChange={val => handleChange('lead', val)}
                  label="lead"
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton variant="contained" type="submit" loading={creatingProject}>
                  Create Project
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default CreateProject;
