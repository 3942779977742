import { NetworkStatus, gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import DataGridTable from 'components/Table';

const GET_BOQS_QUERY = gql`
  query Boqs($filter: BOQFilter, $limit: Int, $page: Int) {
    getBOQs(filter: $filter, limit: $limit, page: $page) {
      boqs {
        _id
        referenceId
        project {
          name
        }
        lead {
          _id
          name
        }
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
      }
      totalCount
      currentPage
    }
  }
`;
const UPDATE_MULTIPLE_BOQS_MUTATION = gql`
  mutation UpdateMultipleBOQs($input: [UpdateMultipleBOQsInput]) {
    updateMultipleBOQs(input: $input) {
      _id
    }
  }
`;

const BulkUpdateBOQsPage = () => {
  const navigate = useNavigate();

  const { rfqId } = useParams<{ boqId: string; rfqId: string }>();

  const { data, networkStatus: boqNetworkStatus } = useQuery<any>(GET_BOQS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {},
  });

  const boqsData = data?.getBOQs?.boqs;
  const loadingBOQDetails =
    boqNetworkStatus === NetworkStatus.loading ||
    boqNetworkStatus === NetworkStatus.setVariables ||
    !!!data;

  const [updateMultipleBOQs, { loading: updatingMultipleBOQs }] = useMutation(
    UPDATE_MULTIPLE_BOQS_MUTATION
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedRowsMap, setSelectedRowsMap] = useState<Record<string, boolean>>({});

  const [globalState, setGlobalState] = useState<Record<string, any>>({});
  const [formTableState, setTableFormState] = useState<Record<string, any>>({});

  const handleChange = (fieldName: string, value: any) => {
    setGlobalState(prev => ({
      ...prev,
      [fieldName]: value,
    }));

    // Apply global value to selected rows
    setTableFormState(prevState => {
      const updatedState = { ...prevState };
      selectedRows.forEach(rowId => {
        updatedState[rowId] = {
          ...(updatedState[rowId] ?? {}),
          [fieldName]: value,
        };
      });
      return updatedState;
    });
  };

  const handleTableChange = (rowId: string, fieldName: string, value: any) => {
    setTableFormState(prev => {
      const updatedRow = {
        ...(prev[rowId] ?? {}),
        [fieldName]: value,
      };

      const updatedState = {
        ...prev,
        [rowId]: updatedRow,
      };

      // Synchronize rows with formTableState
      return updatedState;
    });
  };

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    setTableFormState(prevState => ({
      ...prevState,
      [newRow._id]: {
        ...(prevState[newRow._id] ?? {}),
        ...newRow,
      },
    }));

    return newRow;
  };

  const handleSubmit = () => {
    const updatedRows = boqsData
      .filter((row: any) => selectedRows.includes(row._id))
      .map((row: any) => ({
        // ...row,
        // ...formTableState[row._id],
        _id: row._id,
        margin: formTableState[row._id]?.margin
          ? parseFloat(formTableState[row._id].margin)
          : parseFloat(row.margin),
        discount: formTableState[row._id]?.discount
          ? parseFloat(formTableState[row._id].discount)
          : parseFloat(row.discount),
        wastage: formTableState[row._id]?.wastage
          ? parseFloat(formTableState[row._id].wastage)
          : parseFloat(row.wastage),
      }));

    updateMultipleBOQs({
      variables: {
        input: updatedRows,
      },
      onCompleted: res => navigate(`/rfqs/${rfqId}`, { replace: true }),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: `Bulk Update BOQs`,
      }}
    >
      {loadingBOQDetails ? (
        <Grid item container direction="column" xs={12} mt={0.5} p={2}>
          <LoadingIndicator />
        </Grid>
      ) : (
        <Grid item container direction="column" xs={10} mt={0.5} p={2}>
          <form
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2} mb={2}>
              <Grid item xs={4}>
                <TextField
                  type="number"
                  value={globalState?.margin ?? 0}
                  onChange={e => handleChange('margin', e.target.value)}
                  label="Margin %"
                  disabled={selectedRows.length < 2}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="number"
                  value={globalState?.wastage ?? 0}
                  onChange={e => handleChange('wastage', e.target.value)}
                  label="Wastage %"
                  disabled={selectedRows.length < 2}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="number"
                  value={globalState?.discount ?? 0}
                  onChange={e => handleChange('discount', e.target.value)}
                  label="Discount %"
                  disabled={selectedRows.length < 2}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12}>
                <DataGridTable
                  sx={{
                    maxWidth: '90vw',
                    height: '450px',
                    minHeight: '600px',
                    mx: 'auto',
                    mt: 0.5,
                  }}
                  checkboxSelection
                  columns={[
                    {
                      field: 'referenceId',
                      headerName: 'Item Id',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                    },
                    {
                      field: 'boqId',
                      headerName: 'BOQ Id',
                      minWidth: 150,
                      editable: true,
                      disableColumnMenu: true,
                      disableReorder: true,
                    },
                    {
                      field: 'name',
                      headerName: 'Name',
                      minWidth: 150,
                      editable: true,
                      disableColumnMenu: true,
                      disableReorder: true,
                    },
                    {
                      field: 'qty',
                      headerName: 'Quantity',
                      minWidth: 150,
                      editable: true,
                      disableColumnMenu: true,
                      disableReorder: true,
                    },
                    {
                      field: 'uom',
                      headerName: 'UOM',
                      minWidth: 50,
                      disableColumnMenu: true,
                      disableReorder: true,
                      sortable: false,
                    },
                    {
                      field: 'margin',
                      headerName: 'Margin %',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled={!selectedRowsMap[params?.row?._id]}
                            type="number"
                            value={
                              formTableState[params?.row?._id]?.margin ?? params?.row?.margin ?? 0
                            }
                            onChange={e =>
                              handleTableChange(params?.row?._id, 'margin', e.target.value)
                            }
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },

                    {
                      field: 'wastage',
                      headerName: 'Wastage %',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            type="number"
                            disabled={!selectedRowsMap[params?.row?._id]}
                            value={
                              formTableState[params?.row?._id]?.wastage ?? params?.row?.wastage ?? 0
                            }
                            onChange={e =>
                              handleTableChange(params?.row?._id, 'wastage', e.target.value)
                            }
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                    {
                      field: 'discount',
                      headerName: 'Discount %',
                      minWidth: 150,
                      disableColumnMenu: true,
                      disableReorder: true,
                      renderCell: params => (
                        <Box
                          sx={{
                            height: 50,
                            my: 'auto',
                            pt: 2,
                          }}
                        >
                          <TextField
                            disabled={!selectedRowsMap[params?.row?._id]}
                            type="number"
                            value={
                              formTableState[params?.row?._id]?.discount ??
                              params?.row?.discount ??
                              0
                            }
                            onChange={e =>
                              handleTableChange(params?.row?._id, 'discount', e.target.value)
                            }
                            label=""
                            variant="standard"
                            size="small"
                          />
                        </Box>
                      ),
                    },
                  ]}
                  getRowId={row => row._id}
                  rows={boqsData}
                  onRowSelectionModelChange={newSelection => {
                    setSelectedRowsMap(
                      newSelection.reduce((prev, curr) => {
                        prev[curr] = true;
                        return prev;
                      }, {})
                    );
                    setSelectedRows(newSelection as string[]);
                  }}
                  rowSelectionModel={selectedRows}
                  processRowUpdate={handleProcessRowUpdate}
                  hideFooterPagination
                />
              </Grid>
              <Grid item sx={{ ml: 'auto' }} container justifyContent="flex-end" columnGap={1}>
                <LoadingButton
                  variant="contained"
                  size="medium"
                  type="submit"
                  color="secondary"
                  loading={updatingMultipleBOQs}
                  disabled={!selectedRows.length}
                >
                  Update BOQ
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </Navbar>
  );
};

export default BulkUpdateBOQsPage;
