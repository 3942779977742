import { NetworkStatus, useQuery } from '@apollo/client';
import {
  GET_COMPLIANCE_CHECKLIST_BY_ID,
  GetComplianceChecklistByIdResponse,
  GetComplianceChecklistByIdVariables,
} from 'graphql/query/complianceChecklist';
import { useNavigate, useParams } from 'react-router-dom';

import { SingleComplianceChecklistForm } from 'components/Compliances/Forms/SingleComplianceChecklistForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const UpdateCompliancesDetails = () => {
  const navigate = useNavigate();

  const { complianceId = '' } = useParams<{ complianceId: string }>();

  const { data: complianceChecklist, networkStatus } = useQuery<
    GetComplianceChecklistByIdResponse,
    GetComplianceChecklistByIdVariables
  >(GET_COMPLIANCE_CHECKLIST_BY_ID, {
    variables: {
      id: complianceId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingComplianceChecklist =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!complianceChecklist;

  return (
    <Navbar
      goBackButtonConfig={{
        title: `Update Form for ${
          complianceChecklist?.getComplianceChecklistDetailsById?.docType?.name ?? ''
        }`,
      }}
    >
      {loadingComplianceChecklist ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <SingleComplianceChecklistForm
          cb={() => {}}
          initialData={complianceChecklist.getComplianceChecklistDetailsById}
        />
      )}
    </Navbar>
  );
};

export default UpdateCompliancesDetails;
