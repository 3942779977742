import { NetworkStatus, useQuery } from '@apollo/client';
import {
  COMPLIANCES_CHECKLIST_QUERY,
  CompliancesChecklistQueryResponse,
  CompliancesChecklistQueryVariables,
} from 'graphql/query/complianceChecklist';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ComplianceChecklistTemplate from 'components/Compliances/ComplianceChecklistTemplate';
import StandardComplianceDocumentsList from 'components/Compliances/StandardComplianceChecklist';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const CompliancesListPage = () => {
  const [enableStandardChecklistTable, toggleStandardChecklistTable] = useState(false);

  const { projectId = '' } = useParams<{ projectId: string }>();
  const [searchParams] = useSearchParams();

  const { data: compliances, networkStatus } = useQuery<
    CompliancesChecklistQueryResponse,
    CompliancesChecklistQueryVariables
  >(COMPLIANCES_CHECKLIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      project: projectId,
    },
    fetchPolicy: 'network-only',
  });

  const loadingCompliances =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!compliances;

  return (
    <Navbar
      goBackButtonConfig={{
        title:
          compliances?.getComplianceChecklist?.length === 0 || enableStandardChecklistTable
            ? 'Add Compliance Items'
            : searchParams.get('projectName') ?? '',
        onClick:
          compliances?.getComplianceChecklist?.length === 0
            ? undefined
            : enableStandardChecklistTable
            ? () => toggleStandardChecklistTable(false)
            : undefined,
      }}
    >
      {loadingCompliances ? (
        <LoadingIndicator size="1.6rem" />
      ) : compliances.getComplianceChecklist.length === 0 || enableStandardChecklistTable ? (
        <StandardComplianceDocumentsList />
      ) : (
        <ComplianceChecklistTemplate
          compliances={compliances.getComplianceChecklist}
          toggleStandardChecklistTable={toggleStandardChecklistTable}
        />
      )}
    </Navbar>
  );
};

export default CompliancesListPage;
