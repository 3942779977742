import { Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Branch, BranchTypeEnum, Company, CompanyStatus, CompanyType } from 'types';

import { truncateString } from 'utils/common';
import { getEnumKeyFromValue } from 'utils/transformFn';

import CompanyCard from '../Branches/Card';

const CompaniesTemplate: React.FC<{ companies: Company[] }> = ({ companies }) => {
  const navigate = useNavigate();

  const companyCustomCSS = (status: CompanyStatus): SxProps<Theme> => ({
    backgroundColor: status === 'INACTIVE' ? '#EEEDEB' : 'inherit',
    cursor: 'pointer',
  });

  return (
    <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center" mb={5}>
      {companies?.length > 0 ? (
        companies.map(company => (
          <Grid item key={company.referenceId} width={'100%'} md={1.5} lg={1}>
            <CompanyCard
              headers={[
                truncateString(company.name, 30),
                getEnumKeyFromValue(CompanyType, company.type) as string,
              ]}
              sx={companyCustomCSS(company.status)}
              onClick={() => navigate(`${company._id}`)}
              branch={company.branches.find(val => val.type === BranchTypeEnum.HO) as Branch}
            />
          </Grid>
        ))
      ) : (
        <Grid item minHeight="20vh" container justifyContent="center" alignItems="center" xs={12}>
          <Typography variant="subtitle1" fontWeight={500}>
            No companies to show
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CompaniesTemplate;
