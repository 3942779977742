import { gql } from '@apollo/client';
import { DepartmentEnum } from 'types';
import { User } from 'types/common';

export const areasQuery = gql`
  query GetAreas {
    getAreas {
      name
      _id
    }
  }
`;

export type GetCustomersQueryResponse = {
  getCustomers: { _id: string; firstName: string; lastName: string }[];
};

export const customersName = gql`
  query GetCustomers($filter: CustomerFilter) {
    getCustomers(filter: $filter) {
      _id
      firstName
      lastName
      referenceId
    }
  }
`;

export type UserNamesQueryResponse = {
  getUsers: User[];
};

export type UserNamesQueryVariables = {
  filter: {
    department?: DepartmentEnum[];
    email?: string;
    firstName?: string;
    isIntendedUser?: boolean;
    lastName?: string;
    mobile?: string;
    status?: string;
    type?: string;
    roles?: string[];
  };
};

export const USERS_NAMES_QUERY = gql`
  query GetUsers($filter: UserFilter) {
    getUsers(filter: $filter) {
      _id
      firstName
      lastName
      empId
    }
  }
`;
