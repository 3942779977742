import { Box, Grid } from '@mui/material';
import { useUser } from 'contexts/userContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderTypeEnum, Lead, LeadScopeEnum, LeadSegmentEnum, Project } from 'types';

import Card from 'components/Card';

import { truncateString } from 'utils/common';
import { formatDate, getEnumKeyFromValue } from 'utils/transformFn';

import FolderCard from './Card/FolderCard';

export type LeadOrProjectType = (Lead & Project) & { isLeadOrProject: string };

const DocumentsMasterTemplate: React.FC<{
  documentsMaster: LeadOrProjectType[];
}> = ({ documentsMaster }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  return (
    <Box>
      {user?.role.name !== 'EXTERNAL_USER' && (
        <Grid container columnSpacing={2.5} rowSpacing={3} mb={2}>
          <Grid item xs={12} sm={6} md={4} px={1}>
            <FolderCard
              hideCount
              folder={{
                _id: 'standard_folder_id',
                isStandardFolder: true,
                fileCount: 0,
                type: FolderTypeEnum.LEAD,
                name: 'Standard Folders',
                referenceId: 'FD0001',
              }}
              onClick={() => navigate('/documents/STATIC')}
            />
          </Grid>
        </Grid>
      )}

      <Grid container columnSpacing={2.5} rowSpacing={3} mb={3}>
        {documentsMaster.map(doc => (
          <Grid item key={doc._id} xs={12} sm={6} md={4} px={1}>
            <DocumentMasterCard doc={doc} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const DocumentMasterCard: React.FC<{ doc: LeadOrProjectType }> = ({ doc }) => {
  const navigate = useNavigate();

  const isLead = doc.isLeadOrProject === 'Lead';
  const cardData: { label: string; value: any }[] = [];

  if (isLead) {
    const leadData = [
      {
        label: 'Scope',
        value: getEnumKeyFromValue(LeadScopeEnum, doc.scope),
      },
      {
        label: 'Segment',
        value: getEnumKeyFromValue(LeadSegmentEnum, doc.segment),
      },
      {
        label: 'City',
        value: doc.address.city,
      },
    ];

    cardData.push(...leadData);
  } else {
    const projectData = [
      {
        label: 'Type',
        value: LeadScopeEnum.STRUCTURE,
      },
      {
        label: 'Duration',
        value: `${formatDate(new Date(), 'DD MMM, YY')} To ${formatDate(new Date(), 'DD MMM, YY')}`,
      },
      {
        label: 'City',
        value: 'Pune',
      },
    ];
    cardData.push(...projectData);
  }

  return (
    <Card
      headers={[truncateString(doc.name, 15), doc.isLeadOrProject]}
      data={cardData}
      onClick={() => navigate(`${doc.isLeadOrProject.toUpperCase()}/${doc._id}`)}
    />
  );
};

export default DocumentsMasterTemplate;
