import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { DownloadForOffline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { client } from 'graphql/client';
import {
  GET_DOCUMENT_FILES_QUERY,
  GetFilesQueryResponse,
  GetFilesQueryVariables,
} from 'graphql/query/files';
import {
  DOWNLOAD_FOLDER_AS_ZIP,
  DownloadFolderAsZipQueryResponse,
  DownloadFolderAsZipQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DocumentFile, FolderTypeEnum } from 'types';

import DocumentFileCard from 'components/DocumentMaster/Card/DocumentCard';
import DocumentFilesListTemplate from 'components/DocumentMaster/DocumentFilesList';
import DocumentFileForm from 'components/DocumentMaster/Forms/DocumentFileForm';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

type DocumentViewType = 'list' | 'grid';

const DocumentsMasterFilesPage = () => {
  const [viewType, setViewType] = useState<DocumentViewType>('list');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [showCreateNewForm, toggleCreateNewForm] = useState(false);

  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const [downloadFolder, { loading: downloadingFolder }] = useLazyQuery<
    DownloadFolderAsZipQueryResponse,
    DownloadFolderAsZipQueryVariables
  >(DOWNLOAD_FOLDER_AS_ZIP);

  const isLead = folderType === FolderTypeEnum.LEAD;

  const {
    data: docs,
    networkStatus: docsNetworkStatus,
    refetch: reFetchDocs,
  } = useQuery<GetFilesQueryResponse, GetFilesQueryVariables>(GET_DOCUMENT_FILES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      folder: subFolderId,
      lead: isLead ? documentId : undefined,
      project: isLead ? undefined : documentId,
    },
    fetchPolicy: 'network-only',
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    reFetchDocs(newFilters);
  };

  const loadingDocuments =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!docs;

  const handleViewChange = (view: DocumentViewType) => {
    setViewType(view);
  };

  const handleDownloadFolder = () => {
    downloadFolder({
      variables: {
        folder: subFolderId,
        lead: isLead ? documentId : undefined,
        project: isLead ? undefined : documentId,
      },
      onCompleted: res => window.open(res.downloadFolderAsZip, '_blank'),
    });
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!docs?.getFiles?.title ? docs.getFiles.title : '',
        hideSeparator: true,
      }}
      actionChildren={
        <Tooltip title="Download Folder">
          <IconButton>
            {downloadingFolder ? (
              <CircularProgress size={21} color="secondary" />
            ) : (
              <DownloadForOffline
                color="secondary"
                fontSize="large"
                onClick={handleDownloadFolder}
              />
            )}
          </IconButton>
        </Tooltip>
      }
      searchInputConfig={{
        enable: true,
        name: 'documents search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between" mt={-1.5} columnGap={1.5}>
        <Grid display="flex" alignItems="center">
          <Button
            size="small"
            color="secondary"
            variant="contained"
            sx={{ mr: 1, flex: isMobileScreen ? 1 : undefined }}
            onClick={() => navigate('add-files')}
            startIcon={<AddIcon />}
          >
            Standard Files
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => toggleCreateNewForm(true)}
            startIcon={<AddIcon />}
          >
            File
          </Button>
        </Grid>
        <Grid display="flex" alignItems="center" justifyContent={'flex-end'}>
          <IconButton onClick={() => handleViewChange('list')} color="secondary">
            <Tooltip title="List View">
              {viewType === 'list' ? (
                <ViewListOutlinedIcon
                  fontSize="medium"
                  sx={{
                    backgroundColor: orange[200],
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              ) : (
                <ViewListOutlinedIcon
                  fontSize="medium"
                  sx={{
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              )}
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleViewChange('grid')} color="secondary">
            <Tooltip title="Grid View">
              {viewType === 'grid' ? (
                <GridViewIcon
                  fontSize="small"
                  sx={{
                    backgroundColor: orange[200],
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              ) : (
                <GridViewIcon
                  fontSize="small"
                  sx={{
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              )}
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>

      {loadingDocuments ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid minHeight="80vh" mt={0.5} mb={4}>
          {viewType === 'list' ? (
            <DocumentFilesListTemplate
              docsNetworkStatus={docsNetworkStatus}
              docs={docs.getFiles}
              fetchDocs={reFetchDocs}
            />
          ) : (
            <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center">
              {docs.getFiles.files.map(file => (
                <DocumentFileCard file={file} key={file._id} />
              ))}

              {docs.getFiles.files.length === 0 && (
                <Grid container justifyContent={'center'} mt={2}>
                  <Typography variant="subtitle1">No reports to show</Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      )}

      <SimplePopup
        title="Upload New File"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={showCreateNewForm}
      >
        <Grid py={1}>
          <DocumentFileForm
            folderId={subFolderId}
            cb={res => {
              toggleCreateNewForm(false);
              updateFileCachedList(res, {
                folder: subFolderId,
                lead: isLead ? documentId : undefined,
                project: isLead ? undefined : documentId,
              });
            }}
          />
        </Grid>
      </SimplePopup>
    </Navbar>
  );
};

export const updateFileCachedList = (newFile: DocumentFile, variables?: Record<string, any>) => {
  try {
    const currFiles = client.readQuery({
      query: GET_DOCUMENT_FILES_QUERY,
      variables,
    }) ?? {
      getFiles: {
        title: '',
        files: [],
      },
    };

    client.writeQuery({
      query: GET_DOCUMENT_FILES_QUERY,
      variables: variables,
      data: {
        getFiles: {
          ...currFiles.getFiles,
          files: [newFile, ...currFiles.getFiles.files],
        },
      },
    });
  } catch (error) {
    console.error('Error updating cache:', error);
  }
};

export default DocumentsMasterFilesPage;
