import dayjs, { Dayjs } from 'dayjs';

const formatLocalPrice = (num, precision = 2, locale = 'en-IN') => {
  const formatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: precision,
  });
  return formatter.format(num);
};

const getDateTime = (date, format = 'h:mm A, DD MMMM YYYY') => {
  return dayjs(date).format(format);
};

const fixToTwoLocalPrice = num => formatLocalPrice(num);
const fixToThreeLocalPrice = num => formatLocalPrice(num, 3);

const getDate = (date, format = 'DD MMMM YYYY') => {
  return dayjs(date).format(format);
};

const formatCurrency = (num, precision = 2, locale = 'en-IN') => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: precision,
  });
  return formatter.format(num);
};

function convertAmountToIndianUnits(amount: number) {
  const rupeeSymbol = '₹';

  if (amount >= 1e7) {
    return rupeeSymbol + ' ' + (amount / 1e7).toFixed(1) + ' Cr';
  } else if (amount >= 1e5) {
    return rupeeSymbol + ' ' + (amount / 1e5).toFixed(1) + ' Lakh';
  } else if (amount >= 1e3) {
    return rupeeSymbol + ' ' + (amount / 1e3).toFixed(1) + ' Thousand';
  } else {
    return rupeeSymbol + ' ' + amount.toString();
  }
}

function stringToDateFormatter(value: Dayjs | string | undefined) {
  if (value === undefined) return null;
  else if (dayjs.isDayjs(value)) return value;
  else return dayjs(value);
}

/**
@example 
const url = 'http://localhost:3000/rfqs/66f154fbf9e0fbecf61653f1/67502217d331f1ab0aea6104';
const lastSegment = extractLastSegment(url);
console.log(lastSegment); // Output: 67502217d331f1ab0aea6104
**/
const extractLastSegment = (url: string): string => {
  const parsedUrl = new URL(url);
  const segments = parsedUrl.pathname.split('/');
  return segments[segments.length - 1];
};

/**
 *  @example
 *  const formattedText = formatText('APPROVAL_IN_PROGRESS');
 *  console.log(formattedText); // Output: "Approval In Progress"
 */

function formatText(input: string): string {
  return input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export {
  fixToTwoLocalPrice,
  fixToThreeLocalPrice,
  getDateTime,
  getDate,
  formatCurrency,
  convertAmountToIndianUnits,
  stringToDateFormatter,
  extractLastSegment,
  formatText,
};
