import { Chip, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadScopeEnum, LeadStatusEnum, Project } from 'types';

import LeadCardTemplate from 'components/Card/LeadCard';

import { fixToTwoLocalPrice } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  const navigate = useNavigate();

  return (
    <Grid item key={project._id} width={'100%'} md={1.5} lg={1}>
      <LeadCardTemplate
        title={project.name}
        segment={project.lead.segment}
        onClick={() => navigate(`${project._id}?projectName=${project.name}`)}
      >
        <Grid mt={1} container columns={3} alignItems="end">
          <Grid item color="gray" maxWidth="70%" container direction="column" rowSpacing={0.5}>
            <Typography variant="caption" fontWeight={700}>
              {getEnumKeyFromValue(LeadStatusEnum, project.lead.leadStatus)}
            </Typography>
            <Typography variant="caption" fontWeight={600}>
              {fixToTwoLocalPrice(project.lead.areaOrPower)} {project.lead.areaOrPowerUOM}
            </Typography>
          </Grid>
          <Grid
            sx={{
              position: 'absolute',
              right: '3%',
              bottom: 25,
            }}
          >
            <Grid container justifyContent="center" alignContent="center" rowGap={0.5}>
              <Grid item xs={12}>
                <Stack direction="row" alignContent="center" justifyContent="center" spacing={1}>
                  <Chip
                    label={getEnumKeyFromValue(LeadScopeEnum, project.lead.scope)}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      fontSize: '10px',
                      height: '25px',
                      borderWidth: '1.5px',
                      '.MuiChip-label': {
                        fontWeight: 600,
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LeadCardTemplate>
    </Grid>
  );
};

export default ProjectCard;
