import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useFilterSchema from 'hooks/useFilterSchema';
import { FC, useState } from 'react';
import { FiltersVariant } from 'types';

import { FormPanel } from 'components/FormPanel';
import Transition from 'components/Transition';

import { Filter } from './Filter';
import theme from './theme.module.scss';

type FiltersProps = {
  type: FiltersVariant;
  refetch: (arg: any) => any;
};

const Filters: FC<FiltersProps> = ({ type, refetch }) => {
  const [open, setOpen] = useState(false);
  const [currFilters, setCurrFilters] = useState();
  const [filtersSchema] = useFilterSchema(type, currFilters);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <Button
        startIcon={<FilterAltIcon />}
        onClick={() => setOpen(true)}
        variant={'contained'}
        fullWidth
        size="small"
        color="secondary"
      >
        Filters
      </Button>
      <Dialog
        fullScreen={isMobileScreen}
        fullWidth={!isMobileScreen}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', bgcolor: theme => theme.palette.secondary.main }}>
          <Toolbar variant={isMobileScreen ? 'regular' : 'dense'}>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Filters
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={theme.modalContainer}>
          <FormPanel
            loading={false}
            onSubmit={data => {
              setCurrFilters(data);
              refetch(data);
              setOpen(false);
            }}
            error={null}
            submitButtonLabel="Apply"
            fixActionsAtBottom
            onReset={() => {}}
            enableClear
          >
            {filtersSchema.map((filter, index) => (
              <Filter key={index} {...filter} />
            ))}
          </FormPanel>
        </div>
      </Dialog>
    </div>
  );
};
export default Filters;
