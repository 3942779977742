import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Project } from 'types';

import ComplianceProjectsTemplate from 'components/Compliances/ComplianceProjectsTemplate';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const GET_PROJECTS_QUERY = gql`
  query GetCompliances($filter: ComplianceFilter) {
    getCompliances(filter: $filter) {
      _id
      name
      referenceId
      lead {
        _id
        name
        segment
        scope
        areaOrPower
        areaOrPowerUOM
        approxValue
        leadStatus
      }
      status
    }
  }
`;

type GetProjectsQueryResponse = {
  getCompliances: Project[];
};

type GetProjectsQueryVariables = {
  filter?: {
    searchTerm?: string;
    status?: string[];
  };
};

const ComplianceProjectsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const { data: projects, networkStatus: projectNetworkStatus } = useQuery<
    GetProjectsQueryResponse,
    GetProjectsQueryVariables
  >(GET_PROJECTS_QUERY, {
    variables: { filter: filters },
    notifyOnNetworkStatusChange: true,
  });

  const loadingProjects =
    projectNetworkStatus === NetworkStatus.loading ||
    projectNetworkStatus === NetworkStatus.setVariables ||
    !!!projects;

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <Navbar
      title="Compliances"
      searchInputConfig={{
        enable: true,
        name: 'Compliance search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      {loadingProjects ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <ComplianceProjectsTemplate projects={projects.getCompliances} />
      )}
    </Navbar>
  );
};

export default ComplianceProjectsPage;
