import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { Box, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import {
  DOWNLOAD_SHARED_FILES_AS_ZIP,
  GET_SHARED_FILES,
  GetSharedFilesQueryResponse,
} from 'graphql/query/files';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FolderTypeEnum } from 'types';

import DocumentFileCard from 'components/DocumentMaster/Card/DocumentCard';
import ExternalUsersFileTemplate from 'components/ExternalUserFiles/ExternalUsersFileTemplate';
import LoadingIndicator from 'components/LoadingIndicator';

import { logout } from 'utils/auth';

type DocumentViewType = 'list' | 'grid';

const ExternalUserFilesPage = () => {
  const [viewType, setViewType] = useState<DocumentViewType>('list');
  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const [downloadFolder, { loading: downloadingFolder }] = useLazyQuery<
    { downloadSharedFilesAsZip: string },
    {}
  >(DOWNLOAD_SHARED_FILES_AS_ZIP);

  const { data: docs, networkStatus: docsNetworkStatus } =
    useQuery<GetSharedFilesQueryResponse>(GET_SHARED_FILES);

  const loadingDocuments =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.setVariables ||
    !!!docs;

  const handleViewChange = (view: DocumentViewType) => {
    setViewType(view);
  };

  const isLead = folderType === FolderTypeEnum.LEAD;

  const handleDownloadFolder = () => {
    downloadFolder({
      variables: {
        folder: subFolderId,
        lead: isLead ? documentId : undefined,
        project: isLead ? undefined : documentId,
      },
      onCompleted: res => window.open(res.downloadSharedFilesAsZip, '_blank'),
    });
  };

  return (
    <Grid container direction={'column'} position="relative">
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        position="sticky"
        top={0}
        px={1.5}
        zIndex={10}
      >
        <img
          src={'https://www.woodkraft.com/wp-content/uploads/2021/09/logo-woodkraft.png'}
          height={35}
          alt="wk-logo"
        />

        <Box display="flex" alignItems="center">
          <Tooltip title="Download Folder">
            <IconButton>
              {downloadingFolder ? (
                <CircularProgress size={21} color="secondary" />
              ) : (
                <FileDownloadIcon color="secondary" onClick={handleDownloadFolder} />
              )}
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => handleViewChange('list')} color="secondary">
            <Tooltip title="List View">
              {viewType === 'list' ? (
                <ViewListOutlinedIcon
                  fontSize="medium"
                  sx={{
                    backgroundColor: orange[200],
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              ) : (
                <ViewListOutlinedIcon
                  fontSize="medium"
                  sx={{
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              )}
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleViewChange('grid')} color="secondary">
            <Tooltip title="Grid View">
              {viewType === 'grid' ? (
                <GridViewIcon
                  fontSize="small"
                  sx={{
                    backgroundColor: orange[200],
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              ) : (
                <GridViewIcon
                  fontSize="small"
                  sx={{
                    borderRadius: '4px',
                    padding: '4px',
                  }}
                />
              )}
            </Tooltip>
          </IconButton>
          <Tooltip title="Logout">
            <IconButton onClick={() => logout()} color="primary">
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item px={1.5}>
        {loadingDocuments ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <Grid minHeight="80vh" mt={0.5} mb={4}>
            {viewType === 'list' ? (
              <ExternalUsersFileTemplate files={docs.getSharedFiles} />
            ) : (
              <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center">
                {docs.getSharedFiles.map(file => (
                  <DocumentFileCard file={file} key={file._id} />
                ))}

                {docs.getSharedFiles.length === 0 && (
                  <Grid container justifyContent={'center'} mt={2}>
                    <Typography variant="subtitle1">No reports to show</Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ExternalUserFilesPage;
