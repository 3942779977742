import { NetworkStatus, gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { client } from 'graphql/client';
import {
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, FolderTypeEnum } from 'types';

import { DocumentFoldersGrid } from 'components/DocumentMaster/DocumentFolders';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

import { invalidFolderNameCharacters } from 'utils/regexes';

const CREATE_FOLDER_MUTATION = gql`
  mutation CreateFolderWithSubFolder($input: CreateFolderWithSubFolderInput!) {
    createFolderWithSubFolder(input: $input) {
      _id
      referenceId
      name
      type
      isStandardFolder
      parentFolder {
        _id
        name
      }
      project {
        _id
        name
        referenceId
      }
      lead {
        _id
        referenceId
        name
      }
    }
  }
`;

type CreateFolderMutationResponse = {
  createFolderWithSubFolder: Folder;
};

type CreateFolderMutationVariables = {
  input: {
    folderName: string;
    subFolderName: string;
    type: FolderTypeEnum;
    isStandardFolder: boolean;
    parentFolder?: string;
    project?: string;
    lead?: string;
  };
};

const DocumentFoldersPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [enableCreateNewForm, toggleCreateNewForm] = useState(false);
  const navigate = useNavigate();

  const { documentId = '', folderType = '' } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const {
    data: folders,
    networkStatus,
    refetch: refetchFolders,
  } = useQuery<GetFoldersQueryResponse, GetFoldersQueryVariables>(GET_FOLDERS_QUERY_FOR_LV, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: folderType as FolderTypeEnum,
      projectOrLeadId: documentId,
    },
  });

  if (!Object.values(FolderTypeEnum).includes(folderType as FolderTypeEnum)) {
    navigate('/companies');
    return null;
  }

  const folderActions = [
    <Button
      color="secondary"
      key="create-folder"
      variant="contained"
      size="small"
      onClick={() => toggleCreateNewForm(true)}
    >
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFolders(newFilters);
  };

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  return (
    <Navbar
      goBackButtonConfig={{
        title: !!!folders?.getFolders?.title ? '' : folders?.getFolders.title,
      }}
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={folderActions}
    >
      {loadingFolders ? (
        <LoadingIndicator />
      ) : (
        <DocumentFoldersGrid folders={folders?.getFolders?.folders ?? []} />
      )}
      <SimplePopup
        title="Create a new folder"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={enableCreateNewForm}
      >
        <CreateFolder cb={() => toggleCreateNewForm(false)} />
      </SimplePopup>
    </Navbar>
  );
};

const CreateFolder: React.FC<{ cb: (arg: any) => void }> = ({ cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const { documentId = '', folderType = '' } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateFolderMutationResponse,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION);

  const handleFolderNameChange = (fieldName: string, value: string) => {
    if (!invalidFolderNameCharacters.test(value)) {
      setFormState(prev => ({ ...prev, [fieldName]: value }));
    }
  };

  const handleSubmit = () => {
    const type = folderType as FolderTypeEnum;
    createFolder({
      variables: {
        input: {
          folderName: formState.folderName.trim(),
          subFolderName: formState.subFolderName.trim(),
          type,
          lead: type === FolderTypeEnum.LEAD ? documentId : undefined,
          project: type === FolderTypeEnum.PROJECT ? documentId : undefined,
          isStandardFolder: false,
        },
      },
      onCompleted: res => {
        updateFolderCachedList(res.createFolderWithSubFolder, {
          type,
          projectOrLeadId: documentId,
        });
        cb(res.createFolderWithSubFolder);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" gap={2} px={2} pt={1} pb={3}>
        <TextField
          label="Folder Name"
          value={formState.folderName ?? ''}
          onChange={e => handleFolderNameChange('folderName', e.target.value)}
          helperText={'Name cannot include these characters":  / : * ? " < > |'}
          required
        />
        <TextField
          label="Subfolder Name"
          value={formState.subFolderName ?? ''}
          onChange={e => handleFolderNameChange('subFolderName', e.target.value)}
          required
        />
        <LoadingButton variant="contained" type="submit" loading={creatingFolder}>
          Create
        </LoadingButton>
      </Grid>
    </form>
  );
};

export const updateFolderCachedList = (newFolder: Folder, variables?: Record<string, any>) => {
  try {
    const currFolders = client.readQuery({
      query: GET_FOLDERS_QUERY_FOR_LV,
      variables,
    }) ?? {
      getFolders: {
        title: '',
        folders: [],
      },
    };

    client.writeQuery({
      query: GET_FOLDERS_QUERY_FOR_LV,
      variables: variables,
      data: {
        getFolders: {
          ...currFolders.getFolders,
          folders: [newFolder, ...currFolders.getFolders.folders],
        },
      },
    });
  } catch (error) {
    console.error('Error updating cache:', error);
  }
};

export default DocumentFoldersPage;
