import { Grid } from '@mui/material';
import React from 'react';
import { LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum } from 'types';

import { RFQStatusEnum } from 'components/RFQs';
import Section, { SectionDataProps } from 'components/Section';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

const RFQSection: React.FC<{
  rfq: any;
}> = ({ rfq }) => {
  const rfqDetails: SectionDataProps[] = [
    {
      label: 'Lead',
      value: rfq.name,
      type: 'LINK',
      navigateTo: `/leads/${rfq._id}`,
    },
    {
      label: 'Company',
      value: rfq.company.name,
      type: 'LINK',
      navigateTo: `/companies/${rfq.company._id}`,
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(LeadStatusEnum, rfq.leadStatus),
      type: 'STRING',
    },
    { label: 'Segment', value: getEnumKeyFromValue(LeadSegmentEnum, rfq.segment), type: 'STRING' },
    { label: 'Scope', value: getEnumKeyFromValue(LeadScopeEnum, rfq.scope), type: 'STRING' },
    { label: 'Design & Build', value: rfq.isBuildAndDesign ? 'Yes' : 'No', type: 'BOOLEAN' },
    {
      label: 'Approx Value',
      value: `₹ ${rfq.approxValue} Cr`,
      type: 'STRING',
    },
    {
      label: 'Submission Date',
      value: rfq.rfpReceivedDate ? getDate(rfq.rfpReceivedDate) : 'N/A',
      type: 'STRING',
    },
    {
      label: 'RFQ Status',
      value: getEnumKeyFromValue(RFQStatusEnum, rfq.rfqStatus),
      type: 'STRING',
    },
  ];

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section title="RFQ Details" collapsible data={rfqDetails} />
    </Grid>
  );
};

export default RFQSection;
