import { gql } from '@apollo/client';
import { DocumentFile } from 'types';


export const GET_DOCUMENT_FILES_QUERY = gql`
  query GetFiles($folder: ID!, $project: ID, $lead: ID, $searchTerm: String) {
    getFiles(folder: $folder, project: $project, lead: $lead, searchTerm: $searchTerm) {
      title
      files {
        _id
        referenceId
        name
        path
        metadata
        createdAt
        createdBy {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export type GetFilesQueryResponse = {
  getFiles: {
    title: string;
    files: DocumentFile[];
  };
};

export type GetFilesQueryVariables = {
  folder: string;
  project?: string;
  lead?: string;
  searchTerm?: string;
};

export const GET_STATIC_FILES_QUERY = gql`
  query GetStaticFiles($folder: ID, $subFolder: ID, $searchTerm: String) {
    getStaticFiles(folder: $folder, subFolder: $subFolder, searchTerm: $searchTerm) {
      title
      files {
        _id
        referenceId
        name
        path
        shortPath
        metadata
        createdBy {
          _id
          firstName
          lastName
        }
      }
    }
  }
`;

export type GetStaticFilesQueryResponse = {
  getStaticFiles: {
    title: string;
    files: DocumentFile[];
  };
};

export type GetStaticFilesQueryVariables = {
  folder?: string;
  subFolder?: string;
  searchTerm?: string;
};


export const GET_SHARED_FILES = gql`
  query GetSharedFiles {
    getSharedFiles {
      _id
      referenceId
      name
      path
      metadata
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`;

export type GetSharedFilesQueryResponse = {
  getSharedFiles: DocumentFile[];
};

export const DOWNLOAD_SHARED_FILES_AS_ZIP = gql`
  query Query {
    downloadSharedFilesAsZip
  }
`;