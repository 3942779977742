import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Chip, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LeadStatusEnum } from 'types';

import LeadCard from 'components/Card/LeadCard';

import { formatText, getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

enum LeadScopeEnum {
  'GC' = 'GC',
  'C&I' = 'C_AND_I',
  STRUCTURE = 'STRUCTURE',
  'MEP' = 'MEP',
}

export enum RFQStatusEnum {
  CREATED = 'CREATED',
  'APPROVAL IN PROGRESS' = 'APPROVAL_IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

type IAcc = {
  key: string;
  items: any[];
};

const RFQsTemplate: React.FC<{ rfqs: any[]; rfqType?: string }> = ({ rfqs, rfqType }) => {
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const getAllRFQStatus = Object.values(RFQStatusEnum).map(rfq => ({
    key: rfq,
    items: [],
  }));

  const groupedLeads = rfqs.reduce((acc: IAcc[], rfq) => {
    const rfqStatus = rfq?.rfqStatus || RFQStatusEnum.CREATED;
    const existingGroup = acc.find(group => group?.key === rfqStatus);

    if (existingGroup) {
      existingGroup?.items?.push(rfq);
    } else {
      acc.push({ key: rfq?.rfqStatus, items: [rfq] });
    }
    return acc;
  }, getAllRFQStatus);

  const [itemSection, setItemSection] = useState(groupedLeads);

  useEffect(() => {
    if (!rfqs || rfqs.length === 0) {
      setItemSection(getAllRFQStatus);
    } else {
      setItemSection(groupedLeads);
    }
  }, [rfqs]);

  useEffect(() => {
    if (isMobileScreen) {
      if (rfqType) {
        const filteredSection = groupedLeads.filter(section => section.key === rfqType);
        setItemSection(filteredSection);
      } else {
        setItemSection(groupedLeads);
      }
    }
  }, [rfqType]);

  return (
    <Box display="flex" gap={2} p={2} borderRadius={2}>
      {itemSection &&
        itemSection?.map((section, index) => (
          <Box
            key={index}
            flex="1"
            maxHeight={'80vh'}
            minHeight={'80vh'}
            maxWidth={isMobileScreen ? '100%' : '25%'}
            borderRadius={2}
            boxShadow={2}
            display="flex"
            flexDirection="column"
            overflow={'hidden'}
          >
            <Box
              bgcolor={theme => theme.palette.secondary.light}
              color="#fff"
              textAlign="center"
              py={1}
              borderBottom="2px solid #ccc"
              borderRadius={1}
            >
              <Typography variant="h6" fontWeight="bold" textTransform="capitalize">
                {formatText(section?.key)}
              </Typography>
            </Box>

            <Box
              p={3}
              display="flex"
              flexDirection="column"
              gap={2}
              overflow="auto"
              sx={{
                maxWidth: '100%',
                flexWrap: 'nowrap',
                scrollbarWidth: 'thin',
              }}
            >
              {section?.items?.length > 0 ? (
                section?.items?.map((rfq, rfqIndex) => (
                  <Box key={rfqIndex}>
                    <LeadCard
                      onClick={() => navigate(`${rfq._id}`)}
                      segment={rfq?.segment}
                      title={rfq?.name}
                      key={rfqIndex}
                    >
                      <Grid mt={1} container columns={3} alignItems="end">
                        <Grid
                          item
                          color="gray"
                          maxWidth="70%"
                          container
                          direction="column"
                          rowSpacing={0.5}
                        >
                          <Typography variant="caption" fontWeight={700}>
                            {getEnumKeyFromValue(LeadStatusEnum, rfq?.leadStatus)}
                          </Typography>

                          {rfq.leadStatus === LeadStatusEnum['RFP RECEIVED'] &&
                          rfq.rfpReceivedDate ? (
                            <Typography variant="caption" fontWeight={600}>
                              {getDate(rfq.rfpReceivedDate)}
                            </Typography>
                          ) : (
                            <Typography variant="caption" fontWeight={600}>
                              N/A
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        sx={{
                          position: 'absolute',
                          right: '3%',
                          bottom: 25,
                        }}
                      >
                        <Grid container justifyContent="center" alignContent="center" rowGap={0.5}>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              alignContent="center"
                              justifyContent="center"
                              spacing={1}
                            >
                              {rfq?.rfqStatus === RFQStatusEnum.APPROVED && (
                                <VerifiedIcon fontSize="small" color="secondary" />
                              )}

                              <Chip
                                label={getEnumKeyFromValue(LeadScopeEnum, rfq.scope)}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  fontSize: '10px',
                                  height: '25px',
                                  borderWidth: '1.5px',
                                  '.MuiChip-label': {
                                    fontWeight: 600,
                                  },
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </LeadCard>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body2"
                  fontWeight="500"
                  color="textSecondary"
                  textAlign="center"
                  alignContent={'center'}
                >
                  No RFQs to show for {formatText(section?.key)}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default RFQsTemplate;
