import { NetworkStatus, gql, useLazyQuery, useQuery } from '@apollo/client';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDashboard } from 'contexts/dashboardContext';
import { useUser } from 'contexts/userContext';
import dayjs, { Dayjs } from 'dayjs';
import {
  USERS_NAMES_QUERY,
  UserNamesQueryResponse,
  UserNamesQueryVariables,
} from 'graphql/query/area';
import { useState } from 'react';
import {
  AutoCompleteOption,
  DepartmentEnum,
  LeadSegmentEnum,
  PerformanceDashboardType,
  PerformanceTimelineEnum,
} from 'types';

import LeadFilterCard from 'components/Dashboard/Cards/LeadFilterCard';
import CreatedCountsTemplate from 'components/Dashboard/PerformanceDashboard/KPITemplates/CreatedCounts';
import LeadStatusPerformanceTemplate from 'components/Dashboard/PerformanceDashboard/KPITemplates/LeadStatus';
import LeadWonAndLossPerformanceTemplate from 'components/Dashboard/PerformanceDashboard/KPITemplates/WonAndLoss';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';

import { getFinancialYearStartDate } from 'utils/common';
import { formatDate } from 'utils/transformFn';

import theme from './theme.module.scss';

const GET_PERFORMANCE_DASBOARD_QUERY = gql`
  query GetPerformanceData($input: PerformanceInput!) {
    getPerformanceData(input: $input) {
      events
      clients
      leads
      pqReceivedLeads {
        leadCount
        totalApproxValue
      }
      pqSubmittedLeads {
        leadCount
        totalApproxValue
      }
      rfpReceivedLeads {
        leadCount
        totalApproxValue
      }
      rfpSubmittedLeads {
        leadCount
        totalApproxValue
      }
      wonLeads {
        leadCount
        totalApproxValue
      }
      lostLeads {
        leadCount
        totalApproxValue
      }
      regretLeads {
        leadCount
        totalApproxValue
      }
    }
  }
`;

type GetPerformanceDataQueryResponse = {
  getPerformanceData: PerformanceDashboardType;
};

type GetPerformanceDataQueryVariables = {
  input: {
    timeline: PerformanceTimelineEnum;
    assignedTo?: string;
    segment?: LeadSegmentEnum[];
    customDateRange?: (string | null)[];
  };
};

const PerformanceDashboard = () => {
  const { user } = useUser();
  const isAdmin = user?.role.name === 'ADMIN';

  const { performanceFilters, setPerformanceFilters } = useDashboard();
  const [currDateFilter, setCurrDateFilter] = useState<(Dayjs | null)[]>([null, null]);
  const [openCalender, toggleCalenderView] = useState(false);
  const [selectedUsr, setSelectedUsr] = useState<AutoCompleteOption>({
    _id: user?._id as string,
    name: user?.firstName + ' ' + (user?.lastName ?? ''),
  });

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const {
    data: dashboard,
    networkStatus,
    refetch: refetchDashboard,
  } = useQuery<GetPerformanceDataQueryResponse, GetPerformanceDataQueryVariables>(
    GET_PERFORMANCE_DASBOARD_QUERY,
    {
      variables: {
        input: performanceFilters,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [getUsers, { loading: loadingUsers, data: users }] = useLazyQuery<
    UserNamesQueryResponse,
    UserNamesQueryVariables
  >(USERS_NAMES_QUERY);

  const usersOptions = () => {
    if (!isAdmin) {
      return [selectedUsr];
    }
    const transformedUsers =
      users?.getUsers?.map(usr => ({
        _id: usr._id,
        name: usr.firstName + ' ' + (usr.lastName ?? ''),
      })) ?? [];

    transformedUsers.push(
      { _id: user._id, name: user.firstName + ' ' + (user.lastName ?? '') },
      { _id: 'ALL', name: 'ALL' }
    );
    return transformedUsers;
  };

  const loadingDashboardData =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!dashboard;

  const handleKPIChange = (newSegments: LeadSegmentEnum[]) => {
    const newPerformanceFilters = {
      ...performanceFilters,
      segment: newSegments,
    };

    setPerformanceFilters(newPerformanceFilters);
    refetchDashboard({
      input: newPerformanceFilters,
    });
  };

  const handleTimelineChange = (val: PerformanceTimelineEnum) => {
    if (val === PerformanceTimelineEnum.CUSTOM) {
      toggleCalenderView(true);
      return;
    }

    setPerformanceFilters(prev => ({ ...prev, timeline: val, customDateRange: undefined }));
    setCurrDateFilter([null, null]);

    refetchDashboard({
      input: {
        ...performanceFilters,
        customDateRange: undefined,
        timeline: val,
      },
    });
  };

  const handleDateFilterSubmit = (data: (Dayjs | null)[]) => {
    toggleCalenderView(false);
    setPerformanceFilters(prev => ({ ...prev, timeline: PerformanceTimelineEnum.CUSTOM }));
    setCurrDateFilter(data);

    const newDateRange = [
      data[0]?.format('YYYY-MM-DD') ?? null,
      data[1]?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD'),
    ];

    refetchDashboard({
      input: {
        ...performanceFilters,
        timeline: PerformanceTimelineEnum.CUSTOM,
        customDateRange: newDateRange,
      },
    });
  };

  const handleUserChange = (val: AutoCompleteOption | null) => {
    const newPerformanceFilters = {
      ...performanceFilters,
      assignedTo: !!val?._id ? (val._id === 'ALL' ? undefined : val._id) : (user?._id as string),
    };

    setPerformanceFilters(newPerformanceFilters);
    setSelectedUsr(
      !!val?._id
        ? val
        : {
            _id: user?._id as string,
            name: user?.firstName + ' ' + (user?.lastName ?? ''),
          }
    );
    refetchDashboard({
      input: newPerformanceFilters,
    });
  };

  const formattedCustomDate = `${
    currDateFilter[0]
      ? formatDate(currDateFilter[0], 'DD/MM/YYYY')
      : formatDate(getFinancialYearStartDate(), 'DD/MM/YYYY')
  } - ${
    currDateFilter[1]
      ? formatDate(currDateFilter[1], 'DD/MM/YYYY')
      : formatDate(dayjs(), 'DD/MM/YYYY')
  }`;

  return (
    <>
      <Grid container direction="column" mb={5} justifyContent="center">
        <Grid className={theme.filtersContainer}>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/semiconductor.svg'}
              type={LeadSegmentEnum['SEMI CONDUCTOR']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/dc.svg'}
              type={LeadSegmentEnum['DATA CENTER (DC)']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/pharma.svg'}
              type={LeadSegmentEnum['RESEARCH LAB - LIFE SCIENCES']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/os.svg'}
              type={LeadSegmentEnum['OFFICE SPACE (OS)']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/edu-institute.svg'}
              type={LeadSegmentEnum['EDUCATION INSTITUTE']}
            />
          </Grid>
          <Grid className={theme.filterItem}>
            <LeadFilterCard
              onClick={handleKPIChange}
              currSegments={performanceFilters.segment}
              iconSrc={'/segment-icons/hospital.svg'}
              type={LeadSegmentEnum.HOSPITAL}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          rowSpacing={1.5}
          columnSpacing={1}
          width="100%"
          maxWidth="700px"
          mx="auto"
          mt={1}
        >
          <Grid
            item
            xs={
              isMobileScreen
                ? 12
                : performanceFilters.timeline === PerformanceTimelineEnum.CUSTOM
                ? 4
                : 6
            }
          >
            <AutocompleteWithFetch
              disabled={!isAdmin}
              value={{ _id: selectedUsr._id, name: selectedUsr.name }}
              required
              size="small"
              fetch={getUsers}
              variables={{
                filter: {
                  department: DepartmentEnum.SALES,
                },
              }}
              options={usersOptions()}
              loading={loadingUsers}
              handleChange={val => handleUserChange(val)}
              label="Users"
            />
          </Grid>
          <Grid
            item
            xs={
              isMobileScreen
                ? performanceFilters.timeline === PerformanceTimelineEnum.CUSTOM
                  ? 6
                  : 12
                : performanceFilters.timeline === PerformanceTimelineEnum.CUSTOM
                ? 4
                : 6
            }
          >
            <Select
              label="Timeline"
              size="small"
              value={performanceFilters.timeline}
              options={Object.keys(PerformanceTimelineEnum).map(k => ({
                label: k,
                value: PerformanceTimelineEnum[k],
              }))}
              onChange={val => handleTimelineChange(val)}
            />
          </Grid>
          {performanceFilters.timeline === PerformanceTimelineEnum.CUSTOM && (
            <Grid item xs={isMobileScreen ? 6 : 4}>
              <Box
                display="flex"
                alignItems="center"
                columnGap={0.5}
                onClick={() => toggleCalenderView(true)}
              >
                <IconButton size="small">
                  <CalendarMonthIcon />
                </IconButton>
                <Typography fontWeight={500} variant="caption" sx={{ cursor: 'pointer' }}>
                  {formattedCustomDate}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        {loadingDashboardData ? (
          <Grid item xs={12} mt={3}>
            <LoadingIndicator />
          </Grid>
        ) : (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            rowSpacing={2}
            columnGap={2}
            width="100%"
            mt={2}
          >
            <CreatedCountsTemplate dashboard={dashboard.getPerformanceData} />
            <LeadStatusPerformanceTemplate dashboard={dashboard.getPerformanceData} />
            <LeadWonAndLossPerformanceTemplate dashboard={dashboard.getPerformanceData} />
          </Grid>
        )}
      </Grid>
      <SimplePopup
        onClose={() => toggleCalenderView(false)}
        open={openCalender}
        enableBackdropClickClosure
      >
        <DateRangeCalender initialDateRange={currDateFilter} onSubmit={handleDateFilterSubmit} />
      </SimplePopup>
    </>
  );
};

export const DateRangeCalender: React.FC<{
  onSubmit: (arg: (Dayjs | null)[]) => void;
  initialDateRange: (Dayjs | null)[];
}> = ({ initialDateRange, onSubmit }) => {
  const [fromCalender, setFromCalender] = useState<{ show: boolean; value: Dayjs | null }>({
    show: true,
    value: initialDateRange[0],
  });
  const [toCalender, setToCalender] = useState<{ show: boolean; value: Dayjs | null }>({
    show: false,
    value: initialDateRange[1],
  });

  return (
    <Grid container direction="column">
      <Typography variant="h6" px={2} pt={2} fontWeight={600}>
        {fromCalender.show ? 'Start Date' : 'End Date'}
      </Typography>
      {fromCalender.show && (
        <Grid container direction={'column'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={fromCalender.value}
              maxDate={!!toCalender.value ? toCalender.value : undefined}
              onChange={val =>
                setFromCalender(prev => ({
                  ...prev,
                  value: val,
                }))
              }
            />
          </LocalizationProvider>
          <Grid container item xs={12} mb={2} justifyContent={'flex-end'} pr={3}>
            <Button
              variant="outlined"
              onClick={() => {
                setFromCalender(prev => ({ ...prev, show: false }));
                setToCalender(prev => ({ ...prev, show: true }));
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
      {toCalender.show && (
        <Grid container direction={'column'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={toCalender.value}
              minDate={fromCalender.value ? fromCalender.value : undefined}
              onChange={val =>
                setToCalender({
                  show: true,
                  value: val,
                })
              }
            />
          </LocalizationProvider>
          <Grid container item xs={12} mb={2} justifyContent={'flex-end'} pr={3}>
            <Button
              variant="outlined"
              onClick={() => {
                setFromCalender(prev => ({ ...prev, show: true }));
                setToCalender(prev => ({ ...prev, show: false }));
              }}
              sx={{
                mr: 1,
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onSubmit([fromCalender.value, toCalender.value]);
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PerformanceDashboard;
