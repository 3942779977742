import { Children, ReactNode, isValidElement } from 'react';

export function getInitialDataAndValidatorsFromChildren(children: ReactNode) {
  let initialData = {};
  let validators = {};
  Children.forEach(children, element => {
    if (!isValidElement(element)) return;

    initialData = { ...initialData, [element.props.fieldName]: element.props.defaultValue };
    validators = { ...validators, [element.props.fieldName]: element.props.validators };
  });

  return [initialData, validators];
}
