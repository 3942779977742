import { ApolloQueryResult, NetworkStatus, gql, useMutation } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useUser } from 'contexts/userContext';
import { client } from 'graphql/client';
import { GET_DOCUMENT_FILES_QUERY, GetFilesQueryVariables } from 'graphql/query/files';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentFile, FolderTypeEnum, User } from 'types';

import LoadingIndicator from 'components/LoadingIndicator';
import { ConfirmationPopup } from 'components/Popup';
import DataGridTable from 'components/Table';

export const DELETE_FILE_MUTATION = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(_id: $id)
  }
`;

export type DeleteFileMutationVariables = {
  id: string;
};

const DocumentFilesListTemplate: React.FC<{
  docsNetworkStatus: NetworkStatus;
  docs: { title: string; files: DocumentFile[] };
  fetchDocs: (
    variables?: Partial<GetFilesQueryVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}> = ({ docsNetworkStatus, docs, fetchDocs }) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [deleteConfirmationPopup, toggleDeleteConfirmationPopup] = useState({
    state: false,
    id: '',
  });

  const navigate = useNavigate();

  const { user } = useUser();

  const shouldEnableDownloadButton = user?.role.name === 'EXTERNAL_USER';

  const {
    subFolderId = '',
    folderType = '',
    documentId = '',
  } = useParams<{
    subFolderId: string;
    folderType: FolderTypeEnum;
    documentId: string;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  const [deleteFile, { loading: deletingFile }] = useMutation<{}, DeleteFileMutationVariables>(
    DELETE_FILE_MUTATION
  );

  const columns: GridColDef[] = useMemo(() => {
    const actions = {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: params => (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}>
          {!shouldEnableDownloadButton && (
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteClick(params.row._id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleDownloadClick(params.row.path)}
          >
            <FileDownloadIcon />
          </IconButton>
        </Box>
      ),
      minWidth: 150,
      flex: 1,
    };

    if (isMobileScreen) {
      return [
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: 100,
          renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
        },
        {
          field: 'name',
          flex: 1,
          headerName: 'Name',
          minWidth: 150,
          onCellClick: (row: DocumentFile) => navigate(row._id),
        },
        actions,
      ];
    }
    return [
      {
        field: 'referenceId',
        headerName: 'ID',
        minWidth: 100,
        renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        minWidth: 150,
        onCellClick: (row: DocumentFile) => navigate(row._id),
      },
      {
        field: 'createdBy',
        flex: 1,
        headerName: 'Created By',
        minWidth: 200,
        valueGetter: (val: User) => val.firstName + ' ' + val.lastName ?? '',
        onCellClick: (row: DocumentFile) => navigate(row._id),
      },
      actions,
    ];
  }, [isMobileScreen]);

  const loadingDocs =
    docsNetworkStatus === NetworkStatus.loading ||
    docsNetworkStatus === NetworkStatus.refetch ||
    docsNetworkStatus === NetworkStatus.setVariables;

  const handleDeleteClick = (id: string) => {
    toggleDeleteConfirmationPopup({
      id,
      state: true,
    });
  };

  const handleDownloadClick = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ position: 'relative' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ minHeight: '60vh' }}>
              {loadingDocs || !!!docs ? (
                <LoadingIndicator size="1.6rem" />
              ) : (
                <DataGridTable
                  columns={columns}
                  rows={docs.files}
                  getRowId={row => row.referenceId}
                  hideFooterPagination
                  sx={{
                    maxHeight: '70vh',
                    maxWidth: isMobileScreen ? '90vw' : '82vw',
                    overflowY: 'scroll',
                    mx: 'auto',
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        {/* <Box sx={{ width: '100%' }}>
          <Pagination
            onPageChange={fetchDocs}
            totalCount={docs?.getDocumentMasters?.totalCount ?? 0}
            totalPages={docs?.getDocumentMasters?.totalPages ?? 1}
          />  
        </Box> */}
      </Box>
      <ConfirmationPopup
        onClose={() => toggleDeleteConfirmationPopup({ id: '', state: false })}
        onConfirmation={() => {
          deleteFile({
            variables: {
              id: deleteConfirmationPopup.id,
            },
            onCompleted: _ => {
              toast.success('File Deleted Successfully');
              deleteCachedFileItemFromList(
                {
                  folder: subFolderId,
                  lead: isLead ? documentId : undefined,
                  project: isLead ? undefined : documentId,
                },
                deleteConfirmationPopup.id
              );
              toggleDeleteConfirmationPopup({ id: '', state: false });
            },
          });
        }}
        open={deleteConfirmationPopup.state}
        title="Do you want to delete this document?"
        confirmationLabel="Yes"
        loading={deletingFile}
      />
    </>
  );
};

export const deleteCachedFileItemFromList = (
  variables: Record<string, any>,
  deletedItemId: string
) => {
  const currList = client.readQuery({
    query: GET_DOCUMENT_FILES_QUERY,
    variables,
  }) ?? {
    getFiles: {
      title: '',
      files: [],
    },
  };

  client.writeQuery({
    query: GET_DOCUMENT_FILES_QUERY,
    variables,
    data: {
      getFiles: {
        title: currList.title,
        files: (currList.files ?? []).map(item => item._id !== deletedItemId),
      },
    },
  });
};

export default DocumentFilesListTemplate;
