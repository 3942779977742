import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceChecklist } from 'types';

import { SingleComplianceChecklistForm } from 'components/Compliances/Forms/SingleComplianceChecklistForm';
import Navbar from 'components/Navbar';

const CreateComplianceCheckList = () => {
  const navigate = useNavigate();
  const { projectId = '' } = useParams<{ projectId: string }>();

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'Create Compliance Checklist',
      }}
    >
      <SingleComplianceChecklistForm
        cb={(checklist: ComplianceChecklist) =>
          navigate(`/compliances/${projectId}/${checklist._id}`, { replace: true })
        }
      />
    </Navbar>
  );
};

export default CreateComplianceCheckList;
