import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import BOQCreationSection from 'components/RFQs/Sections/BOQCreation';
import GoNogoSection from 'components/RFQs/Sections/GoNogoValidation';
import RFQSection from 'components/RFQs/Sections/RFQSection';

const GET_RFQ_BY_ID_QUERY = gql`
  query GetRFQById($id: ID!) {
    getRFQById(_id: $id) {
      _id
      referenceId
      name
      leadStatus
      segment
      scope
      company {
        name
        _id
      }
      rfqStatus
      approxValue
      rfpReceivedDate
      rfqValidation {
        _id
        referenceId
        lead {
          _id
          referenceId
          name
        }
        totalAreaForWork
        uom
        approxWorkValue
        executionTime
        advance
        onSupply
        ra
        handoverPercentage
        dlpYears
        dlpRetentionPercentage
        totalHandoverPercentage
        timeToDesign
        designRequirement
        designInvestmentRequired
        emd
        abg
        pbg
        otherBG
        paymentTermsNegotiable
        comments
        reasonForNogo
      }
      boqs {
        _id
        referenceId
        boqId
        name
        description
        uom
        qty
        cost
        margin
        discount
        wastage
        price
        rateAnalysis {
          _id
          referenceId
          name
          description
          baseQty
          baseUom
          items {
            item
            name
            qty
            uom
            actualCost
            modifiedCost
            totalCost
          }
        }
      }
    }
  }
`;

export type RFQQueryResponse = {
  getRFQById: {
    _id: string;
    rfqStatus: string;
  };
};

export type RFQQueryVariables = {
  id: string;
};

const RFQDetailsPage = () => {
  const [refetchRFQ, setRefetchRFQ] = useState<(() => Promise<any>) | null>(null);
  const { rfqId = '' } = useParams<{ rfqId: string }>();

  const {
    data: rfq,
    networkStatus,
    refetch,
  } = useQuery<RFQQueryResponse, RFQQueryVariables>(GET_RFQ_BY_ID_QUERY, {
    variables: {
      id: rfqId,
    },
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    setRefetchRFQ(refetch);
  }, [refetch]);

  const loadingRFQ =
    NetworkStatus.loading === networkStatus ||
    NetworkStatus.setVariables === networkStatus ||
    !!!rfq;

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'RFQ Details',
      }}
    >
      {loadingRFQ ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
          <RFQSection rfq={rfq.getRFQById} />
          <GoNogoSection rfq={rfq.getRFQById} rfqId={rfqId} refetchRFQ={refetch} />
          {(rfq.getRFQById?.rfqStatus !== 'CREATED' || rfq.getRFQById?.rfqStatus === null) && (
            <BOQCreationSection rfqId={rfqId} rfq={rfq.getRFQById} />
          )}
        </Grid>
      )}
    </Navbar>
  );
};

export default RFQDetailsPage;
