import EditNoteIcon from '@mui/icons-material/EditNote';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentFile, FolderTypeEnum } from 'types';

import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import { formatDate } from 'utils/transformFn';

import DocumentFileForm from '../Forms/DocumentFileForm';

const FileDetailsSection: React.FC<{ file: DocumentFile }> = ({ file }) => {
  const [enableEditForm, toggleEditForm] = useState(false);

  const { folderType = '' } = useParams<{
    folderType: FolderTypeEnum;
  }>();

  const isLead = folderType === FolderTypeEnum.LEAD;

  const data: SectionDataProps[] = [
    {
      label: 'Name',
      value: file.name,
      type: 'EXTERNAL_LINK',
      navigateTo: file.path,
    },
  ];

  if (isLead) {
    data.push({
      label: 'Lead',
      value: file.lead.name,
      navigateTo: `/leads/${file.lead._id}`,
      type: 'LINK',
    });
  } else {
    data.push({
      label: 'Project',
      value: file.project.name,
      navigateTo: `/projects/${file.project._id}`,
      type: 'LINK',
    });
  }

  Object.keys(file.metadata ?? {}).forEach(k =>
    data.push({
      label: k,
      value: file.metadata[k],
      type: 'STRING',
    })
  );

  data.push(
    {
      label: 'Created By',
      value: file.createdBy.firstName + ' ' + file.createdBy.lastName ?? '',
    },
    {
      label: 'Updated By',
      value: !!file.updatedBy
        ? file.updatedBy.firstName + ' ' + file.updatedBy.lastName ?? ''
        : undefined,
    },
    {
      label: 'Created At',
      value: formatDate(file.createdAt),
    },
    {
      label: 'Updated At',
      value: formatDate(file.updatedAt),
    }
  );

  return (
    <>
      <Section
        title={file.name}
        data={data}
        action={<EditNoteIcon onClick={() => toggleEditForm(true)} sx={{ cursor: 'pointer' }} />}
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update File"
        fullWidth
      >
        <DocumentFileForm
          initialData={{
            _id: file._id,
            file: file,
            metadata: Object.keys(file.metadata ?? {}).map(k => ({
              key: k,
              value: file.metadata[k],
            })),
            fileName: file.name,
            path: file.path,
          }}
          cb={() => toggleEditForm(false)}
        />
      </SimplePopup>
    </>
  );
};

export default FileDetailsSection;
