import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lead, LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import Filters from 'components/Filters';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RATemplate from 'components/RateAnalysis';

import { transformFilters } from 'utils/transformFn';

export const GET_RA_TEMPLATE_QUERY = gql`
  query GetRateAnalysisTemplates($filter: RateAnalysisTemplateFilter) {
    getRateAnalysisTemplates(filter: $filter) {
      rateAnalysisTemplates {
        _id
        referenceId
        name
        description
        baseQty
        baseUom
        items {
          item {
            _id
            referenceId
          }
          name
          qty
          uom
          cost
        }
      }
      totalCount
      totalPages
      currentPage
    }
  }
`;

type GetRATemplateQueryResponse = {
  getRateAnalysisTemplates: any[];
  totalCount: number;
  totalPages: number;
  currentPage: number;
};

type GetRATemplateQueryVariables = {
  filter: {
    searchTerm?: string;
    limit: number | null;
    page: number | null;
  };
};

const RateAnalysisPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const {
    data: raTemplate,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery(GET_RA_TEMPLATE_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      filter: filters,
      limit: paginationModel.pageSize || null,
      page: paginationModel?.page || null,
    },
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const handlePaginationChange = (newPaginationModel: { pageSize: number; page: number }) => {
    setPaginationModel(newPaginationModel);
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!raTemplate;

  return (
    <Navbar
      title="Rate Analysis Templates"
      searchInputConfig={{
        enable: true,
        name: 'Rate analysis search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate(`/rateanalysis/manage-ra?type=create`)}
          color="secondary"
        >
          <AddIcon fontSize="small" />
        </Button>
      }
    >
      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <RATemplate
          RATemplate={raTemplate.getRateAnalysisTemplates?.rateAnalysisTemplates || []}
          totalCount={raTemplate.getRateAnalysisTemplates?.totalCount || 0}
          paginationModel={paginationModel}
          setPaginationModel={handlePaginationChange}
        />
      )}
    </Navbar>
  );
};

export default RateAnalysisPage;
