import { ApolloQueryResult } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { PQQueryResponse, PQQueryVariables } from 'routes/PQs/PQDetails';
import { Lead, LeadScopeEnum, LeadSegmentEnum, LeadStatusEnum } from 'types';

import SubmitPQForm from 'components/PQs/Forms/SubmitPQForm';
import { SimplePopup } from 'components/Popup';
import Section, { SectionDataProps } from 'components/Section';

import { getDate } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

import UpdatePQForm from '../../Forms/UpdatePQForm';

const PQSection: React.FC<{
  lead: Lead;
  refetchPQ: (variables?: Partial<PQQueryVariables>) => Promise<ApolloQueryResult<PQQueryResponse>>;
}> = ({ lead, refetchPQ }) => {
  const [enableEditForm, toggleEditForm] = useState(false);

  const pqDetails: SectionDataProps[] = [
    {
      label: 'Lead',
      value: lead.name,
      type: 'LINK',
      navigateTo: `/leads/${lead._id}`,
    },
    {
      label: 'Company',
      value: lead.company.name,
      type: 'LINK',
      navigateTo: `/companies/${lead.company._id}`,
    },
    {
      label: 'Status',
      value: getEnumKeyFromValue(LeadStatusEnum, lead.leadStatus),
      type: 'STRING',
    },
    { label: 'Segment', value: getEnumKeyFromValue(LeadSegmentEnum, lead.segment), type: 'STRING' },
    { label: 'Scope', value: getEnumKeyFromValue(LeadScopeEnum, lead.scope), type: 'STRING' },
    { label: 'Design & Build', value: lead.isBuildAndDesign ? 'Yes' : 'No', type: 'BOOLEAN' },
  ];

  if (lead.approxValue) {
    pqDetails.push({
      label: 'Approx Value',
      value: `₹ ${lead.approxValue} Cr`,
      type: 'STRING',
    });
  }
  if (lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] && lead.pqReceivedDate) {
    pqDetails.push({
      label: 'Submission Date',
      value: getDate(lead.pqReceivedDate),
      type: 'STRING',
    });
  }

  if (lead.leadStatus === LeadStatusEnum['PQ SUBMITTED'] && lead.pqSubmittedDate) {
    pqDetails.push(
      {
        label: 'Submitted Date',
        value: getDate(lead.pqSubmittedDate),
      },
      { label: 'Submit Type', value: lead.pqSubmitType }
    );
  }

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="PQ Details"
        collapsible
        data={pqDetails}
        action={
          lead.leadStatus === LeadStatusEnum['PQ RECEIVED'] ? (
            <EditIcon
              sx={{ cursor: 'pointer' }}
              fontSize="small"
              onClick={() => toggleEditForm(true)}
            />
          ) : undefined
        }
      />
      <SimplePopup
        onClose={() => toggleEditForm(false)}
        open={enableEditForm}
        title="Update PQ"
        fullWidth
      >
        <UpdatePQForm
          cb={() => {
            refetchPQ({
              id: lead._id,
            });
            toggleEditForm(false);
          }}
          lead={lead}
        />
      </SimplePopup>
    </Grid>
  );
};

export default PQSection;
