import { gql } from '@apollo/client';
import { Lead } from 'types';

export const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
    }
  }
`;

export type GetLeadsQueryResponse = {
  getLeads: Lead[];
};
