import { Button, Grid } from '@mui/material';
import React from 'react';
import { CompanyType } from 'types';

import Fieldset from 'components/Fieldset';
import { Select } from 'components/Inputs/Select';
import TextField, { ContactTextField } from 'components/Inputs/TextField';

const CreateCompanyForm: React.FC<{
  formState: Record<string, any>;
  handleNext: () => void;
  setFormState: (arg: any) => void;
}> = ({ formState, handleNext, setFormState }) => {
  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleContactChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      contact: {
        ...(prev.contact ?? {}),
        [fieldName]: value,
      },
    }));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleNext();
      }}
    >
      <Grid container columnSpacing={2} rowGap={2}>
        <Grid item xs={6}>
          <TextField
            label="Company Name"
            value={formState.name ?? ''}
            onChange={e => handleChange('name', e.target.value)}
            required
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Type"
            value={formState.type ?? ''}
            onChange={val => handleChange('type', val)}
            required
            options={Object.values(CompanyType).map(o => ({ label: o, value: o }))}
          />
        </Grid>
        <Grid item xs={12}>
          <Fieldset label="Contact Person Details">
            <Grid columns={12} container columnSpacing={2} rowGap={2}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  value={formState.contact?.name ?? ''}
                  onChange={e => handleContactChange('name', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={formState.designation}
                  onChange={e => handleContactChange('designation', e.target.value)}
                  label="Designation"
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  type="email"
                  value={formState.contact?.email}
                  onChange={e => handleContactChange('email', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <ContactTextField
                  label="Mobile"
                  onChange={e => handleContactChange('mobile', e.target.value)}
                  value={formState.contact?.mobile ?? ''}
                  required
                />
              </Grid>
            </Grid>
          </Fieldset>
        </Grid>
        <Grid item xs={12} textAlign={'end'}>
          <Button variant="outlined" type="submit">
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateCompanyForm;
