import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Lead, LeadSegmentEnum, LeadSortOptionEnum, LeadStatusEnum, MonthTypeEnum } from 'types';

import Filters from 'components/Filters';
import { Sort } from 'components/Leads/Sort/Sort';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import RFQsTemplate, { RFQStatusEnum } from 'components/RFQs';

import { transformFilters } from 'utils/transformFn';

export const GET_RFQS_QUERY = gql`
  query GetRFQs($filter: LeadFilter) {
    getRFQs(filter: $filter) {
      _id
      referenceId
      name
      leadStatus
      segment
      scope
      rfqStatus
      rfpReceivedDate
    }
  }
`;

type GetPQsQueryResponse = {
  getRFQs: Lead[];
};

type GetPQsQueryVariables = {
  filter: {
    leadStatus?: LeadStatusEnum[];
    monthType?: MonthTypeEnum;
    segment?: LeadSegmentEnum[];
    searchTerm?: string;
    sortOption?: LeadSortOptionEnum;
  };
};

const RFQPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [rfqType, setRfqType] = useState(RFQStatusEnum.CREATED);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const {
    data: rfqs,
    networkStatus,
    refetch: refetchLeads,
  } = useQuery<GetPQsQueryResponse, GetPQsQueryVariables>(GET_RFQS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchLeads({
      filter: newFilters,
    });
  };

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!rfqs;

  return (
    <Navbar
      title="RFQ's"
      searchInputConfig={{
        enable: true,
        name: 'RFQ search',
        placeholder: 'ID / Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
    >
      {isMobileScreen ? (
        <Grid container spacing={0.5} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={6} sm={6}>
            <Button
              variant="outlined"
              onClick={() => setRfqType(RFQStatusEnum.CREATED)}
              color="secondary"
              fullWidth
              size="small"
            >
              Created
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant="outlined"
              onClick={() => setRfqType(RFQStatusEnum['APPROVAL IN PROGRESS'])}
              color="secondary"
              fullWidth
              size="small"
            >
              Approval in Progress
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant="outlined"
              onClick={() => setRfqType(RFQStatusEnum.APPROVED)}
              color="secondary"
              fullWidth
              size="small"
            >
              Approved
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant="outlined"
              onClick={() => setRfqType(RFQStatusEnum.REJECTED)}
              color="secondary"
              fullWidth
              size="small"
            >
              Rejected
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="flex-end"
          columnSpacing={2}
          mb={2}
          pr={isMobileScreen ? 0 : 2}
          pt={isMobileScreen ? 0.5 : 0}
        >
          <Grid item xs={6} md={1.8} xl={1}>
            <Filters
              type="pqs"
              refetch={data => {
                applyFilters(transformFilters(data, ['city', 'company']));
              }}
            />
          </Grid>
          <Grid item xs={6} md={1.5} xl={1}>
            <Sort
              sort={filters.sortOption}
              setSort={newSortVal =>
                applyFilters({
                  ...filters,
                  sortOption: newSortVal,
                })
              }
            />
          </Grid>
        </Grid>
      )}

      {loadingLeads ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <RFQsTemplate rfqs={rfqs.getRFQs} rfqType={rfqType} />
      )}
    </Navbar>
  );
};

export default RFQPage;
