import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  ButtonOwnProps,
  ButtonProps,
  Grid,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { FC } from 'react';

import { VisuallyHiddenInput } from '../VisuallyHiddenInput';
import theme from './theme.module.scss';

type DocumentFile = { _id: string; name: string; path: string };
type FileType = File | DocumentFile;

const UploadFile: FC<{
  values?: FileType[] | FileType;
  onChange: (arg: any) => void;
  helperText?: string;
  accept?: string;
  required?: boolean;
  multiple?: boolean;
  label: string;
  buttonProps?: ButtonProps;
}> = ({
  values,
  onChange,
  helperText,
  accept,
  required = false,
  multiple = false,
  label,
  buttonProps,
}) => {
  const deleteDoc = (doc: DocumentFile | File) => {
    if (multiple) onChange((values as FileType[]).filter(d => d !== doc));
    else onChange('');
  };

  const handleChange = (e: any) => {
    if (multiple) {
      onChange([...(e.target.files ?? []), ...((values as FileType[]) ?? [])]);
    } else {
      onChange(!!e.target.files ? e.target.files[0] : '');
    }
  };

  const getlabelAndURL = (doc: FileType) => {
    const label = doc.name;
    const url = doc instanceof File ? URL.createObjectURL(doc) : doc.path;

    return { label, url };
  };

  return (
    <>
      <Grid container direction="column" rowGap={1}>
        <Button component="label" variant="outlined" {...buttonProps} className={theme.content}>
          <VisuallyHiddenInput
            required={required}
            multiple={multiple}
            type="file"
            accept={accept}
            onChange={handleChange}
          />
          <BackupIcon
            color="primary"
            // @ts-ignore
            fontSize={!!buttonProps?.sx?.fontSize ? 'small' : 'inherit'}
            sx={{
              mr: 1,
            }}
          />
          <span>{label}</span>
        </Button>

        {!!values && (
          <Grid container direction="column" rowGap={0.5}>
            {multiple ? (
              (values as (File | DocumentFile)[]).map(doc => {
                const { label, url } = getlabelAndURL(doc);
                return <LineItem label={label} url={url} onDelete={() => deleteDoc(doc)} />;
              })
            ) : (
              <LineItem
                label={getlabelAndURL(values as FileType).label}
                url={getlabelAndURL(values as FileType).url}
                onDelete={() => deleteDoc(values as FileType)}
              />
            )}
          </Grid>
        )}
      </Grid>
      <p className={theme.helperText}>{helperText}</p>
    </>
  );
};

const LineItem: React.FC<{ label: string; url: string; onDelete: () => void }> = ({
  label,
  url,
  onDelete,
}) => {
  return (
    <Grid item xs={6} key={label} container columnGap={2} alignItems="center">
      <Typography
        variant="body1"
        sx={{
          cursor: 'pointer',
          ':hover': {
            textDecoration: 'underline',
            textUnderlineOffset: 4,
          },
        }}
        onClick={() => window.open(url, '_blank')}
      >
        {label}
      </Typography>
      <CloseIcon
        fontSize="small"
        color="error"
        sx={{ cursor: 'pointer' }}
        onClick={() => onDelete()}
      />
    </Grid>
  );
};

export default UploadFile;
