import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentFile, User } from 'types';

import DataGridTable from 'components/Table';

const ExternalUsersFileTemplate: React.FC<{ files: DocumentFile[] }> = ({ files }) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        minWidth: 150,
        onCellClick: (row: DocumentFile) => navigate(row._id),
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        minWidth: 200,
        // @ts-ignore
        valueGetter: (val: User) => val.firstName + ' ' + val.lastName ?? '',
        onCellClick: (row: DocumentFile) => navigate(row._id),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        renderCell: params => (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}>
            <IconButton
              color="secondary"
              size="small"
              onClick={() => handleDownloadClick(params.row.path)}
            >
              <FileDownloadIcon />
            </IconButton>
          </Box>
        ),
        minWidth: 150,
      },
    ];
  }, [isMobileScreen]);

  const handleDownloadClick = (path: string) => {
    window.open(path, '_blank');
  };

  return (
    <DataGridTable
      columns={columns}
      rows={files}
      getRowId={row => row.referenceId}
      hideFooterPagination
      sx={{
        maxWidth: '100vw',
        height: '80vh',
        overflowY: 'auto',
        mx: 'auto',
      }}
    />
  );
};

export default ExternalUsersFileTemplate;
