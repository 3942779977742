import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router-dom';
import { ComplianceChecklist, FrequencyEnum } from 'types';

import Section, { SectionDataProps } from 'components/Section';

import { getEnumKeyFromValue } from 'utils/transformFn';

const ComplianceChecklistDetailsSection: React.FC<{
  complianceChecklist: ComplianceChecklist;
}> = ({ complianceChecklist }) => {
  const navigate = useNavigate();

  const sectionData: SectionDataProps[] = [
    {
      label: 'Refernce ID',
      value: complianceChecklist.referenceId,
      type: 'STRING',
    },
    {
      label: 'Project',
      value: complianceChecklist.project.name,
      type: 'STRING',
    },
    {
      label: 'Doc Name',
      value: complianceChecklist.docName,
      type: 'STRING',
    },
    {
      label: 'Folder',
      value: complianceChecklist.docType.name,
      type: 'LINK',
      navigateTo: `/documents/PROJECT/${complianceChecklist.project._id}/${complianceChecklist.docType.parentFolder?._id}/${complianceChecklist.docType._id}`,
    },
    {
      label: 'Frequency',
      value: getEnumKeyFromValue(FrequencyEnum, complianceChecklist.frequency),
      type: 'STRING',
    },
    {
      label: 'Schedule Starts',
      value: complianceChecklist.scheduleStarts,
      type: 'DATE',
    },
  ];

  if (!!complianceChecklist.scheduleStarts) {
    sectionData.push({
      label: 'Schedule Ends',
      value: complianceChecklist.scheduleEnds,
      type: 'DATE',
    });
  }

  sectionData.push(
    {
      label: 'Assign To',
      value: complianceChecklist.assignTo.map(usr => usr.firstName).join(', '),
      type: 'STRING',
    },
    {
      label: 'Status',
      value: complianceChecklist.status,
      type: 'STRING',
    }
  );

  return (
    <Section
      title="Compliance Details"
      collapsible
      data={sectionData}
      action={<EditNoteIcon onClick={() => navigate('edit')} sx={{ cursor: 'pointer' }} />}
    />
  );
};

export default ComplianceChecklistDetailsSection;
