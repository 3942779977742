import { gql } from '@apollo/client';
import { Folder, FolderTypeEnum } from 'types';



import { FolderNameEnum } from 'components/PQs/Sections/FolderSection';


export const GET_FOLDERS_QUERY_FOR_AC = gql`
  query GetFolders(
    $type: FolderTypeEnum!
    $projectOrLeadId: String
    $parentFolder: String
    $folderName: FolderNameEnum
  ) {
    getFolders(
      type: $type
      projectOrLeadId: $projectOrLeadId
      parentFolder: $parentFolder
      folderName: $folderName
    ) {
      title
      folders {
        _id
        referenceId
        name
        type
      }
    }
  }
`;

export const GET_FOLDERS_QUERY_FOR_LV = gql`
  query GetFolders(
    $type: FolderTypeEnum!
    $projectOrLeadId: String
    $parentFolder: String
    $searchTerm: String
    $folderName: FolderNameEnum
  ) {
    getFolders(
      type: $type
      projectOrLeadId: $projectOrLeadId
      parentFolder: $parentFolder
      searchTerm: $searchTerm
      folderName: $folderName
    ) {
      title
      folders {
        _id
        referenceId
        name
        type
        fileCount
        parentFolder {
          _id
          name
        }
        isStandardFolder
      }
    }
  }
`;

export type GetFoldersQueryResponse = {
  getFolders: {
    title: string;
    folders: Folder[];
  };
};

export type GetFoldersQueryVariables = {
  type: FolderTypeEnum;
  projectOrLeadId?: string;
  searchTerm?: string;
  parentFolder?: string;
  folderName?: FolderNameEnum;
};

export const GET_STATIC_FOLDERS_QUERY_FOR_LV = gql`
  query GetStaticFolders($type: FolderTypeEnum!, $parentFolder: String, $searchTerm: String) {
    getStaticFolders(type: $type, parentFolder: $parentFolder, searchTerm: $searchTerm) {
      title
      folders {
        _id
        referenceId
        name
        type
        fileCount
        parentFolder {
          _id
          name
        }
        isStandardFolder
      }
    }
  }
`;

export const GET_STATIC_FOLDERS_QUERY_FOR_AC = gql`
  query GetStaticFolders($type: FolderTypeEnum!, $parentFolder: String, $searchTerm: String) {
    getStaticFolders(type: $type, parentFolder: $parentFolder, searchTerm: $searchTerm) {
      title
      folders {
        _id
        referenceId
        name
      }
    }
  }
`;

export type GetStaticFoldersQueryResponse = {
  getStaticFolders: {
    title: string;
    folders: Folder[];
  };
};

export type GetStaticFoldersQueryVariables = {
  type: FolderTypeEnum;
  searchTerm?: string;
  parentFolder?: string;
};

export const DOWNLOAD_FOLDER_AS_ZIP = gql`
  query Query($folder: ID!, $lead: ID, $project: ID) {
    downloadFolderAsZip(folder: $folder, lead: $lead, project: $project)
  }
`;

export type DownloadFolderAsZipQueryVariables = {
  folder: string;
  lead?: string;
  project?: string;
};

export type DownloadFolderAsZipQueryResponse = {
  downloadFolderAsZip: string;
};

export const GET_FOLDER_BY_NAME = gql`
  query GetFolderByName($name: FolderNameEnum!, $lead: ID, $project: ID) {
    getFolderByName(name: $name, lead: $lead, project: $project) {
      _id
      name
    }
  }
`;

export type GetFolderByNameVariables = {
  name: string;
  lead?: string;
  project?: string;
};

export type GetFolderByNameResponse = { getFolderByName: Folder };