import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UOMEnum } from 'routes/RFQs/ManageBOQ';

import { SimplePopup } from 'components/Popup';
import UploadBOQForm from 'components/RFQs/UploadBOQForm';
import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const BOQCreationSection: React.FC<{
  rfqId: string;
  rfq: any;
}> = ({ rfqId, rfq }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const boqsData = rfq?.boqs;
  return (
    <Section
      title="BOQ Table"
      collapsible
      action={
        <>
          <EditNoteIcon
            onClick={() => navigate(`/rfqs/${rfqId}/bulk-update`)}
            sx={{ cursor: 'pointer', mr: 1 }}
          />
          <FileUploadIcon
            onClick={() => {
              setOpen(true);
            }}
            sx={{ cursor: 'pointer', mr: 1 }}
          />
          <AddIcon
            onClick={() => navigate(`/rfqs/manage-boq?type=create&rfqId=${rfqId}`)}
            sx={{ cursor: 'pointer' }}
          />
        </>
      }
    >
      <BOQCreationTable boqs={boqsData} rfqId={rfqId} />
      <SimplePopup onClose={() => setOpen(false)} open={open} title="Upload BOQs" fullWidth>
        <UploadBOQForm onCancel={() => setOpen(false)} />
      </SimplePopup>
    </Section>
  );
};

const BOQCreationTable: React.FC<{ boqs: []; rfqId?: string }> = ({ boqs, rfqId }) => {
  return (
    <DataGridTable
      pageSizeOptions={[2, 10, 20]}
      pagination
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: 100,
          renderCell: params => <Link to={`/rfqs/${rfqId}/${params.row._id}`}>{params.value}</Link>,
        },
        {
          field: 'boqId',
          headerName: 'BOQ Id',
          minWidth: 125,
        },
        {
          field: 'name',
          headerName: 'BOQ Name',
          minWidth: 200,
        },
        {
          field: 'qty',
          headerName: 'Quantity',
          minWidth: 150,
        },
        {
          field: 'uom',
          headerName: 'UOM',
          minWidth: 150,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
        {
          field: 'price',
          headerName: 'Price ₹',
          minWidth: 150,
          renderCell: params => params.row.price ?? 0,
        },
        {
          field: 'margin',
          headerName: 'Margin %',
          minWidth: 150,
          renderCell: params => params.row.margin ?? 0,
        },
        {
          field: 'wastage',
          headerName: 'Wastage %',
          minWidth: 150,
          renderCell: params => params.row.wastage ?? 0,
        },
        {
          field: 'discount',
          headerName: 'Discount %',
          minWidth: 150,
          renderCell: params => params.row.discount ?? 0,
        },
        {
          field: 'cost',
          headerName: 'Cost ₹',
          minWidth: 150,
          renderCell: params => params.row.cost ?? 0,
        },
      ]}
      rows={boqs}
      paginationMode="client"
      getRowId={row => row._id}
      sx={{
        height: '50vh',
        maxWidth: '80vw',
        mx: 'auto',
        mt: 0.5,
      }}
    />
  );
};

export default BOQCreationSection;
