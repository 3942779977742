import { NetworkStatus, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { GetLeadsQueryResponse } from 'graphql/query/lead';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';
import Navbar from 'components/Navbar';
import { GET_BOQ_BYID_QUERY } from 'components/RFQs/Sections/BOQSection';

import { removeEmptyFields } from 'utils/common';
import { getEnumKeyFromValue } from 'utils/transformFn';

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      name
      leadStatus
    }
  }
`;

const CREATE_BOQ_MUTATION = gql`
  mutation CreateBOQ($input: CreateBOQInput!) {
    createBOQ(input: $input) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      margin
      cost
      discount
      wastage
      # price
    }
  }
`;

const UPDATE_BOQ_MUTATION = gql`
  mutation UpdateBOQ($input: UpdateBOQInput!) {
    updateBOQ(input: $input) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      cost
      margin
      discount
      wastage
      # price
    }
  }
`;

type UpdateBOQMutationResponse = {
  createBOQ: any;
};

type UpdateBOQMutationVariables = {
  input: {
    _id: any;
    lead: any;
    boqId: string;
    name: string;
    description: string;
    qty: number;
    uom: UOMEnum;
    wastage: number;
    discount: number;
    margin: number;
    cost: number;
  };
};

export enum UOMEnum {
  'R Mts' = 'R_Mts',
  'R Ft' = 'R_Ft',
  'Sq Mts' = 'Sq_Mts',
  'Sq Ft' = 'Sq_Ft',
  'Cu Mts' = 'Cu_Mts',
  'Cu Ft' = 'Cu_Ft',
  Kgs = 'Kgs',
  Litres = 'Litres',
  Nos = 'Nos',
}

type CreateBOQMutationResponse = {
  createBOQ: any;
};

type CreateBOQMutationVariables = {
  input: {
    boqId: string;
    name: string;
    description: string;
    qty: number;
    uom: UOMEnum;
    wastage: null;
    discount: null;
    margin: null;
    cost: null;
    lead: string;
  };
};

const ManageBOQ = () => {
  const navigate = useNavigate();
  const queryString = useLocation()?.search;
  const params = new URLSearchParams(queryString);

  const type = params.get('type');
  const rfqId = params.get('rfqId');
  const boqId = params.get('boqId');
  const screenTitle = type === 'create' ? 'Create BOQ' : 'Update BOQ';

  const [formState, setFormState] = useState<Record<string, any>>({});

  // Get BOQ details by id
  const { data, networkStatus } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !boqId,
    variables: {
      id: boqId,
    },
  });

  const loadingBOQs =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  const boqDetails = data?.getBOQById;

  useEffect(() => {
    if (boqDetails) {
      setFormState({
        boqId: boqDetails?.boqId,
        name: boqDetails?.name,
        description: boqDetails?.description,
        qty: boqDetails?.qty,
        uom: boqDetails?.uom,
        wastage: boqDetails?.wastage,
        discount: boqDetails?.discount,
        margin: boqDetails?.margin,
        cost: boqDetails?.cost,
        price: boqDetails?.price,
      });
    }
  }, [boqDetails]);

  const [createBOQ, { loading: creatingBOQ }] = useMutation<
    CreateBOQMutationResponse,
    CreateBOQMutationVariables
  >(CREATE_BOQ_MUTATION);

  const [updateBOQ, { loading: updatingBOQ }] = useMutation<
    UpdateBOQMutationResponse,
    UpdateBOQMutationVariables
  >(UPDATE_BOQ_MUTATION);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const refinedData = removeEmptyFields({ ...formState });
    if (type === 'create') {
      createBOQ({
        variables: {
          input: {
            ...refinedData,
            lead: rfqId,
            discount: Number(formState.discount),
            margin: Number(formState.margin),
            cost: Number(formState.cost),
            // price: Number(formState.price),
            wastage: Number(formState.wastage),
            qty: Number(formState.qty),
          },
        },
        onCompleted: res => navigate(`/rfqs/${rfqId}`, { replace: true }),
      });
    } else {
      updateBOQ({
        variables: {
          input: {
            _id: boqId,
            lead: rfqId,
            name: String(formState.name),
            description: String(formState.description),
            uom: formState.uom,
            boqId: String(formState.boqId),
            discount: Number(formState.discount),
            margin: Number(formState.margin),
            cost: Number(formState.cost),
            // price: Number(formState.price),
            wastage: Number(formState.wastage),
            qty: Number(formState.qty),
          },
        },
        onCompleted: res => navigate(`/rfqs/${rfqId}/${boqId}`, { replace: true }),
      });
    }
  };

  return (
    <Navbar
      goBackButtonConfig={{
        title: screenTitle,
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={6} mt={0.5} rowGap={2.5}>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  value={formState.boqId ?? ''}
                  onChange={e => handleChange('boqId', e.target.value)}
                  label="BOQ ID"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  value={formState.name ?? ''}
                  onChange={e => handleChange('name', e.target.value)}
                  label="BOQ Name"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                minRows={3}
                value={formState.description ?? ''}
                onChange={e => handleChange('description', e.target.value)}
                label="BOQ Description"
              />
            </Grid>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <Select
                  name="uom"
                  label="UOM"
                  options={Object.keys(UOMEnum).map(o => ({
                    label: o,
                    value: UOMEnum[o],
                  }))}
                  value={formState.uom ?? ''}
                  onChange={val => handleChange('uom', val)}
                  // onChange={e => setFormState({ ...formState, uom: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  value={formState.qty ?? ''}
                  onChange={e => handleChange('qty', e.target.value)}
                  label="Quantity"
                  type="number"
                />
              </Grid>
            </Grid>
            {screenTitle === 'Update BOQ' && (
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    // required
                    value={formState.cost ?? ''}
                    onChange={e => handleChange('cost', e.target.value)}
                    label="Cost"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // required
                    disabled
                    value={formState.price ?? ''}
                    onChange={e => handleChange('price', e.target.value)}
                    label="Price"
                    type="number"
                  />
                </Grid>

                <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      // required={screenTitle === 'Update BOQ'}
                      value={formState.margin ?? ''}
                      onChange={e => handleChange('margin', e.target.value)}
                      label="Margin %"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // required
                      value={formState.wastage ?? ''}
                      onChange={e => handleChange('wastage', e.target.value)}
                      label="Wastage %"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      // required
                      value={formState.discount ?? ''}
                      onChange={e => handleChange('discount', e.target.value)}
                      label="Discount %"
                      type="number"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} alignSelf="center" container justifyContent="end">
              <LoadingButton
                variant="contained"
                size="medium"
                type="submit"
                color="secondary"
                loading={creatingBOQ || updatingBOQ}
              >
                {screenTitle}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Navbar>
  );
};

export default ManageBOQ;
