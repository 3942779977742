import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import Navbar from 'components/Navbar';
import BOQSection from 'components/RFQs/Sections/BOQSection';

const BOQDetailsPage = () => {
  const { rfqId = '' } = useParams<{ rfqId: string }>();

  return (
    <Navbar
      goBackButtonConfig={{
        title: 'BOQ Details',
      }}
    >
      <Grid item container direction="column" xs={12} md={11} lg={9} xl={7} mb={5} rowGap={2.5}>
        <BOQSection rfqId={rfqId} />
      </Grid>
    </Navbar>
  );
};

export default BOQDetailsPage;
