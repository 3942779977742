import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import TextField from 'components/Inputs/TextField';

export function AuthSelector({
  onNext,
  formState,
  handleChange,
  isValidEmail,
  timerRef,
  startTimer,
  generatePasscode,
}) {
  return (
    <Grid container flexDirection="column" gap={2}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2" color={theme => theme.palette.primary.main}>
          LOGIN
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.email}
          onChange={e => handleChange('email', e.target.value)}
          label="Email"
          type="email"
          required
          error={formState.email?.length > 0 && (formState.email === '' || !isValidEmail)}
          helperText={
            formState.email?.length > 0 &&
            (formState.email === '' || !isValidEmail) && (
              <Typography color="red" sx={{ p: '0 !important' }}>
                Enter a valid email
              </Typography>
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={async () => {
            try {
              const response = await generatePasscode({
                variables: { email: formState.email },
              });

              if (response.errors || response.data?.generatePasscode === null) {
                onNext(1);
              } else {
                if (timerRef.current) clearInterval(timerRef.current);
                startTimer();
                onNext(2);
              }
            } catch (error) {
              onNext(1);
            }
          }}
          disabled={formState.email === '' || !isValidEmail}
        >
          Login with Passcode
        </Button>
      </Grid>
      <Grid item container flexDirection="column" rowGap={0.5} mt={-1}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={formState.email === '' || !isValidEmail}
                onChange={() => onNext(3)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Login With Password"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function PasswordScreen({
  onNext,
  formState,
  handleChange,
  handleTogglePassword,
  showPassword,
  errorMsg,
  isLoading,
  timerRef,
  startTimer,
  generatePasscode,
  setFormState,
}) {
  return (
    <Grid container flexDirection="column" gap={2}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2" color={theme => theme.palette.primary.main}>
          LOGIN
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.email}
          onChange={e => handleChange('email', e.target.value)}
          label="Email"
          disabled
          type="email"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.password}
          onChange={e => handleChange('password', e.target.value)}
          label="Password"
          required
          type={showPassword ? 'text' : 'password'}
          autoComplete=""
          error={!!errorMsg.password}
          helperText={errorMsg.password}
          FormHelperTextProps={{
            sx: {
              ml: 0.5,
              fontSize: 12,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item container flexDirection="column" rowGap={0.5} mt={-1}>
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onClick={() => {
                  onNext(2);
                  generatePasscode({
                    variables: { email: formState.email },
                    onCompleted: _ => {
                      if (timerRef.current) clearInterval(timerRef.current);
                      startTimer();
                    },
                  });

                  setFormState({ ...formState, passcode: '' });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Login With Passcode"
          />

          <Typography>
            <Link to={'/forgot-password'}>Forgot password?</Link>
          </Typography>
        </Grid>
        <LoadingButton
          loading={isLoading}
          disabled={!!!formState.password || formState.password === '' || !!errorMsg.password}
          variant="contained"
          type="submit"
          fullWidth
        >
          Submit
        </LoadingButton>
        <LoadingButton
          variant="text"
          sx={{ mt: 0.5, typography: 'capation' }}
          size="small"
          onClick={() => onNext(1)}
        >
          Login with different email
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export function PasscodeScreen({
  onNext,
  formState,
  handleChange,
  setErrorMsg,
  errorMsg,
  passcodeTimer,
  isLoading,
  timerRef,
  startTimer,
  generatePasscode,
  setFormState,
}) {
  return (
    <Grid container flexDirection="column" gap={2}>
      <Grid item xs={12}>
        <Typography textAlign="center" variant="h2" color={theme => theme.palette.primary.main}>
          LOGIN
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.email}
          onChange={e => handleChange('email', e.target.value)}
          label="Email"
          disabled
          type="email"
          required
          sx={{
            // cursor: 'zoom-in',

            '&:disabled': {
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Passcode"
          placeholder="Enter 6-digit passcode"
          value={formState.passcode}
          onChange={e => {
            const value = e.target.value;
            if (/^\d{0,6}$/.test(value)) {
              handleChange('passcode', value);
              setErrorMsg({
                passcode: value.length !== 6 ? 'Please enter a 6-digit passcode' : '',
              });
            }
          }}
          required
          type="text"
          error={!!errorMsg.passcode}
          helperText={
            <Box>
              <Typography variant="caption" color={'crimson'}>
                Please check your email for passcode..
              </Typography>
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="caption" color={'crimson'}>
                  {errorMsg.passcode}
                </Typography>
              </Box>
            </Box>
          }
          FormHelperTextProps={{
            sx: {
              ml: 0.5,
              fontSize: 12,
            },
          }}
          inputProps={{
            maxLength: 6,
            inputMode: 'numeric',
            pattern: '\\d{6}',
          }}
        />
      </Grid>
      <Grid item container flexDirection="column" rowGap={0.5} mt={-1}>
        {passcodeTimer > 0 && (
          <Typography variant="caption" color={'crimson'} marginLeft={0.5}>
            Please wait for {passcodeTimer} seconds to resend passcode
          </Typography>
        )}
        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                disabled={passcodeTimer > 0}
                size="small"
                onChange={() => onNext(3)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Login With Password"
          />

          <LoadingButton
            variant="text"
            size="small"
            disabled={passcodeTimer > 0}
            onClick={() => {
              generatePasscode({
                variables: { email: formState.email },
                onCompleted: _ => {
                  if (timerRef.current) clearInterval(timerRef.current);
                  startTimer();
                },
              });
              formState.passcode = '';
              setFormState({ ...formState, passcode: '' });
            }}
          >
            Resend Passcode
          </LoadingButton>
        </Grid>
        <LoadingButton
          loading={isLoading}
          disabled={!!errorMsg.passcode || formState.passcode.length !== 6}
          variant="contained"
          type="submit"
          fullWidth
        >
          Submit
        </LoadingButton>
        <LoadingButton
          variant="text"
          sx={{ mt: 0.5, typography: 'capation' }}
          size="small"
          onClick={() => onNext(1)}
        >
          Login with different email
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
