import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { Dayjs } from 'dayjs';

import { Validators } from 'utils/textValidators';

export interface User {
  _id: string;
  empId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  mobile: string;
  status: string;
  employmentType: string;
  department: string;
  reportingManager: User;
  role: {
    _id: string;
    name: string;
  };
  passwordResetRequired: boolean;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
}

export interface Company {
  createdBy: User;
  _id: string;
  referenceId: string;
  name: string;
  type: CompanyType;
  status: CompanyStatus;
  branches: Branch[];
}

export interface Branch {
  _id: string;
  name: string;
  referenceId: string;
  address: AddressType;
  email: string;
  status: BranchStatusEnum;
  type: BranchTypeEnum;
  landline1?: string;
  company: Company;
  landline2?: string;
  landline3?: string;
  mobile1?: string;
  mobile2?: string;
  people: People[];
}

export enum CompanyType {
  CLIENT = 'CLIENT',
  PMC = 'PMC',
  ARCHITECT = 'ARCHITECT',
  'COST CONSULTANT' = 'COST_CONSULTANT',
  'WK CONSULTANT' = 'WK_CONSULTANT',
  OTHERS = 'OTHERS',
  COMPETITOR = 'COMPETITOR',
  PMO = 'PMO',
}

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type AddressType = {
  _id: string;
  referenceId: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  country: string;
  addressLocation: string;
  pincode: number;
  locationUrl: string;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export interface People {
  _id: string;
  referenceId: string;
  company: Company;
  branch: Branch;
  name: string;
  designation: string;
  email: string;
  mobile: string;
  status: PeopleStatusEnum;
  createdBy: User;
  updatedBy: User;
  createdAt: Date;
  updatedAt: Date;
}

export enum PeopleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BranchStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BranchTypeEnum {
  HO = 'HO',
  BRH = 'BRH',
}

export enum LeadSegmentEnum {
  'SEMI CONDUCTOR' = 'SEMI_CONDUCTOR',
  'RESEARCH LAB - LIFE SCIENCES' = 'RESEARCH_LAB_LIFESCIENCES',
  'OFFICE SPACE (OS)' = 'OFFICE_SPACE',
  'HOSPITAL' = 'HOSPITAL',
  'EDUCATION INSTITUTE' = 'EDUCATION_INSTITUTE',
  'DATA CENTER (DC)' = 'DATA_CENTER',
}

export enum LeadUnitOfMeasureEnum {
  SQFT = 'SQFT',
  MW = 'MW',
  'Number of Beds' = 'NO_OF_BEDS',
}

export interface Lead {
  _id: string;
  referenceId: string;
  name: string;
  company: Company;
  branch: Branch;
  architect: Company;
  architectBranch: Branch;
  pmc: Company;
  pmcBranch: Branch;
  costConsultant: Company;
  costConsultantBranch: Branch;
  wkConsultant: Company;
  wkConsultantBranch: Branch;
  others: Company[];
  competitors: Company[];
  pmo: Company;
  pmoBranch: Branch;
  salesPerson: User;
  leadStatus: LeadStatusEnum;
  segment: LeadSegmentEnum;
  scope: LeadScopeEnum;
  lostReason: LeadLostReasonEnum;
  regretReason?: string;
  address: AddressType;
  areaOrPower: number;
  areaOrPowerUOM: LeadUnitOfMeasureEnum;
  isBuildAndDesign: boolean;
  approxValue: number;
  poAmount: number;
  poIssuedBy: POIssuedByEnum;
  poNumber: string;
  probability: number;
  actualPODate: string;
  expectedPODate: string;
  pqSubmittedDate: string;
  pqReceivedDate: string;
  pqSubmitType: PQSubmitTypeEnum;
  rfpReceivedDate: string;
  rfpSubmittedDate: string;
  rfpSubmitType: RFPSubmitTypeEnum;
  followups: Followup[];
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
}

export enum PQSubmitTypeEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
export enum RFPSubmitTypeEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum LeadStatusEnum {
  'CREATED' = 'CREATED',
  'WIP' = 'WORK_IN_PROGRESS',
  'PQ RECEIVED' = 'PQ_RECEIVED',
  'PQ SUBMITTED' = 'PQ_SUBMITTED',
  'RFP RECEIVED' = 'RFP_RECEIVED',
  'RFP SUBMITTED' = 'RFP_SUBMITTED',
  WON = 'WON',
  LOST = 'LOST',
  REGRET = 'REGRET',
  'ON HOLD' = 'ON_HOLD',
}

export enum LeadStatusWithProbabilityEnum {
  'CREATED (0%)' = 'CREATED',
  'WORK IN PROGRESS (25%)' = 'WORK_IN_PROGRESS',
  'PQ RECEIVED (25%)' = 'PQ_RECEIVED',
  'PQ SUBMITTED (50%)' = 'PQ_SUBMITTED',
  'RFP RECEIVED (50%)' = 'RFP_RECEIVED',
  'RFP SUBMITTED (75%)' = 'RFP_SUBMITTED',
  'WON (100%)' = 'WON',
  'LOST (0%)' = 'LOST',
  REGRET = 'REGRET',
  'ON HOLD' = 'ON_HOLD',
}

export enum FunnelStateEnum {
  HOT = 'HOT',
  WARM = 'WARM',
  COLD = 'COLD',
}

export enum LeadScopeEnum {
  'GENERAL CONTRACT (GC)' = 'GC',
  'CIVIL & INTERIORS (C&I)' = 'C_AND_I',
  'STRUCTURE' = 'STRUCTURE',
  'MEP' = 'MEP',
}

export enum DepartmentEnum {
  IT = 'IT',
  SALES = 'SALES',
  CATEGORY = 'CATEGORY',
  CRM = 'CRM',
  MARKETING = 'MARKETING',
  SCM = 'SCM',
  DESIGN = 'DESIGN',
  FINANCE = 'FINANCE',
  HR = 'HR',
  LEGAL = 'LEGAL',
  COMPLIANCE = 'COMPLIANCE',
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum MonthsEnum {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export type LeadPeople = {
  _id: string;
  referenceId: string;
  lead: Lead;
  people: People;
  type: CompanyType;
  isDecisionMaker: boolean;
  createdBy: User;
  updatedBy: User;
  createdAt: Date;
  updatedAt: Date;
};

export interface PQ {
  _id: string;
  referenceId: string;
  lead: Lead;
  priority: PqPriorityEnum;
  status: PqStatusEnum;
  approvalStatus: PqApprovalStatusEnum;
  creationDate: Date;
  submitionDate: Date;
  documents: DocumentReference[];
  createdBy: User;
  updatedBy: User;
  createdAt: Date;
  updatedAt: Date;
}

export enum PqPriorityEnum {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum PqStatusEnum {
  'NOT STARTED' = 'NOT_STARTED',
  'IN PROGRESS' = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
}

export enum PqApprovalStatusEnum {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface DocumentMaster {
  _id: string;
  referenceId: string;
  type: DocumentTypeEnum;
  typeId: string;
  name: string;
  comment: string;
  version: DocumentMasterVersionEnum;
  path: string;
  financialYear: FinancialYears;
  status: DocumentMasterStatus;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
}

export enum DocumentMasterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DocumentTypeEnum {
  PQ = 'PQ',
  RFQ = 'RFQ',
  BOQ = 'BOQ',
  EMD = 'EMD',
  GEN = 'GEN',
}

export interface RfpTenderDetails {
  _id: string;
  referenceId: string;
  rfp: RFP;
  billingType: BillingTypeEnum;
  siteVisited: boolean;
  coefficient: number;
  discount: number;
  retention: number;
  startDate: Date;
  endDate: Date;
  submissionMode: SubmissionModeEnum;
  isGovtTender: boolean;
  emdNumber: string;
  emdAmount: number;
  emdEndDate: Date;
  emdNotifyBeforeDate: Date;
  emdDocument: DocumentReference[];
  createdBy: User;
  updatedBy: User;
  createdAt: Date;
  updatedAt: Date;
}

export enum BillingTypeEnum {
  PROGRESSIVE = 'PROGRESSIVE',
  MILESTONE = 'MILESTONE',
}

export enum SubmissionModeEnum {
  EMAIL = 'EMAIL',
  'E-TENDER' = 'E_TENDER',
  'IN-PERSON' = 'IN_PERSON',
}

export interface RFP {
  _id: string;
  referenceId: string;
  lead: Lead;
  pq: PQ;
  tenderDetails: RfpTenderDetails;
  tenderCommercials: RfpTenderCommercials;
  po: PO;
  createdBy: User;
  updatedBy: User;
  createdAt: Date;
  updatedAt: Date;
}

export interface RfpTenderCommercials {
  _id: string;
  referenceId: string;
  rfp: RFP;
  costType: CostTypeEnum;
  costSubType: CostSubTypeEnum;
  costValue: number;
  priceValue: number;
  marginValue: number;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
}

export enum CostTypeEnum {
  STRUCTURE = 'STRUCTURE',
  MEP = 'MEP',
  'C&I' = 'C_I',
}

export enum CostSubTypeEnum {
  SUPPLY = 'SUPPLY',
  INSTALLATION = 'INSTALLATION',
}

export interface PO {
  _id: string;
  referenceId: string;
  rfp: RFP;
  poOrLOIReferenceNumber: string;
  poOrLOIDate: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum FinancialYears {
  FY2020 = '2020',
  FY2021 = '2021',
  FY2022 = '2022',
  FY2023 = '2023',
  FY2024 = '2024',
  FY2025 = '2025',
}

export type DocumentReference = {
  _id: string;
  referenceId: string;
  documentMaster: DocumentMaster;
  type: string;
  typeId: string;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export type FiltersVariant =
  | 'document-master'
  | 'file-list'
  | 'leads'
  | 'static-files'
  | 'companies'
  | 'events'
  | 'pqs';

export type AutoCompleteOption = { name: string; _id: string; referenceId?: string };

export type FilterProps = {
  fieldName: string;
  type:
    | 'select'
    | 'select_with_search'
    | 'selection'
    | 'date'
    | 'range-slider'
    | 'date-range'
    | 'multiple_select'
    | 'toggle'
    | 'auto_complete_with_fetch'
    | 'string';
  defaultValue?: any;
  label: string;
  editable?: boolean;
  readOnlyMode?: boolean;
  options?: {
    label: string;
    value: any;
  }[];
  autoCompleteConfig?: {
    fetchOptionsFn: LazyQueryExecFunction<any, OperationVariables> | (() => void);
    loading: boolean;
    options: AutoCompleteOption[];
    labelWithId?: boolean;
    variables?: Record<string, any>;
  };
  validators?: Validators;
  fullWidth?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minValue?: number;
  maxValue?: number;
  minDistance?: number;
  minLabel?: string;
  maxLabel?: string;
  placeholder?: string;
  conditionToShow?:
    | {
        field: string;
        condition: '===' | '!==' | '>' | '<' | '>=' | '<=';
        value: string;
      }[]
    | {
        field: string;
        condition: 'in' | 'not_in';
        values: string[];
      }[];
  dangerouslySetInnerHTML?: { __html: string };
  disabled?: boolean;
  minRows?: number;
};
export enum FollowupTypeEnum {
  VISIT = 'VISIT',
  CALL = 'CALL',
}

export type Followup = {
  _id: string;
  referenceId: string;
  leadStatus: LeadStatusEnum;
  lead: Lead;
  followupType: FollowupTypeEnum;
  followupDate: string;
  pqReceivedDate: string;
  pqSubmittedDate: string;
  rfpReceivedDate: string;
  rfpSubmittedDate: string;
  followupContact: LeadPeople[];
  comments: string;
  lostReason: string;
  regretReason?: string;
  pastFollowups: Followup[];
  createdBy: User;
};

export enum LeadLostReasonEnum {
  'PROJECT DELAYED' = 'PROJECT_DELAYED',
  'CONTRACT AWARDED ELSEWHERE' = 'CONTRACT_AWARDED_ELSEWHERE',
  'OTHERS' = 'OTHERS',
}

export enum DocumentMasterVersionEnum {
  'VERSION 1' = 'VERSION_1',
  'VERSION 2' = 'VERSION_2',
  'VERSION 3' = 'VERSION_3',
  'VERSION 4' = 'VERSION_4',
  'VERSION 5' = 'VERSION_5',
  'VERSION 6' = 'VERSION_6',
  'VERSION 7' = 'VERSION_7',
  'VERSION 8' = 'VERSION_8',
  'VERSION 9' = 'VERSION_9',
  'VERSION 10' = 'VERSION_10',
}
export type IconProps = {
  color?: string;
  size?: string | number;
} & React.SVGAttributes<SVGElement>;

export interface Project {
  _id: string;
  referenceId: string;
  name: string;
  lead: Lead;
  status: any;
}

export enum FolderTypeEnum {
  LEAD = 'LEAD',
  STATIC = 'STATIC',
  PROJECT = 'PROJECT',
}

export type Folder = {
  _id: string;
  referenceId: string;
  name: string;
  type: FolderTypeEnum;
  parentFolder?: Folder;
  project?: Project;
  lead?: Lead;
  isStandardFolder: boolean;
  fileCount: number;
};

export type DocumentFile = {
  _id: string;
  referenceId: string;
  project: Project;
  lead: Lead;
  folder: Folder;
  name: string;
  path: string;
  metadata: Record<string, any>;
  createdBy: User;
  shortPath: string;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export const SectionTypes = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
};

export type LeadMonthSummary = {
  month: string;
} & LeadSummary;

export type LeadSummary = {
  leadCount: number;
  totalApproxValue: number;
};

export type LeadDashboardType = {
  regretLeads: LeadSummary;
  rfpSubmitted: LeadSummary;
  rfpInProgress: LeadSummary;
  pqSubmitted: LeadSummary;
  pqInProgress: LeadSummary;
  currentMonthLeads: LeadMonthSummary;
  nextMonthLeads: LeadMonthSummary;
  nextNextMonthLeads: LeadMonthSummary;
  otherMonthLeads: LeadMonthSummary;
  wonLeads: LeadSummary;
  lostLeads: LeadSummary;
};

export type PerformanceMetrics = {
  leadCount: number;
  totalApproxValue: number;
};

export type PerformanceDashboardType = {
  events: number;
  clients: number;
  leads: number;
  pqReceivedLeads: PerformanceMetrics;
  pqSubmittedLeads: PerformanceMetrics;
  rfpReceivedLeads: PerformanceMetrics;
  rfpSubmittedLeads: PerformanceMetrics;
  wonLeads: PerformanceMetrics;
  lostLeads: PerformanceMetrics;
  regretLeads: PerformanceMetrics;
};

export enum MonthTypeEnum {
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
  NEXT_NEXT = 'NEXT_NEXT',
  OTHERS = 'OTHERS',
}

export enum POIssuedByEnum {
  ARCHITECT = 'ARCHITECT',
  CLIENT = 'CLIENT',
  COST_CONSULTANT = 'COST_CONSULTANT',
  PMC = 'PMC',
  PMO = 'PMO',
  OTHERS = 'OTHERS',
}

export enum LeadSortOptionEnum {
  ValueHighToLow = 'ValueHighToLow',
  ValueLowToHigh = 'ValueLowToHigh',
  probabilityHighToLow = 'probabilityHighToLow',
  probabilityLowToHigh = 'probabilityLowToHigh',
  NameAToZ = 'NameAToZ',
  NameZToA = 'NameZToA',
}
export type City = {
  _id: string;
  name: string;
};

export enum FrequencyEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  FORTNIGHTLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  'HALF YEARLY' = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
  'ONE TIME' = 'ONE_TIME',
}

export type ComplianceChecklist = {
  _id: string;
  referenceId: string;
  docName: string;
  docType: Folder;
  frequency: FrequencyEnum;
  scheduleStarts: string;
  scheduleEnds: string;
  description: string;
  notificationLeadDays: number;
  assignTo: User[];
  project: Project;
  status: ComplianceChecklistStatus;
  pendingDocuments: ComplianceDocument[];
  uploadedDocuments: ComplianceDocument[];
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export enum DocStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  SUBMITTED = 'SUBMITTED',
  'NO DUE' = 'NO_DUE',
  'LATE SUBMISSION' = 'LATE_SUBMISSION',
}

export type ComplianceDocument = {
  _id: string;
  referenceId: string;
  complianceChecklist: ComplianceChecklist;
  metadata: string;
  docStatus: DocStatusEnum;
  status: ComplianceDocumentStatus;
  files: DocumentFile[];
  remarks: string;
  dueDate: string;
  uploadedBy: User;
  createdAt: string;
  updatedAt: string;
};

export type StandardComlianceDocument = {
  _id: string;
  referenceId: string;
  docType: Folder;
  docName: string;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
};

export enum ComplianceDocumentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ComplianceChecklistStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PerformanceTimelineEnum {
  TODAY = 'TODAY',
  'LAST WEEK' = 'LAST_WEEK',
  'LAST MONTH' = 'LAST_MONTH',
  'LAST QUARTER' = 'LAST_QUARTER',
  'TILL DATE' = 'TILL_DATE',
  CUSTOM = 'CUSTOM',
}
