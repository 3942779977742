import dayjs from 'dayjs';
import { AutoCompleteOption, CallStatusEnum, FollowupOutcomeEnum } from 'types/common';

import { emailRegex } from './regexes';
import { transformAutoCompleteToString } from './transformFn';

export const truncateString = (input: string, maxLength: number = 12): string => {
  if (!!!input) return '';
  if (input.length > maxLength) {
    return input.slice(0, maxLength) + '...';
  }
  return input;
};

/**
 *
 * checks if two objects are same or not
 * @param obj1 any
 * @param obj2 any
 * @returns boolean
 */
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const isObject = (obj: any) =>
  typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export const maskSensitiveDigits = (input: string | number | undefined): string => {
  if (input === null || input === undefined) {
    return '';
  }

  const inputString = String(input);

  const totalDigits = inputString.length;
  const numberOfDigitsToMask = Math.ceil(totalDigits * 0.4);

  if (numberOfDigitsToMask > totalDigits) {
    throw new Error('Too many digits to mask, at least one digit must be visible');
  }

  let maskSymbols = '';
  for (let i = 0; i < numberOfDigitsToMask; i++) {
    maskSymbols += 'X';
  }

  const visiblePart = inputString.slice(0, totalDigits - numberOfDigitsToMask);
  return visiblePart + maskSymbols;
};

export const maskAlternateLetters = (email: string | undefined): string => {
  if (email === null || email === undefined || email.trim() === '') {
    return '';
  }

  if (!emailRegex.test(email)) {
    throw new Error('Invalid email address format');
  }

  const emailParts = email.split('@');
  const username = emailParts[0];
  const domain = emailParts[1];

  let maskedUsername = '';
  for (let i = 0; i < username.length; i++) {
    maskedUsername += i % 2 === 0 ? username[i] : 'X';
  }

  return `${maskedUsername}@${domain}`;
};

export function isAutoCompleteOption(value: any): value is AutoCompleteOption {
  return typeof value === 'object' && value !== null && 'name' in value && '_id' in value;
}

export function isCustomerType(value: any): value is AutoCompleteOption {
  return typeof value === 'object' && value !== null && 'firstName' in value && '_id' in value;
}

export function getLastFiveChars(inputString: string): string {
  if (inputString.length <= 5) {
    return inputString;
  } else {
    return inputString.slice(-5);
  }
}

export function removeEmptyFields(obj: any) {
  if (isObject(obj)) {
    const newObj = JSON.parse(JSON.stringify(obj));
    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        const value = newObj[key];

        if (
          value === null ||
          value === undefined ||
          value === '' ||
          (Array.isArray(value) && !value.length)
        ) {
          delete newObj[key];
        } else if (isAutoCompleteOption(value) || isCustomerType(value)) {
          if (!value._id) delete newObj[key];
          else newObj[key] = newObj[key]._id;
        }
      }
    }
    return newObj;
  }
  return obj;
}

export const getUpdatedFields = (oldData: any = {}, newData: any = {}) => {
  const updatedFields = {} as any;

  for (const fieldName in newData) {
    const currValue = newData[fieldName];
    const oldValue = oldData[fieldName];

    if (!Object.hasOwn(oldData, fieldName)) {
      if (isAutoCompleteOption(currValue)) {
        if (!!currValue._id) updatedFields[fieldName] = transformAutoCompleteToString(currValue);
      } else if (!!currValue) {
        updatedFields[fieldName] = currValue;
      }
    } else if (isAutoCompleteOption(currValue)) {
      if (!currValue._id && oldValue) {
        updatedFields[fieldName] = null;
      } else if (
        (currValue._id && !oldValue) ||
        (currValue && oldValue && currValue._id !== oldValue._id)
      ) {
        updatedFields[fieldName] = currValue._id;
      }
    } else if (dayjs.isDayjs(currValue)) {
      if (currValue.format('YYYY-MM-DD') !== dayjs(oldValue).format('YYYY-MM-DD'))
        updatedFields[fieldName] = currValue.format('YYYY-MM-DD');
    } else if (!oldValue && oldValue !== false) {
      if (
        currValue !== null &&
        currValue !== undefined &&
        currValue !== '' &&
        currValue !== oldValue
      )
        updatedFields[fieldName] = currValue;
    } else if (oldValue && !currValue && currValue !== false) updatedFields[fieldName] = null;
    else if (
      (!isObject(oldValue) && !isObject(currValue) && oldValue !== currValue) ||
      (isObject(oldValue) && isObject(currValue) && !deepEqual(oldValue, currValue))
    )
      updatedFields[fieldName] = currValue;
  }

  return updatedFields;
};

export const getCallStatusMappedFollowupOutcomes = (callStatus: CallStatusEnum) => {
  const pickedOutcomes = Object.values(FollowupOutcomeEnum).filter(
    o => ![FollowupOutcomeEnum.CALLBACK, FollowupOutcomeEnum.NOT_REACHABLE].includes(o)
  );

  const map = {
    [CallStatusEnum.CALLBACK]: [FollowupOutcomeEnum.CALLBACK],
    [CallStatusEnum.DISCONNECTED]: [FollowupOutcomeEnum.CALLBACK],
    [CallStatusEnum.NOT_PICKED]: [FollowupOutcomeEnum.NOT_REACHABLE],
    [CallStatusEnum.NOT_REACHABLE]: [FollowupOutcomeEnum.NOT_REACHABLE],
    [CallStatusEnum.PICKED]: pickedOutcomes,
  };

  return map[callStatus] ?? [];
};

export const scrollToTop = () => {
  if (window) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

export const getHomeRoute = (userRole: string) => {
  switch (userRole) {
    case 'ADMIN':
      return '/';
    case 'EXTERNAL_USER':
      return '/external-user';
    case 'PQ_USER':
      return '/pqs';
    case 'USER':
      return '/';
    default:
      return '/';
  }
};

export const getFinancialYearStartDate = () => {
  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  return currentMonth >= 3 ? dayjs(`${currentYear}-04-01`) : dayjs(`${currentYear - 1}-04-01`);
};
