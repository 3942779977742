import { useLazyQuery } from '@apollo/client';
import { GET_LEAD_CITIES_QUERY } from 'graphql/query/cities';
import { GET_COMPANIES_QUERY } from 'graphql/query/company';
import {
  GET_STATIC_FOLDERS_QUERY_FOR_AC,
  GetStaticFoldersQueryResponse,
  GetStaticFoldersQueryVariables,
} from 'graphql/query/folder';
import { useEffect, useState } from 'react';
import {
  CompanyStatus,
  CompanyType,
  DocumentMasterStatus,
  DocumentTypeEnum,
  FilterProps,
  FiltersVariant,
  FolderTypeEnum,
  FollowupTypeEnum,
  LeadScopeEnum,
  LeadSegmentEnum,
  LeadStatusEnum,
} from 'types';

enum DocumentMasterType {
  LEAD = 'LEAD',
  PROJECT = 'PROJECT',
}

const getEventsFilterSchema = (initialData: any): FilterProps[] => {
  const { followupType, followupDateRange } = initialData;
  return [
    {
      fieldName: 'followupType',
      type: 'multiple_select',
      label: 'Status',
      fullWidth: true,
      defaultValue: followupType ?? [],
      options: Object.keys(FollowupTypeEnum).map(k => ({
        label: k,
        value: FollowupTypeEnum[k],
      })),
    },
    // {
    //   fieldName: 'followupDateRange',
    //   type: 'date-range',
    //   label: 'Followup',
    //   fullWidth: true,
    //   defaultValue: followupDateRange ?? [null, null],
    // },
  ];
};

const getCompaniesFilterSchema = (initialData: any): FilterProps[] => {
  const { status, type } = initialData;

  return [
    {
      fieldName: 'status',
      type: 'multiple_select',
      label: 'Status',
      fullWidth: true,
      defaultValue: status ?? [],
      options: Object.keys(CompanyStatus).map(k => ({
        label: k,
        value: CompanyStatus[k],
      })),
    },
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Company Type',
      fullWidth: true,
      defaultValue: type ?? [],
      options: Object.keys(CompanyType).map(k => ({
        label: k,
        value: CompanyType[k],
      })),
    },
  ];
};

const getFilesFilterSchema = (initialData: any): FilterProps[] => {
  const { type, status } = initialData;

  return [
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Document Type',
      fullWidth: true,
      placeholder: 'Type',
      defaultValue: type || [],
      options: Object.keys(DocumentTypeEnum).map(k => ({
        label: k,
        value: DocumentTypeEnum[k],
      })),
    },
    {
      fieldName: 'status',
      type: 'selection',
      label: 'Document Status',
      fullWidth: true,
      defaultValue: status ?? [null],
      options: Object.keys(DocumentMasterStatus).map(k => ({
        label: k,
        value: DocumentMasterStatus[k],
      })),
    },
  ];
};

const getDocumentsFilterSchema = (initialData: any): FilterProps[] => {
  const { type } = initialData;

  return [
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Document Type',
      fullWidth: true,
      placeholder: 'Type',
      defaultValue: type || [],
      options: Object.keys(DocumentMasterType).map(k => ({
        label: k,
        value: DocumentMasterType[k],
      })),
    },
  ];
};

const getStaticFilesSchema = (initialData: any): FilterProps[] => {
  const { folder } = initialData;

  return [
    {
      fieldName: 'folder',
      type: 'auto_complete_with_fetch',
      label: 'Folder',
      placeholder: 'Select Folder',
      defaultValue: folder ?? { name: '', _id: '', referenceId: '' },
      fullWidth: true,
    },
    // {
    //   fieldName: 'subFolder',
    //   type: 'auto_complete_with_fetch',
    //   label: 'Sub Folder',
    //   placeholder: 'Select subfolder',
    //   defaultValue: subFolder ?? { name: '', _id: '', referenceId: '' },
    // },
  ];
};

const getPQsFilterSchema = (initialData: any): FilterProps[] => {
  const { city, company, leadStatus, segment, scope, expectedPODateRange } = initialData;

  return [
    {
      fieldName: 'city',
      type: 'auto_complete_with_fetch',
      label: 'City',
      placeholder: 'Select City',
      defaultValue: city ?? { name: '', _id: '', referenceId: '' },
    },
    {
      fieldName: 'company',
      type: 'auto_complete_with_fetch',
      label: 'Company',
      defaultValue: company ?? { name: '', _id: '', referenceId: '' },
    },
    {
      fieldName: 'leadStatus',
      type: 'multiple_select',
      label: 'Lead Status',
      fullWidth: true,
      defaultValue: leadStatus ?? [],
      options: Object.keys(LeadStatusEnum).map(k => ({
        label: k,
        value: LeadStatusEnum[k],
      })),
    },
    {
      fieldName: 'segment',
      type: 'multiple_select',
      label: 'Segment',
      defaultValue: segment ?? [],
      options: Object.keys(LeadSegmentEnum).map(k => ({
        label: k,
        value: LeadSegmentEnum[k],
      })),
    },
    {
      fieldName: 'scope',
      type: 'multiple_select',
      label: 'Scope',
      defaultValue: scope ?? [],
      options: Object.keys(LeadScopeEnum).map(k => ({
        label: k,
        value: LeadScopeEnum[k],
      })),
    },
    {
      fieldName: 'expectedPODateRange',
      type: 'date-range',
      label: 'Expected PO',
      fullWidth: true,
      defaultValue: expectedPODateRange ?? [null, null],
    },
  ];
};

const getLeadsFilterSchema = (initialData: any): FilterProps[] => {
  const { city, company, leadStatus, segment, scope, expectedPODateRange } = initialData;

  return [
    {
      fieldName: 'city',
      type: 'auto_complete_with_fetch',
      label: 'City',
      placeholder: 'Select City',
      defaultValue: city ?? { name: '', _id: '', referenceId: '' },
    },
    {
      fieldName: 'company',
      type: 'auto_complete_with_fetch',
      label: 'Company',
      defaultValue: company ?? { name: '', _id: '', referenceId: '' },
    },
    {
      fieldName: 'leadStatus',
      type: 'multiple_select',
      label: 'Lead Status',
      fullWidth: true,
      defaultValue: leadStatus ?? [],
      options: Object.keys(LeadStatusEnum).map(k => ({
        label: k,
        value: LeadStatusEnum[k],
      })),
    },
    {
      fieldName: 'segment',
      type: 'multiple_select',
      label: 'Segment',
      defaultValue: segment ?? [],
      options: Object.keys(LeadSegmentEnum).map(k => ({
        label: k,
        value: LeadSegmentEnum[k],
      })),
    },
    {
      fieldName: 'scope',
      type: 'multiple_select',
      label: 'Scope',
      defaultValue: scope ?? [],
      options: Object.keys(LeadScopeEnum).map(k => ({
        label: k,
        value: LeadScopeEnum[k],
      })),
    },
    {
      fieldName: 'expectedPODateRange',
      type: 'date-range',
      label: 'Expected PO',
      fullWidth: true,
      defaultValue: expectedPODateRange ?? [null, null],
    },
  ];
};

const attachGqlFnsToAutocomplete = (
  fieldName: string,
  filters: FilterProps[],
  config: {
    fetchOptionsFn: () => void;
    loading: boolean;
    options: any[];
    variables?: Record<string, any>;
  }
) => {
  const idx = filters.findIndex(el => el.fieldName === fieldName);
  if (idx !== -1) {
    filters[idx].autoCompleteConfig = config;
  }
};

const useFilterSchema = (type: FiltersVariant, initialData: any) => {
  const [filterSchema, setFilterSchema] = useState<FilterProps[]>([]);

  const [getCompanies, { data: companies, loading: loadingCompanies }] =
    useLazyQuery(GET_COMPANIES_QUERY);

  const [getLeadCities, { data: cities, loading: loadingCities }] =
    useLazyQuery(GET_LEAD_CITIES_QUERY);

  const [getStaticFolders, { loading: loadingStaticFolders, data: staticFolders }] = useLazyQuery<
    GetStaticFoldersQueryResponse,
    GetStaticFoldersQueryVariables
  >(GET_STATIC_FOLDERS_QUERY_FOR_AC);

  // const [getStaticSubFolders, { loading: loadingStaticSubFolders, data: staticSubFolders }] =
  //   useLazyQuery<GetStaticFoldersQueryResponse, GetStaticFoldersQueryVariables>(
  //     GET_STATIC_FOLDERS_QUERY_FOR_AC,
  //     {
  //       variables: {
  //         type: FolderTypeEnum.STATIC,
  //         parentFolder: '',
  //       },
  //     }
  //   );

  useEffect(() => {
    const getfilterSchema = () => {
      let filters: FilterProps[];

      switch (type) {
        case 'pqs':
          filters = getPQsFilterSchema(initialData ?? {});

          attachGqlFnsToAutocomplete('company', filters, {
            fetchOptionsFn: getCompanies,
            loading: loadingCompanies,
            options: companies?.getCompanies ?? [],
          });

          attachGqlFnsToAutocomplete('city', filters, {
            fetchOptionsFn: getLeadCities,
            loading: loadingCities,
            options: cities?.getLeadCities ?? [],
          });
          break;
        case 'events':
          filters = getEventsFilterSchema(initialData ?? {});
          break;
        case 'companies':
          filters = getCompaniesFilterSchema(initialData ?? {});
          break;
        case 'document-master':
          filters = getDocumentsFilterSchema(initialData ?? {});
          break;
        case 'file-list':
          filters = getFilesFilterSchema(initialData ?? {});
          break;
        case 'leads':
          filters = getLeadsFilterSchema(initialData ?? {});

          attachGqlFnsToAutocomplete('company', filters, {
            fetchOptionsFn: getCompanies,
            loading: loadingCompanies,
            options: companies?.getCompanies ?? [],
          });

          attachGqlFnsToAutocomplete('city', filters, {
            fetchOptionsFn: getLeadCities,
            loading: loadingCities,
            options: cities?.getLeadCities ?? [],
          });
          break;
        case 'static-files':
          filters = getStaticFilesSchema(initialData ?? {});

          attachGqlFnsToAutocomplete('folder', filters, {
            fetchOptionsFn: getStaticFolders,
            loading: loadingStaticFolders,
            options: staticFolders?.getStaticFolders?.folders ?? [],
            variables: {
              type: FolderTypeEnum.STATIC,
            } as GetStaticFoldersQueryVariables,
          });

          // attachGqlFnsToAutocomplete('subFolder', filters, {
          //   fetchOptionsFn: getStaticSubFolders,
          //   loading: loadingStaticSubFolders,
          //   options: staticSubFolders?.getStaticFolders?.folders ?? [],
          //   variables: {
          //     type: FolderTypeEnum.STATIC,
          //     parentFolder: filters
          //   } as GetStaticFoldersQueryVariables,
          // });
          break;
        default:
          filters = [];
          break;
      }

      setFilterSchema(filters);
    };

    getfilterSchema();
  }, [
    type,
    initialData,
    companies,
    cities,
    staticFolders,
    loadingCities,
    loadingCompanies,
    loadingStaticFolders,
  ]);

  return [filterSchema];
};

export default useFilterSchema;
