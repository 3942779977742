import { Grid } from '@mui/material';
import React from 'react';
import { Project } from 'types';

import ProjectCard from './Cards/ProjectCard';

const ProjectTemplate: React.FC<{ projects: Project[] }> = ({ projects }) => {
  return (
    <Grid container columns={3} rowSpacing={3} columnSpacing={5} alignItems="center" mb={5} pr={2}>
      {projects.map(project => (
        <ProjectCard project={project} key={project._id} />
      ))}
    </Grid>
  );
};

export default ProjectTemplate;
