import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SyncIcon from '@mui/icons-material/Sync';
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { extractLastSegment } from 'utils/formatHelper';

const RAMappingSection: React.FC<{
  rfqId: string;
  rateAnalysisId: string;
  items: any[];
}> = ({ rfqId, items, rateAnalysisId }) => {
  const navigate = useNavigate();
  const boqId = extractLastSegment(window.location.href);

  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="Item List"
        collapsible
        action={
          <>
            <SyncIcon fontSize="small" onClick={() => navigate(`/rfqs/${rfqId}/${boqId}`)} />
            {items?.length > 0 ? (
              <EditNoteIcon
                fontSize="small"
                onClick={() =>
                  navigate(
                    `/rfqs/${rfqId}/${boqId}/map-boq-ra?type=update&rateAnalysisId=${rateAnalysisId}`
                  )
                }
                sx={{ cursor: 'pointer' }}
              />
            ) : (
              <AddIcon
                fontSize="small"
                onClick={() => navigate(`/rfqs/${rfqId}/${boqId}/map-boq-ra?type=create`)}
                sx={{ cursor: 'pointer' }}
              />
            )}
          </>
        }
      >
        <DataGridTable
          sx={{
            maxWidth: '80vw',
            mx: 'auto',
            mt: 0.5,
          }}
          columns={[
            {
              field: 'item',
              headerName: 'Item Id',
              minWidth: 150,
              editable: true,
              disableColumnMenu: true,
              disableReorder: true,
            },
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 150,
              editable: true,
              disableColumnMenu: true,
              disableReorder: true,
            },
            {
              field: 'uom',
              headerName: 'UOM',
              minWidth: 150,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'qty',
              headerName: 'Qty',
              minWidth: 165,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'actualCost',
              headerName: 'ERP Cost',
              minWidth: 165,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'modifiedCost',
              headerName: 'Modified Cost',
              minWidth: 165,
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
            },
            {
              field: 'totalCost',
              headerName: 'Amount',
              disableColumnMenu: true,
              disableReorder: true,
              sortable: false,
              minWidth: 165,
            },
          ]}
          getRowId={row => row.item}
          rows={items}
          hideFooterPagination
        />
      </Section>
    </Grid>
  );
};

export default RAMappingSection;
