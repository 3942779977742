import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { DateRangePicker } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import React, { Dispatch, FC, useContext } from 'react';
import { AutoCompleteOption } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import UploadFile from 'components/Inputs/UploadFile';
import UploadImage from 'components/Inputs/UploadImage';

import { getDate } from 'utils/formatHelper';
import getStatusDetail from 'utils/statusMessage';
import validate, { Validators } from 'utils/textValidators';

import FormPanelContext from './formPanelContext';
import { ActionType, UpdateErrorsActionType, UpdateStateActionType } from './formPanelReducer';
import theme from './theme.module.scss';

export type FormInputProps = {
  fieldName: string;
  type:
    | 'string'
    | 'number'
    | 'tel'
    | 'select'
    | 'select_with_search'
    | 'textarea'
    | 'currency'
    | 'upload_image'
    | 'upload_file'
    | 'date'
    | 'switch'
    | 'image_url'
    | 'date_range'
    | 'multiple_select'
    | 'auto_complete_with_fetch';
  defaultValue?: any;
  label: string;
  editable?: boolean;
  readOnlyMode?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  validators?: Validators;
  fullWidth?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  placeholder?: string;
  conditionToShow?:
    | {
        field: string;
        condition: '===' | '!==' | '>' | '<' | '>=' | '<=';
        value: string;
      }[]
    | {
        field: string;
        condition: 'in' | 'not_in';
        values: string[];
      }[];
  dangerouslySetInnerHTML?: { __html: string };
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  autoCompleteConfig?: {
    fetchOptionsFn: LazyQueryExecFunction<any, OperationVariables> | (() => void);
    loading: boolean;
    options: AutoCompleteOption[];
    variables?: any;
    labelWithId?: boolean;
  };
  accept?: string;
  multiple?: boolean;
  dateConfig?: Record<string, any>;
  setUpdatedValue?: (arg: any) => void;
};

let inputErrors = {};

const FormInput: FC<FormInputProps> = ({
  fieldName,
  type,
  label,
  editable = true,
  readOnlyMode = false,
  options,
  fullWidth = false,
  validators,
  minDate,
  maxDate,
  placeholder,
  conditionToShow,
  dangerouslySetInnerHTML,
  disabled = false,
  minRows = 3,
  maxRows = 3,
  autoCompleteConfig,
  accept,
  multiple = false,
  dateConfig = {},
  setUpdatedValue = _ => {},
}) => {
  const { formState, formDispatch } = useContext(FormPanelContext);
  const updateStateDispatch: Dispatch<ActionType & UpdateStateActionType> = formDispatch;
  const updateErrorsDispatch: Dispatch<ActionType & UpdateErrorsActionType> = formDispatch;

  const hideFormInput =
    conditionToShow &&
    ![...conditionToShow].every(c => {
      switch (c.condition) {
        case 'in':
          return c.values.includes(formState.data[c.field]);
        case 'not_in':
          return !c.values.includes(formState.data[c.field]);
        default:
          // eslint-disable-next-line
          return eval(`"${formState.data[c.field]}" ${c.condition} "${c.value}"`);
      }
    });

  if (validators) {
    if (validators.required !== undefined) {
      validators.required = !hideFormInput;
    }
    if (validators.isPhoneNumber !== undefined) {
      validators.isPhoneNumber = !hideFormInput;
    }
  }

  // onBlur event validating current field.
  function validateField(fieldName: string) {
    delete inputErrors[fieldName];

    if (validators) {
      const error = validate(validators, formState, formState.data[fieldName]);
      if (error) {
        inputErrors = { ...inputErrors, [fieldName]: error };
      }
    }

    let errors = inputErrors;

    updateErrorsDispatch({
      type: 'UPDATE_ERRORS',
      payload: { errors },
    });

    return errors;
  }

  const handleChange = value => {
    updateStateDispatch({
      type: 'UPDATE_STATE',
      payload: {
        fieldName,
        value,
      },
    });
    setUpdatedValue(value);
  };

  if (hideFormInput) {
    return null;
  }

  const inputField = () => {
    switch (type) {
      case 'string':
        return (
          <FormControl fullWidth>
            <TextField
              size="medium"
              variant="outlined"
              label={label}
              placeholder={placeholder}
              fullWidth
              value={formState.data[fieldName]}
              error={!!(formState.errors && formState.errors[fieldName])}
              required={validators?.required}
              onChange={e => handleChange(e.target.value)}
              onBlur={e => validateField(fieldName)}
              disabled={disabled}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'number':
        return (
          <FormControl fullWidth>
            <TextField
              type="number"
              placeholder={placeholder}
              size="medium"
              label={label}
              value={formState.data[fieldName]}
              error={!!(formState.errors && formState.errors[fieldName])}
              onChange={e => handleChange(e.target.value ? parseFloat(e.target.value) : '')}
              onBlur={e => validateField(fieldName)}
              disabled={disabled}
              required={validators?.required}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'tel':
        return (
          <FormControl fullWidth>
            <TextField
              type="tel"
              placeholder={placeholder}
              label={label}
              size="medium"
              value={formState.data[fieldName]}
              error={!!(formState.errors && formState.errors[fieldName])}
              onChange={e =>
                handleChange(
                  !!e.target.value && Number(e.target.value) ? parseFloat(e.target.value) : ''
                )
              }
              onBlur={e => validateField(fieldName)}
              disabled={disabled}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'currency':
        return (
          <FormControl fullWidth>
            <TextField
              type="number"
              placeholder={placeholder}
              size="medium"
              label={label}
              value={formState.data[fieldName]}
              error={!!(formState.errors && formState.errors[fieldName])}
              onChange={e => handleChange(e.target.value ? parseFloat(e.target.value) : '')}
              onBlur={e => validateField(fieldName)}
              helperText={formState.errors ? formState.errors[fieldName] : ''}
              InputProps={{
                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                inputProps: { min: 0 },
              }}
              disabled={disabled}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'select':
        return (
          <FormControl fullWidth>
            <InputLabel
              error={!!(formState.errors && formState.errors[fieldName])}
              id={fieldName + '-label'}
              required={validators?.required}
            >
              {label}
            </InputLabel>
            <Select
              labelId={fieldName + '-label'}
              label={label}
              size="medium"
              placeholder={placeholder}
              value={formState.data[fieldName]}
              fullWidth
              // required={!!validators?.dependsOn && !!formState.data[validators.dependsOn]}
              error={!!(formState.errors && formState.errors[fieldName])}
              onChange={e => handleChange(e.target.value)}
              onBlur={e => validateField(fieldName)}
              displayEmpty={true}
              renderValue={value =>
                value ? options?.find(o => o.value === value)?.label : placeholder
              }
              required={validators?.required}
              disabled={disabled}
            >
              {options &&
                options.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
            </Select>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'multiple_select':
        return (
          <FormControl fullWidth>
            <Select
              multiple
              value={formState.data[fieldName]}
              onChange={e =>
                handleChange(
                  // On autofill we get a stringified value.
                  typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                )
              }
              renderValue={selected => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip key={value} label={options?.find(o => o.value === value)?.label} />
                    ))}
                  </Box>
                );
              }}
              MenuProps={{ PaperProps: { sx: { maxHeight: '75%' } } }}
            >
              {options &&
                options.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    <Checkbox
                      size="medium"
                      checked={formState.data[fieldName].indexOf(o.value) > -1}
                    />
                    {o.label}
                  </MenuItem>
                ))}
            </Select>
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'select_with_search':
        return (
          <FormControl fullWidth>
            <Autocomplete
              id={fieldName}
              value={formState.data[fieldName] ?? { name: '', _id: '' }}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              includeInputInList
              disabled={disabled}
              onChange={(e, val) => handleChange(val ? val : null)}
              options={autoCompleteConfig ? autoCompleteConfig.options : []}
              renderInput={params => {
                return <TextField {...params} label={label} />;
              }}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'auto_complete_with_fetch':
        const config = autoCompleteConfig ?? {
          loading: false,
          options: [],
          fetchOptionsFn: () => {},
          variables: {},
          labelWithId: false,
        };

        return (
          <FormControl fullWidth error={!!(formState.errors && formState.errors[fieldName])}>
            <AutocompleteWithFetch
              value={formState.data[fieldName] ?? { name: '', _id: '', referenceId: '' }}
              loading={config.loading}
              options={config.options}
              handleChange={handleChange}
              disabled={disabled}
              label={label}
              labelWithId={!!config.labelWithId}
              onBlur={() => validateField(fieldName)}
              fetch={config.fetchOptionsFn}
              variables={config.variables}
              error={!!(formState.errors && formState.errors[fieldName])}
              required={validators?.required}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'switch':
        return (
          <Switch
            checked={formState.data[fieldName]}
            onChange={e => handleChange(e.target.checked)}
          />
        );
      case 'textarea':
        return (
          <FormControl fullWidth>
            <TextField
              multiline
              minRows={minRows}
              maxRows={maxRows}
              placeholder={placeholder}
              label={label}
              value={formState.data[fieldName]}
              onChange={e => handleChange(e.target.value)}
              onBlur={e => validateField(fieldName)}
              required={validators?.required}
              className={theme.textarea}
              error={!!(formState.errors && formState.errors[fieldName])}
              disabled={disabled}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'upload_image':
        return (
          <FormControl fullWidth>
            <UploadImage
              value={formState.data[fieldName]}
              onChange={files => handleChange(files)}
              label={label}
              accept={accept}
              multiple={multiple}
              required={validators?.required}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </FormControl>
        );
      case 'upload_file':
        return (
          <FormControl fullWidth>
            <UploadFile
              values={formState.data[fieldName]}
              onChange={(file: FileList) => handleChange(file)}
              helperText={formState.errors ? formState.errors[fieldName] : ''}
              accept={accept}
              multiple={multiple}
              label={label}
            />
          </FormControl>
        );
      case 'image_url':
        return (
          <TextField
            size="small"
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            error={!!(formState.errors && formState.errors[fieldName])}
            onChange={e => handleChange(e.target.value)}
            helperText={formState.errors ? formState.errors[fieldName] : ''}
          />
        );
      case 'date':
        return (
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={formState.data[fieldName]}
                onChange={handleChange}
                minDate={minDate}
                maxDate={maxDate}
                label={label}
                disabled={disabled}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth,
                    error: !!(formState.errors && formState.errors[fieldName]),
                    required: validators?.required,
                  },
                }}
                {...dateConfig}
              />
              <p className={theme.helperTextError}>
                {formState.errors && formState.errors[fieldName]}
              </p>
            </LocalizationProvider>
          </FormControl>
        );
      case 'date_range':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={formState.data[fieldName]}
              onChange={newValue => handleChange(newValue)}
              PopperProps={{
                placement: 'bottom-start',
              }}
              minDate={minDate}
              maxDate={maxDate}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} label={undefined} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} label={undefined} />
                </React.Fragment>
              )}
            />
            <p className={theme.helperTextError}>
              {formState.errors && formState.errors[fieldName]}
            </p>
          </LocalizationProvider>
        );
      default:
        return (
          <TextField
            size="small"
            fullWidth={fullWidth}
            value={formState.data[fieldName]}
            error={!!(formState.errors && formState.errors[fieldName])}
            onChange={e => handleChange(e.target.value)}
            onBlur={e => validateField(fieldName)}
            helperText={formState.errors ? formState.errors[fieldName] : ''}
            required={validators?.required}
          />
        );
    }
  };

  const staticText = () => {
    if (type === 'currency') {
      return (
        <p>
          ₹{' '}
          {formState.data[fieldName]
            ? formState.data[fieldName].toLocaleString('en-IN')
            : formState.data[fieldName]}
        </p>
      );
    }

    if (type === 'switch') {
      return <Switch checked={formState.data[fieldName]} disabled />;
    }

    if (type === 'image_url') {
      return formState.data[fieldName] ? (
        <img src={formState.data[fieldName]} alt={formState.data[fieldName]} height={50} />
      ) : (
        '-'
      );
    }

    if (type === 'upload_image') {
      return (
        <Button
          onClick={() =>
            formState.data[fieldName].url
              ? window.open(formState.data[fieldName].url, '_blank')
              : window.open(URL.createObjectURL(formState.data[fieldName]), '_blank')
          }
        >
          {formState.data[fieldName].name}
        </Button>
      );
    }

    if (type === 'select') {
      return (
        <span className={theme.status}>
          {getStatusDetail(formState.data[fieldName]).labelWithBadge}
        </span>
      );
    }

    if (type === 'multiple_select') {
      return formState.data[fieldName].map(value => (
        <span key={value} className={theme.status}>
          {getStatusDetail(value).labelWithBadge}
        </span>
      ));
    }

    if (type === 'date_range') {
      const startDate = getDate(formState.data.date[0], 'MM/DD/YYYY');
      const endDate = getDate(formState.data.date[1], 'MM/DD/YYYY');

      return <div className={theme.value}>{`${startDate} ${endDate}`}</div>;
    }

    if (dangerouslySetInnerHTML) {
      return <div dangerouslySetInnerHTML={{ __html: formState.data[fieldName] }} />;
    }

    return (
      <p style={{ overflowWrap: 'anywhere', marginTop: 0 }}>
        {formState.data[fieldName] !== (undefined || null) ? formState.data[fieldName] : '-'}
      </p>
    );
  };

  // if (validators?.dependsOn && !formState.data[validators.dependsOn]) {
  //   return null;
  // }

  return (
    <>
      <Grid item gridColumn={fullWidth ? '1/-1' : 'span 1/span 1'}>
        {readOnlyMode || !editable ? staticText() : inputField()}
      </Grid>
    </>
  );
};

export default FormInput;
