import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Sort } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Company, CompanyStatus, CompanyType } from 'types';

import CompaniesTemplate from 'components/Companies/CompaniesTemplate';
import Filters from 'components/Filters';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

const COMPANIES_QUERY = gql`
  query GetCompanies($filter: CompanyFilter) {
    getCompanies(filter: $filter) {
      _id
      name
      referenceId
      status
      type
      branches {
        _id
        email
        landline1
        mobile1
        mobile2
        name
        referenceId
        status
        type
        address {
          _id
          addressLine1
          addressLocation
          city
          country
          pincode
          state
        }
      }
    }
  }
`;

type CompaniesQueryResponse = {
  getCompanies: Company[];
};

type CompaniesQueryVariables = {
  filter: {
    searchTerm?: string;
    status?: CompanyStatus[];
    type?: CompanyType[];
  };
};

export const PRIMARY_BRANCH_NAME = 'HEAD OFFICE';

const CompaniesPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  const navigate = useNavigate();

  const {
    data: companies,
    networkStatus,
    refetch: refetchCompanies,
  } = useQuery<CompaniesQueryResponse, CompaniesQueryVariables>(COMPANIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchCompanies({
      filter: newFilters,
    });
  };

  const loadingCompanies =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!companies;

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  return (
    <Navbar
      title="Companies"
      searchInputConfig={{
        enable: true,
        name: 'Search',
        placeholder: 'Search a Company',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => navigate('create-company')}
        >
          <AddIcon fontSize="small" color="inherit" sx={{ color: 'white' }} />
        </Button>
      }
    >
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        mb={2}
        pr={isMobileScreen ? 0 : 2}
        pt={isMobileScreen ? 0.5 : 0}
      >
        <Grid item xs={6} md={1.8} xl={1}>
          <Filters
            type="companies"
            refetch={data =>
              applyFilters({
                ...data,
              })
            }
          />
        </Grid>
      </Grid>
      {loadingCompanies ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <CompaniesTemplate companies={companies.getCompanies} />
      )}
    </Navbar>
  );
};

export default CompaniesPage;
