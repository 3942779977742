import { gql, useMutation } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { client } from 'graphql/client';
import { GET_COMPLIANCE_CHECKLIST_BY_ID } from 'graphql/query/complianceChecklist';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ComplianceChecklist, ComplianceDocument, DocStatusEnum, DocumentFile } from 'types';

import { ConfirmationPopup } from 'components/Popup';
import Section from 'components/Section';
import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const DELETE_FILE_MUTATION = gql`
  mutation DeleteComplianceDocument($id: ID!) {
    deleteComplianceDocument(_id: $id)
  }
`;

const UploadedComplianceDocumentsSection: React.FC<{
  uploadedDocuments: ComplianceDocument[];
  complianceChecklist: ComplianceChecklist;
}> = ({ uploadedDocuments, complianceChecklist }) => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  const [deletePopup, toggleDeletePopup] = useState<{
    complianceDocumentId?: string;
    show: boolean;
  }>({
    show: false,
  });

  const [deleteFile, { loading: deletingFile }] = useMutation<{}, { id: string }>(
    DELETE_FILE_MUTATION
  );

  const files = useMemo(() => {
    const dFiles: (DocumentFile & {
      complianceDocument: {
        _id: string;
        docStatus: DocStatusEnum;
      };
    })[] = [];

    uploadedDocuments.forEach(ud => {
      dFiles.push(
        ...ud.files.map(f => ({
          ...f,
          complianceDocument: {
            _id: ud._id,
            docStatus: ud.docStatus,
          },
        }))
      );
    });

    return dFiles;
  }, [uploadedDocuments]);

  return (
    <Section title="Updated Tasks" collapsible>
      <DataGridTable
        columns={[
          {
            field: 'referenceId',
            headerName: 'ID',
            minWidth: 100,
            renderCell: params =>
              params.row.docStatus === DocStatusEnum['NO DUE'] ? (
                <Typography>Submission Skipped</Typography>
              ) : (
                <Link
                  to={`/documents/PROJECT/${projectId}/${complianceChecklist.docType.parentFolder?._id}/${complianceChecklist.docType?._id}/${params.row?._id}`}
                >
                  {params.value}
                </Link>
              ),
          },
          {
            field: 'complianceDocument',
            headerName: 'Doc Status',
            valueGetter: (val: any) => getEnumKeyFromValue(DocStatusEnum, val.docStatus),
            minWidth: 150,
          },
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 200,
            flex: 1,
          },
          {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            renderCell: params => (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() =>
                    toggleDeletePopup({
                      show: true,
                      complianceDocumentId: params.row.complianceDocument._id,
                    })
                  }
                >
                  <DeleteForeverIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => window.open(params.row?.path, '_blank')}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Box>
            ),
          },
        ]}
        rows={files}
        getRowId={row => row._id}
        hideFooterPagination
        sx={{
          height: '40vh',
          maxWidth: '80vw',
        }}
      />

      <ConfirmationPopup
        onClose={() => toggleDeletePopup({ complianceDocumentId: undefined, show: false })}
        onConfirmation={() => {
          deleteFile({
            variables: {
              id: deletePopup.complianceDocumentId as string,
            },
            onCompleted: _ => {
              toast.success('File Deleted Successfully');
              deleteCachedListItem(
                { id: complianceChecklist._id },
                deletePopup.complianceDocumentId as string
              );
              toggleDeletePopup({ complianceDocumentId: undefined, show: false });
            },
          });
          toggleDeletePopup({ complianceDocumentId: undefined, show: false });
        }}
        open={deletePopup.show}
        title="Do you want to delete this document?"
        confirmationLabel="Yes"
        loading={deletingFile}
      />
    </Section>
  );
};

const deleteCachedListItem = (variables: Record<string, any>, complianceDocumentId: string) => {
  const currComplianceDocuments = client.readQuery({
    query: GET_COMPLIANCE_CHECKLIST_BY_ID,
    variables,
  }) ?? {
    getComplianceChecklistDetailsById: {
      uploadedDocuments: [],
    },
  };

  client.writeQuery({
    query: GET_COMPLIANCE_CHECKLIST_BY_ID,
    variables,
    data: {
      getComplianceChecklistDetailsById: {
        ...(currComplianceDocuments ?? {}),
        uploadedDocuments: (
          currComplianceDocuments?.getComplianceChecklistDetailsById?.uploadedDocuments ?? []
        ).filter((item: ComplianceDocument) => item._id !== complianceDocumentId),
      },
    },
  });
};

export default UploadedComplianceDocumentsSection;
