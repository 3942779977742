import { NetworkStatus, gql, useQuery } from '@apollo/client';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UOMEnum } from 'routes/RFQs/ManageBOQ';

import Section, { SectionDataProps } from 'components/Section';

import { extractLastSegment } from 'utils/formatHelper';
import { getEnumKeyFromValue } from 'utils/transformFn';

import RAMappingSection from './RAMapping';

export const GET_BOQ_BYID_QUERY = gql`
  query GetBOQById($id: ID!) {
    getBOQById(_id: $id) {
      _id
      referenceId
      boqId
      name
      description
      uom
      qty
      cost
      margin
      discount
      wastage
      price
      rateAnalysis {
        _id
        referenceId
        name
        description
        baseQty
        baseUom
        totalCost
        totalPrice
        items {
          item
          name
          qty
          uom
          actualCost
          modifiedCost
          totalCost
        }
      }
    }
  }
`;

const BOQSection: React.FC<{
  rfqId: string;
}> = ({ rfqId }) => {
  const { data, networkStatus } = useQuery<any>(GET_BOQ_BYID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: extractLastSegment(window.location.href),
    },
  });

  const loadingLeads =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!data;

  const boqDetails = data?.getBOQById;
  // console.log('boqDetails', boqDetails);
  const details: SectionDataProps[] = [
    {
      label: 'BOQ Id',
      value: boqDetails?.boqId,
      type: 'STRING',
    },
    {
      label: 'Name',
      value: boqDetails?.name,
      type: 'STRING',
    },
    {
      label: 'Description',
      value: boqDetails?.description,
      type: 'STRING',
    },
    {
      label: 'Quantity',
      value: boqDetails?.qty ?? 'N/A',
      type: 'STRING',
    },
    {
      label: 'UOM',
      value: getEnumKeyFromValue(UOMEnum, boqDetails?.uom),
      type: 'STRING',
    },
    {
      label: 'Wastage %',
      value: boqDetails?.wastage ? boqDetails?.wastage : '0',
      type: 'STRING',
    },
    {
      label: 'Margin %',
      value: boqDetails?.margin ? boqDetails?.margin : '0',
      type: 'STRING',
    },
    {
      label: 'Discount %',
      value: boqDetails?.discount ? boqDetails?.discount : '0',
      type: 'STRING',
    },
    {
      label: 'Price',
      value: boqDetails?.price ? boqDetails?.price : '0',
      type: 'STRING',
    },
    {
      label: 'Cost',
      value: boqDetails?.cost ? boqDetails?.cost : '0',
      type: 'STRING',
    },
  ];
  const raDetails: SectionDataProps[] = [
    {
      label: 'Reference Id',
      value: boqDetails?.rateAnalysis?.referenceId,
      type: 'STRING',
    },
    {
      label: 'Name',
      value: boqDetails?.rateAnalysis?.name ?? 'N/A',
      type: 'STRING',
    },
    {
      label: 'Description',
      value: boqDetails?.rateAnalysis?.description ?? 'N/A',
      type: 'STRING',
    },
    {
      label: 'Base Quantity',
      value: boqDetails?.rateAnalysis?.baseQty ?? 'N/A',
      type: 'STRING',
    },
    {
      label: 'Base UOM',
      value: getEnumKeyFromValue(UOMEnum, boqDetails?.rateAnalysis?.baseUom) ?? 'N/A',
      type: 'STRING',
    },
  ];
  const navigate = useNavigate();
  return (
    <Grid container direction="column" rowGap={1.5} mt={0}>
      <Section
        title="BOQ Details"
        collapsible
        data={details}
        action={
          <EditNoteIcon
            onClick={() => {
              navigate(`/rfqs/manage-boq?type=edit&rfqId=${rfqId}&boqId=${boqDetails?._id}`);
            }}
            sx={{ cursor: 'pointer' }}
          />
        }
      />
      <Section title="Rate Analysis Details" collapsible data={raDetails} />
      <RAMappingSection
        rfqId={rfqId}
        items={boqDetails?.rateAnalysis?.items}
        rateAnalysisId={boqDetails?.rateAnalysis?._id}
      />
    </Grid>
  );
};

export default BOQSection;
