import { NetworkStatus, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import {
  CREATE_FOLDER_MUTATION,
  CreateFolderMutationResponse,
  CreateFolderMutationVariables,
} from 'graphql/mutation/folder';
import {
  DOWNLOAD_FOLDER_AS_ZIP,
  DownloadFolderAsZipQueryResponse,
  DownloadFolderAsZipQueryVariables,
  GET_FOLDERS_QUERY_FOR_LV,
  GetFoldersQueryResponse,
  GetFoldersQueryVariables,
} from 'graphql/query/folder';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, FolderTypeEnum } from 'types';

import { DocumentFoldersGrid } from 'components/DocumentMaster/DocumentFolders';
import TextField from 'components/Inputs/TextField';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

import { invalidFolderNameCharacters } from 'utils/regexes';

import { updateFolderCachedList } from '../DocumentFolders';
import { DownloadForOffline } from '@mui/icons-material';

const DocumentSubFoldersPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [enableCreateNewForm, toggleCreateNewForm] = useState(false);
  const navigate = useNavigate();

  const {
    documentId = '',
    folderType = '',
    folderId = '',
  } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
    folderId: string;
  }>();

  const [downloadFolder, { loading: downloadingFolder }] = useLazyQuery<
    DownloadFolderAsZipQueryResponse,
    DownloadFolderAsZipQueryVariables
  >(DOWNLOAD_FOLDER_AS_ZIP);
  const isLead = folderType === FolderTypeEnum.LEAD;


  const {
    data: folders,
    networkStatus,
    refetch: refetchFolders,
  } = useQuery<GetFoldersQueryResponse, GetFoldersQueryVariables>(GET_FOLDERS_QUERY_FOR_LV, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: folderType as FolderTypeEnum,
      projectOrLeadId: documentId,
      parentFolder: folderId,
    },
  });

  if (!Object.values(FolderTypeEnum).includes(folderType as FolderTypeEnum)) {
    navigate('/companies');
    return null;
  }



  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetchFolders(newFilters);
  };

  const loadingFolders =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables ||
    !!!folders;

  const handleDownloadFolder = () => {
    downloadFolder({
      variables: {
        folder: folderId,

        lead: isLead ? documentId : undefined,
        project: isLead ? undefined : documentId,
      },
      onCompleted: res => window.open(res.downloadFolderAsZip, '_blank'),
    });
  };


  return (
    <Navbar
      goBackButtonConfig={{
        title: !!!folders?.getFolders?.title ? '' : folders?.getFolders.title,
        hideSeparator: true,
      }}
      searchInputConfig={{
        enable: true,
        name: 'SubFolder search',
        placeholder: 'Folder Name',
        handleChange: searchFilter =>
          applyFilters({
            ...filters,
            searchTerm: !!searchFilter ? searchFilter : null,
          }),
      }}
      actionChildren={
        <Box display={'flex'} alignItems={'center'}>
          <Tooltip title="Download Folder">
            <IconButton>
              {downloadingFolder ? (
                <CircularProgress size={21} color="secondary" />
              ) : (
                <DownloadForOffline
                  color="secondary"
                  fontSize="large"
                  onClick={handleDownloadFolder}
                />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Create Folder" >
            <IconButton >
              <AddBoxIcon

                fontSize="large"
                color="secondary"
                onClick={() => toggleCreateNewForm(true)}

              />
            </IconButton>
          </Tooltip>
        </Box>
      }
    >
      {loadingFolders ? (
        <LoadingIndicator />
      ) : (
        <DocumentFoldersGrid folders={folders?.getFolders?.folders ?? []} />
      )}
      <SimplePopup
        title="Create a new folder"
        fullWidth
        onClose={() => toggleCreateNewForm(false)}
        open={enableCreateNewForm}
      >
        <CreateFolder cb={() => toggleCreateNewForm(false)} />
      </SimplePopup>
    </Navbar>
  );
};

const CreateFolder: React.FC<{ cb: (arg: Folder) => void }> = ({ cb }) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  const [createFolder, { loading: creatingFolder }] = useMutation<
    CreateFolderMutationResponse,
    CreateFolderMutationVariables
  >(CREATE_FOLDER_MUTATION);

  const {
    documentId = '',
    folderType = '',
    folderId = '',
  } = useParams<{
    folderType: FolderTypeEnum;
    documentId: string;
    folderId: string;
  }>();

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleFolderNameChange = (fieldName: string, value: string) => {
    if (!invalidFolderNameCharacters.test(value)) {
      setFormState(prev => ({ ...prev, [fieldName]: value }));
    }
  };

  const handleSubmit = () => {
    createFolder({
      variables: {
        input: {
          name: formState.name.trim(),
          type: folderType as FolderTypeEnum,
          parentFolder: folderId,
          lead: folderType === FolderTypeEnum.LEAD ? documentId : undefined,
          project: folderType === FolderTypeEnum.PROJECT ? documentId : undefined,
          isStandardFolder: false,
        },
      },
      onCompleted: res => {
        updateFolderCachedList(res.createFolder, {
          type: folderType as FolderTypeEnum,
          projectOrLeadId: documentId,
          parentFolder: folderId,
        });
        cb(res.createFolder);
      },
    });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Grid container direction="column" gap={2} px={2} py={1}>
        <TextField
          label="Folder Name"
          value={formState.name ?? ''}
          onChange={e => handleFolderNameChange('folderName', e.target.value)}
          helperText={'Name cannot include these characters":  / : * ? " < > |'}
          required
        />
        <LoadingButton variant="contained" type="submit" loading={creatingFolder}>
          Create
        </LoadingButton>
      </Grid>
    </form>
  );
};

export default DocumentSubFoldersPage;
