import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { UOMEnum } from 'routes/RFQs/ManageBOQ';

import DataGridTable from 'components/Table';

import { getEnumKeyFromValue } from 'utils/transformFn';

const RATemplate: React.FC<{
  RATemplate: any[];
  totalCount: number;
  totalPages?: number;
  currentPage?: number;
  paginationModel: { pageSize: number; page: number };
  setPaginationModel: (model: { pageSize: number; page: number }) => void;
}> = ({ RATemplate, totalCount, paginationModel, setPaginationModel }) => {
  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setPaginationModel({ page: newPage, pageSize: newPageSize });
  };
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  return (
    <DataGridTable
      columns={[
        {
          field: 'referenceId',
          headerName: 'ID',
          minWidth: isMobileScreen ? 150 : 250,
          renderCell: params => <Link to={params.row._id}>{params.value}</Link>,
        },
        {
          field: 'name',
          headerName: 'Name',
          minWidth: isMobileScreen ? 150 : 250,
        },
        {
          field: 'baseQty',
          headerName: 'Base Qty',
          minWidth: isMobileScreen ? 150 : 250,
        },
        {
          field: 'baseUom',
          headerName: 'Base UOM',
          minWidth: isMobileScreen ? 150 : 250,
          valueFormatter: (value: UOMEnum) => getEnumKeyFromValue(UOMEnum, value),
        },
      ]}
      sx={{ maxWidth: isMobileScreen ? '92vw' : '100vw', mx: 'auto', background: 'transparent' }}
      rows={RATemplate}
      getRowId={row => row._id}
      rowCount={totalCount}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={newModel => handlePaginationChange(newModel.page, newModel.pageSize)}
    />
  );
};

export default RATemplate;
