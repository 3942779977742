import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { AddressType } from 'types';

import { SimplePopup } from 'components/Popup';

import Section from '..';
import AddressForm from './AddressForm';

const AddressSection: React.FC<{ address: AddressType; disabled: boolean; title?: string }> = ({
  address,
  disabled,
  title,
}) => {
  const [enableFormEdit, toggleFormEdit] = useState(false);

  return (
    <Grid container direction="column">
      <Section
        title={!!title ? title : 'Address Details'}
        collapsible
        data={[
          {
            label: 'Address Line1',
            value: address.addressLine1,
            type: 'STRING',
          },
          {
            label: 'Address Line2',
            value: address.addressLine2,
            type: 'STRING',
          },
          {
            label: 'Location',
            value: address.addressLocation,
            type: 'STRING',
          },
          {
            label: 'Location URL',
            value: address.locationUrl ? 'See on Maps' : '-',
            type: 'EXTERNAL_LINK',
            navigateTo: address.locationUrl ?? '#',
          },
          {
            label: 'Pincode',
            value: address.pincode,
            type: 'NUMBER',
          },
          {
            label: 'City',
            value: address.city,
            type: 'STRING',
          },
          {
            label: 'State',
            value: address.state,
            type: 'STRING',
          },
          {
            label: 'Country',
            value: address.country,
            type: 'STRING',
          },
        ]}
        action={
          <EditNoteIcon
            onClick={() => toggleFormEdit(true)}
            sx={
              disabled
                ? {
                    pointerEvents: 'none',
                    color: 'gray',
                  }
                : { cursor: 'pointer' }
            }
          />
        }
      />
      <SimplePopup
        onClose={() => toggleFormEdit(false)}
        open={enableFormEdit}
        title="Update Address"
        fullWidth
      >
        <AddressForm address={address} cb={() => toggleFormEdit(false)} />
      </SimplePopup>
    </Grid>
  );
};

export default AddressSection;
