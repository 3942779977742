export interface ActionType {
  type: string;
}

export interface UpdateStateActionType extends ActionType {
  payload: {
    fieldName: string;
    value: any;
  };
}

export interface UpdateErrorsActionType extends ActionType {
  payload: {
    errors: any;
  };
}

export interface ResetFormActionType extends ActionType {
  payload: {
    initialData: any;
  };
}
export interface ClearFormActionType extends ActionType {
  payload: {
    initialData: any;
  };
}

function isUpdateStateAction(action: ActionType): action is UpdateStateActionType {
  return action.type === 'UPDATE_STATE';
}

function isUpdateErrorsAction(action: ActionType): action is UpdateErrorsActionType {
  return action.type === 'UPDATE_ERRORS';
}

function isResetFormAction(action: ActionType): action is ResetFormActionType {
  return action.type === 'RESET_FORM';
}

function isClearFormAction(action: ActionType): action is ResetFormActionType {
  return action.type === 'CLEAR_FORM';
}

const reducer = (state: any, action: ActionType) => {
  if (isUpdateStateAction(action)) {
    const fieldName = action.payload.fieldName;
    return {
      ...state,
      data: { ...state.data, [fieldName]: action.payload.value },
    };
  }
  if (isUpdateErrorsAction(action)) {
    return {
      ...state,
      errors: action.payload.errors,
    };
  }
  if (isResetFormAction(action)) {
    return {
      ...state,
      data: action.payload.initialData,
    };
  }
  if (isClearFormAction(action)) {
    const clearData = Object.keys(state.data).reduce((acc, key) => {
      // Reset each field to an empty state (empty array or similar)
      acc[key] = Array.isArray(state.data[key]) ? [] : null;
      return acc;
    }, {} as Record<string, any>);

    return {
      ...state,
      data: clearData,
    };
  }
  return state;
};

export default reducer;
